const activityFieldFunctions = {
    tableFilter: function () {

        let config = tableFilterFunctions.defaultConfig();

        config.col_0 = 'input'
        config.col_1 = 'none'

        config.col_types = [
            'string',
            'none',
        ];

        config.col_widths = [
            '80%',
            '20%',
        ];


        document.querySelectorAll('.tablefilter').forEach(function (table) {

            let tf = new TableFilter(table, config);
            tf.init();
            tf.filter();
        });
    }
}

export default activityFieldFunctions
