const tableFilterFunctions = {
    defaultConfig: function() {

        let gridHeight = (($(window).height() - 500).toString()) + 'px';

        let config = {
            base_path: '/vendor/tablefilter/',
            grid_layout: {
                width: '100%',
                height: gridHeight,
                default_col_width: '150px'
            },
            rows_counter: {
                text: 'Nombre de lignes : '
            },
            help_instructions: false,
            //if state is on, check on setDefaultFilters : tf.filter();
            state: {
                types: ['cookie'],
                filters: true,
                sort: true,
            },
            responsive: true,
            alternate_rows: true,
            btn_reset: {
                tooltip: 'Effacer les filtres'
            },
            highlight_keywords: true,
            ignore_diacritics: true,
            enable_checklist_reset_filter: false,
            /*no_results_message: {
                content: '<div class="alert alert-danger">Aucun résultat</div>'
            },*/
            clear_filter_text: ' ',
            extensions: [{
                name: 'sort'
            }],
            //bootstrap
            filters_cell_tag: 'th',
            themes: [{ name:'theme_custom', path:'/assets/css/tablefilter.css'}]
        }

        return config;
    },
    setBadgeToNumberOfRows: function() {
        setTimeout(function(){
            $('.tot span:nth-child(2)').addClass('label label-rounded label-primary');
            $('.tot span:nth-child(2)').css({'font-weight': 'bold'});
        }, 100);
    },
    setTotalRowInTable: function(tf, tableClassSelector) {

        let $totalColumnsCloned = $('.totalColumnsHidden').clone();
        $totalColumnsCloned.removeClass('totalColumnsHidden');
        $totalColumnsCloned.addClass('totalColumns');
        $(tableClassSelector + ' tbody').append($totalColumnsCloned);

        tf.emitter.on(['after-filtering'], function() {

            $(tableClassSelector + ' tbody tr:last-of-type').remove();

            let $totalColumnsCloned = $('.totalColumnsHidden').clone();
            $totalColumnsCloned.removeClass('totalColumnsHidden');
            $totalColumnsCloned.addClass('totalColumns');
            $(tableClassSelector + ' tbody').append($totalColumnsCloned);
        });
    },
    setTotalRowFromTableAlwaysBottom: function(tableClassSelector) {

        let $totalColumnsCloned = $('.totalColumns').clone();
        $(tableClassSelector+' .totalColumns').remove();
        $(tableClassSelector+' tbody').append($totalColumnsCloned);
    },
    setFullWidthOnSelectFilter: function() {

        setTimeout(function(){
            $('.div_checklist').each(function() {
                $(this).parent().css({'padding':'0'});
            });
        }, 100);
    },
    setDefaultWidthColumnsIfTableEmpty: function(tableClassSelector) {

        let isEmptyTable = true;

        let rows = $('.'+ tableClassSelector +' tbody tr');

        if (rows.length > 1) {
            isEmptyTable = false;
        }

        if (isEmptyTable) {

            setTimeout(function(){

                $('col').each(function( index ) {
                    $(this).css({'width': '150px'});
                });
                $('th').each(function( index ) {
                    $(this).css({'width': '150px', 'height': '75px'});
                });

            }, 100);
        }
    }
}

export default tableFilterFunctions
