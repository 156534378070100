import intlTelInputFunctions from "./intlTelInput";

const clientFunctions = {
    tableFilter: function(isCompanyDisplayed) {

        let config = tableFilterFunctions.defaultConfig();

        if (isCompanyDisplayed) {

            //config.extensions.sort_col_at_start = [0, true],
            config.col_0 = 'input',
                config.col_1 = 'checklist',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'checklist',
                config.col_7 = 'input',
                config.col_8 = 'checklist',
                config.col_9 = 'input',
                config.col_10 = 'input',
                config.col_11 = 'input',
                config.col_12 = 'checklist',
                config.col_13 = 'none',
                config.col_types = [
                    'number',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'none',
                ],
                config.col_widths = [
                    '125px',
                    '80px',
                    '200px',
                    '300px',
                    '300px',
                    '300px',
                    '100px',
                    '175px',
                    '100px',
                    '175px',
                    '275px',
                    '175px',
                    '150px',
                    '100px',
                ];

        } else {

            //config.extensions.sort_col_at_start = [0, true],
            config.col_0 = 'input',
                config.col_1 = 'checklist',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'checklist',
                config.col_7 = 'input',
                config.col_8 = 'checklist',
                config.col_9 = 'input',
                config.col_10 = 'input',
                config.col_11 = 'input',
                config.col_12 = 'none',
                config.col_types = [
                    'number',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'none',
                ],
                config.col_widths = [
                    '125px',
                    '80px',
                    '200px',
                    '300px',
                    '300px',
                    '300px',
                    '100px',
                    '175px',
                    '100px',
                    '175px',
                    '275px',
                    '175px',
                    '100px',
                ];
        }

        document.querySelectorAll('.tablefilter').forEach(function(table) {
            let tf = new TableFilter(table, config);
            tf.init();
        });
    },
    tableDataFilter: function() {

        let config = tableFilterFunctions.defaultConfig();


            //config.extensions.sort_col_at_start = [0, true],
            config.col_0 = 'input',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'input',

                config.col_types = [
                    'number',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                ],
                config.col_widths = [
                    '125px',
                    '200px',
                    '300px',
                    '300px',
                    '300px',
                    '100px',
                    '175px',
                    '100px',
                ];

        document.querySelectorAll('.tablefilter').forEach(function(table) {
            let tf = new TableFilter(table, config);
            tf.init();
        });
    },
    IntlTelInputClient: function() {

        let config = intlTelInputFunctions.defaultConfig();

        $("#client_coordinate_phoneNumber1").intlTelInput(config);
        $("#client_coordinate_phoneNumber2").intlTelInput(config);

        $("form").submit(function(e) {
            $("#client_coordinate_phoneNumber1").val($("#client_coordinate_phoneNumber1").intlTelInput("getNumber"));
            $("#client_coordinate_phoneNumber2").val($("#client_coordinate_phoneNumber2").intlTelInput("getNumber"));
        });
    },
    checkIfClientsHasSameLastName: function() {

        $('#client_coordinate_lastName').focusout(function () {

            let lastNameEncoded = $(this).val();

            let datas = [];
            let data = {
                lastNameEncoded: lastNameEncoded
            };
            datas.push(data);

            $.ajax({
                type: "POST",
                url: Routing.generate('check_if_clients_has_same_last_name'),
                data: {dataJson: JSON.stringify(datas)},
                cache: false,
                dataType: "json",
            }).done(function (dataJson) {

                if (dataJson.clientsWithSameLastName.length > 0) {

                    let clientsWithHtmlTags = '';

                    $.each(dataJson.clientsWithSameLastName, function (index, value) {
                        clientsWithHtmlTags += '<tr class="pathLink ' + Routing.generate('client_folder', {
                            'clientId': value[0],
                            'tabReferer': 'card' + value[0]
                        }) + '"><td class="link">' + value[1] + '</td><td class="link">' + value[2] + '</td><td class="link">' + value[3] + ', ' + value[4] + '</td><td class="link">' + value[5] + '</td></tr>';
                    });

                    let popupContent = "\
								<p>Ce nom est déjà utilisé par un/des client(s) :</p>\
				                <table class='table table-condensed table-bordered table-hover tablefilter'>\
				                  	<thead>\
				                    <tr>\
				                      	<th>Nom</th>\
				                      	<th>Prénom</th>\
				                      	<th>Adresse</th>\
				                      	<th>Code postal</th>\
				                    </tr>\
				                  	</thead>\
				                  	<tbody>" + clientsWithHtmlTags + "</tbody>\
				                </table>\
				            ";

                    $.alert({
                        title: 'Attention',
                        content: popupContent,
                        icon: 'fa fa-warning',
                        type: 'red',
                        typeAnimated: true,
                        draggable: true,
                        dragWindowGap: 0, // number of px of distance
                        theme: 'modern',
                        buttons: {
                            cancel: {
                                text: 'Fermer',
                                btnClass: 'btn-default',
                            },
                        },
                        onContentReady: function () {

                            appFunctions.redirectToPathLinkOnClickRow();
                        },
                    });
                }
            });
        });
    }
}

export default clientFunctions
