const deadlineFunctions = {
    tableFilter: function() {

        let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'input',
                config.col_1 = 'checklist',
                config.col_2 = 'input',
                config.col_3 = 'checklist',
                config.col_4 = 'input',
                config.col_5 = 'checklist',
                config.col_6 = 'input',
                config.col_7 = 'input',
                config.col_8 = 'input',
                config.col_9 = 'input'
            config.col_types = [
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
            ];


        document.querySelectorAll('.tablefilter').forEach(function (table) {

            let tf = new TableFilter(table, config);
            tf.init();
            tf.filter();
        });
    }
}

export default deadlineFunctions
