const appFunctions = {

    displayMessageIfEmptyTable: function(message) {
        if($.trim($("tbody").html())=='') {
            $('table').replaceWith('<div class="alert alert-info m-b-0" role="alert">'+message+'.</div>');
        }
    },
    setLinkInTabOnReload: function() {
        // Javascript to enable link to tab
        let hash = document.location.hash;
        let prefix = "tab_";
        if (hash) {
            $('.nav-tabs a[href="'+hash.replace(prefix,"")+'"]').tab('show');
        }

        // Change hash for page-reload
        $('.nav-tabs a').on('shown', function (e) {
            window.location.hash = e.target.hash.replace("#", "#" + prefix);
        });

    },
    redirectToPathLinkOnClickRow: function() {

        $('body').on('click', '.link', function() {

            let pathLink = $(this).parent('tr').attr('class').split(' ')[1];

            window.location = pathLink;
        });
    },
    sumBooleanFields: function(table, fieldToCheck, fieldToDisplay) {
        let sumBooleanFieldsTrue = 0;
        $(table + ' ' + fieldToCheck +':visible').each(function(){

            let booleanFieldValue = $.trim($(this).text());

            if (booleanFieldValue === 'Oui') {
                sumBooleanFieldsTrue = sumBooleanFieldsTrue + 1;
            }
            $(fieldToDisplay).html(sumBooleanFieldsTrue);
        });
    },
    getNumberOfDecimal(number) {
        if (!isFinite(number)) return 0;
        let e = 1, p = 0;
        while (Math.round(number * e) / e !== number) { e *= 10; p++; }
        return p;
    },
    isDifferentUser: function(isValidation2, datas) {

        let isDifferentUser = true;

        if (isValidation2) {

            let userIdFromValidation1 = $('#userIdFromValidation1').attr('class').split(' ')[0];

            if (userIdFromValidation1 === datas['userId']) {

                $.alert({
                    title: 'Attention',
                    content: "Il faut deux utilisateurs différents pour pouvoir valider",
                    icon: 'fa fa-warning',
                    type: 'red',
                    typeAnimated: true,
                    draggable: true,
                    dragWindowGap: 0, // number of px of distance
                    theme: 'modern',
                    buttons: {
                        cancel: {
                            text: 'Ok',
                            btnClass: 'btn-default',
                        },
                    }
                });

                isDifferentUser = false;
            }
        }

        return isDifferentUser;
    },
    translateAmountInEnglish: function(amount) {

        let amountTranslateInEnglish = amount.toString().replace(/\./g, '');
        amountTranslateInEnglish = amountTranslateInEnglish.toString().replace(/\,/g, '.');

        return amountTranslateInEnglish;
    },
    withdrawAllInputsFromTable: function() {

        $('.table tbody tr td input, .table tbody tr td select').each(function() {

            let valueInput = $(this).val();

            $(this).parent().html(valueInput);
        });
    },
    transformDotFromPavNumIntoComma: function() {

        $('body').on('keyup', 'input', function(e) {

            let valueInput = $(this).val();
            let position = valueInput.lastIndexOf('.');

            if (position > 0) {

                let valueWithCommaInsteadOfDot = valueInput.substring(0, position) + ',' + valueInput.substring(position + 1);

                if (e.keyCode == 110) {

                    $(this).val(valueWithCommaInsteadOfDot);
                }
            }
        });
    },
    capitalizeFirstLetter: function(string) {
        return string[0].toUpperCase() + string.slice(1);
    }
}

export default appFunctions
