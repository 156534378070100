import "jquery-confirm/dist/jquery-confirm.min.js"

const mediaFunctions = {

    selectMedia: function($route) {

        $('body').on('click', '.select-media', function() {

            let mediaId = $(this).attr('class').split(' ')[0];
            let target = $(this).attr('class').split(' ')[1];

            let data = {
                'mediaId': mediaId,
                'target': target
            };

            $.ajax({
                type: "POST",
                url: Routing.generate($route),
                data: data,
                cache: false,
                success: function() {
                    $("#message_success").fadeIn();
                    setTimeout(function(){
                        $("#message_success").fadeOut();
                    }, 3000);
                }
            });
        });
    },
    deleteMedia: function($route, $message) {

        $('body').on('click', '.delete-media', function() {

            let mediaId = $(this).attr('class').split(' ')[0];
            let data = {
                'mediaId': mediaId
            };

            $.confirm({
                title: 'Attention',
                content: "Etes-vous sûr de vouloir supprimer "+$message+" ?",
                icon: 'fa fa-warning',
                type: 'red',
                typeAnimated: true,
                draggable: true,
                dragWindowGap: 0, // number of px of distance
                theme: 'modern',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-danger',
                        action: function(){
                            $.ajax({
                                type: "POST",
                                url: Routing.generate($route),
                                data: data,
                                cache: false,
                                success: function() {
                                    $('#delete'+mediaId).remove();
                                    if ($message == 'cette photo') {
                                        appFunctions.displayMessageIfEmptyTable('Aucune photo disponible');
                                    } else {
                                        appFunctions.displayMessageIfEmptyTable('Aucune photo disponible');
                                    }
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non',
                        btnClass: 'btn-default',
                    },
                }
            });

        });
    },
    dropZonePicture: function(config) {

        //Fix Dropzone.autoDiscover = false;
        Dropzone.autoDiscover = false
        $('.future-dropzone').addClass('dropzone');

        const dropzone = new Dropzone('.dropzone',{
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: 100,
            maxThumbnailFilesize: 100,
            maxFilesize: 99,
            acceptedFiles: 'image/gif, image/jpeg, image/pjpeg, image/png',
            dictInvalidFileType: 'Ce type de fichier n\'est pas accepté.',
            dictFileTooBig: 'Ce fichier est trop gros, vous devez réduire sa taille pour pouvoir le mettre sur le site. (64MB Maximum)',
            init: function () {
                this.on("successmultiple", function (file) {
                    /* Reload page after upload success */
                    if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                        location.reload();
                    }
                });
                this.on("errormultiple", function(files, response) {
                    // Gets triggered when there was an error sending the files.
                    // Maybe show form again, and notify user of error
                    $('#infoErrorUpload').html('<p class="alert alert-danger text-center"><b>'+response+'</b></p>');
                });
                // Register for the thumbnail callback.
                // When the thumbnail is created the image dimensions are set.
                this.on("thumbnail", function(file) {
                    // Do the dimension checks you want to do

                    if (file.width > config.maxImageWidth) {
                        config.message = '- La largeur de l\'image doit être plus petite (' + config.maxImageWidth + 'px maximum)';
                    }
                    if (file.width < config.minImageWidth) {
                        config.message += ' - La largeur de l\'image doit être plus grande (' + config.minImageWidth + 'px minimum)';
                    }
                    if (file.height > config.maxImageHeight) {
                        config.message += ' - La hauteur de l\'image doit être plus petite (' + config.maxImageHeight + 'px maximum)';
                    }
                    if (file.height < config.minImageHeight) {
                        config.message += ' - La hauteur de l\'image doit être plus grande (' + config.minImageHeight + 'px minimum)';
                    }

                    if (file.width > config.maxImageWidth || file.width < config.minImageWidth || file.height > config.maxImageHeight || file.height < config.minImageHeight) {
                        file.rejectDimensions();
                        config.message = '';
                    } else {
                        file.acceptDimensions();
                    }
                })
            },
            // Instead of directly accepting / rejecting the file, setup two
            // functions on the file that can be called later to accept / reject
            // the file.
            accept: function(file, done) {
                file.acceptDimensions = done;
                file.rejectDimensions = function() { done(config.message); };
                // Of course you could also just put the `done` function in the file
                // and call it either with or without error in the `thumbnail` event
                // callback, but I think that this is cleaner.
            }
        })

    },
    dropZoneDocument: function() {

        Dropzone.autoDiscover = false
        $('.future-dropzone').addClass('dropzone');

        const dropzone = new Dropzone('.dropzone',{
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: 100,
            maxFilesize: 99, // MB
            acceptedFiles: '.pdf, .docx, .doc, .xlsx, .xls, .pptx, .ppt, .odt, .ods, .odp, image/gif, image/jpeg, image/pjpeg, image/png',
            dictInvalidFileType: 'Ce type de fichier n\'est pas accepté.',
            dictFileTooBig: 'Ce fichier est trop gros, vous devez réduire sa taille pour pouvoir le mettre sur le site. (5MB Maximum)',
            init: function () {
                this.on("successmultiple", function (file) {
                    /* Reload page after upload success */
                    if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                        location.reload();
                    }
                });
                this.on("errormultiple", function(files, response) {
                    // Gets triggered when there was an error sending the files.
                    // Maybe show form again, and notify user of error
                    $('#infoErrorUpload').html('<p class="alert alert-danger text-center"><b>'+response+'</b></p>');
                });
            },
        })
    },
    eventChangeOriginalNameMedia: function() {

        $('input.originalName').focusout(function() {
            let mediaId = $(this).attr('id');
            let newOriginalName = $(this).val();

            let data = {
                'mediaId': mediaId,
                'newOriginalName': newOriginalName
            };

            $.ajax({
                type: "POST",
                url: Routing.generate('media_update_original_name'),
                data: data,
                cache: false,
            }).done(function(data) {
            });
        });
    },

}

export default mediaFunctions
