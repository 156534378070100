const projectFunctions = {
    tableFilter: function(isCompanyDisplayed) {

        let config = tableFilterFunctions.defaultConfig();

        if (isCompanyDisplayed) {

            config.col_0 = 'none',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'input',
                config.col_8 = 'checklist',
                config.col_9 = 'none',
                config.col_types = [
                    'none',
                    'string',
                    'string',
                    'string',
                    'number',
                    'number',
                    'number',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'string',
                    'none',
                ];

        } else {

            config.col_0 = 'none',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'input',
                config.col_8 = 'none',
                config.col_types = [
                    'none',
                    'string',
                    'string',
                    'string',
                    'number',
                    'number',
                    'number',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'none',
                ];

        }

        document.querySelectorAll('.tablefilter').forEach(function(table) {
            let tf = new TableFilter(table, config);
            tf.init();
        });
    },
    folder: {
        tableFilterCardDocumentsUploaded: function() {

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'none',
                ],
                config.col_widths = [
                    '125px',
                    '325px',
                    '100px',
                    '75px',
                ];

            document.querySelectorAll('.tablefilterProjectCardDocumentsUploaded').forEach(function(table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },
    }
}

export default projectFunctions
