const headerControl = {

    costDashboard: () => {
        const stateInformationContainer = document.querySelector('#stateInformationContainer')
        const globalAndAnnualBudgetsFlag = document.querySelector('#globalAndAnnualBudgetsFlag').textContent
        const annualBudgetsAndBudgetSuppliersFlag = document.querySelector('#annualBudgetsAndBudgetSuppliersFlag').textContent

        if (globalAndAnnualBudgetsFlag === '0'){
            const stateInformationFieldFalse = stateInformationContainer.querySelector('#stateInformationContainerFalse')
            stateInformationFieldFalse.classList.toggle('d-none')
            const icon = stateInformationContainer.querySelector('.colorDanger')
            icon.classList.toggle('d-none')
            stateInformationFieldFalse.textContent = 'Budgets'

        }else{
            if (annualBudgetsAndBudgetSuppliersFlag === '0'){
                const stateInformationFieldFalse = stateInformationContainer.querySelector('#stateInformationContainerFalse')
                stateInformationFieldFalse.classList.toggle('d-none')
                const icon = stateInformationContainer.querySelector('.colorDanger')
                icon.classList.toggle('d-none')
                stateInformationFieldFalse.textContent = 'Budgets'
            }else{
                const stateInformationFieldFalse = stateInformationContainer.querySelector('#stateInformationContainerTrue')
                stateInformationFieldFalse.classList.toggle('d-none')
                const icon = stateInformationContainer.querySelector('.colorSuccess')
                icon.classList.toggle('d-none')
                stateInformationFieldFalse.textContent = 'Budgets'
            }
        }
    }
}

export default headerControl
