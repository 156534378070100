import Swal from "sweetalert2";

const invoiceFunctions = {
    payments: {

        tableFilter: function () {

            let tableClassSelector = '.tableFilterEncodingType';

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'input',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'checklist',
                config.col_4 = 'checklist',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'input',
                config.col_8 = 'input',
                config.col_9 = 'checklist',
                config.col_10 = 'input',
                config.col_11 = 'input',
                config.col_12 = 'input',
                config.col_types = [
                    'number',
                    'string',
                    'string',
                    'number',
                    'number',
                    'date',
                    'date',
                    'string',
                    'number',
                    'string',
                    'string',
                    'string',
                    'string',
                ],
                config.col_widths = [
                    '75px',
                    '150px',
                    '150px',
                    '75px',
                    '75px',
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                    '75px',
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                ];
            document.querySelectorAll('.tableFilterEncodingType').forEach(function (table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },

    },
    encodingType: {

        tableFilter: function () {

            let tableClassSelector = '.tableFilterEncodingType';

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'input',
                config.col_2 = 'checklist',
                config.col_3 = 'none',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'input',
                config.col_8 = 'input',
                config.col_9 = 'input',
                config.col_10 = 'input',
                config.col_11 = 'checklist',
                config.col_types = [
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                ],
                config.col_widths = [
                    '175px',
                    '125px',
                    '100px',
                    '100px',
                    '200px',
                    '200px',
                    '200px',
                    '100px',
                    '100px',
                    '175px',
                    '125px',

                ];
            document.querySelectorAll('.tableFilterEncodingType').forEach(function (table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },

        //TODO CHANGE MESSAGE MODAL
        exportAllODACHInExcel: function (result, messageModal) {

            window.location.reload()







            const data = JSON.parse(result.data)

            let countResponseFlags = 0
            let typeResponse = []

            for (const [key, type] of Object.entries(data['type'])) {
                if (type) {
                    countResponseFlags++
                    typeResponse.push(key)
                }
            }

            if (countResponseFlags > 0) {

                //This not reset the page
                document.location.href = '/company/' + data['companyId'] + '/invoice-export-odach/' + typeResponse[0]

                Swal.fire({
                    template: '#encoding_modal2',
                    text: messageModal
                }).then((e) => {

                    if (e.isConfirmed) {
                        document.location.href = '/company/' + data['companyId'] + '/invoice-export-od-analytique/' + typeResponse[0]
                    } else {
                        document.location.href = '/company/' + data['companyId'] + '/invoice-export-od-analytique/' + typeResponse[0]
                    }

                    if (countResponseFlags > 1) {

                        Swal.fire({
                            template: '#encoding_modal2',
                            text: messageModal
                        }).then((e) => {

                            if (e.isConfirmed) {
                                document.location.href = '/company/' + data['companyId'] + '/invoice-export-odach/' + typeResponse[1]
                            } else {
                                document.location.href = '/company/' + data['companyId'] + '/invoice-export-odach/' + typeResponse[1]
                            }

                            Swal.fire({
                                template: '#encoding_modal2',
                                text: messageModal
                            }).then((e) => {

                                if (e.isConfirmed) {
                                    document.location.href = '/company/' + data['companyId'] + '/invoice-export-od-analytique/' + typeResponse[1]
                                } else {
                                    document.location.href = '/company/' + data['companyId'] + '/invoice-export-od-analytique/' + typeResponse[1]
                                }

                                if (countResponseFlags > 2) {
                                    Swal.fire({
                                        template: '#encoding_modal2',
                                        text: messageModal
                                    }).then((e) => {

                                        if (e.isConfirmed) {
                                            document.location.href = '/company/' + data['companyId'] + '/invoice-export-odach/' + typeResponse[2]
                                        } else {
                                            document.location.href = '/company/' + data['companyId'] + '/invoice-export-odach/' + typeResponse[2]
                                        }

                                        Swal.fire({
                                            template: '#encoding_modal2',
                                            text: messageModal
                                        }).then((e) => {

                                            if (e.isConfirmed) {
                                                document.location.href = '/company/' + data['companyId'] + '/invoice-export-od-analytique/' + typeResponse[2]
                                            } else {
                                                document.location.href = '/company/' + data['companyId'] + '/invoice-export-od-analytique/' + typeResponse[2]
                                            }
                                        })
                                    })
                                }
                            })
                        })
                    }
                })
            }
        },
    },

    encodingHistoryType: {

        tableFilter: function () {

            let tableClassSelector = '.tableFilterEncodingType';

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'input',
                config.col_1 = 'checklist',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'checklist',
                config.col_6 = 'checklist',
                config.col_7 = 'checklist',
             /*   config.col_8 = 'input',
                config.col_9 = 'input',*/
                config.col_types = [
                    'date',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                  /*  'string',
                    'string',*/
                ],
                config.col_widths = [
                    '75px',
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                    '75px',
                   /* '150px',
                    '150px',*/
                ];
            document.querySelectorAll('.tableFilterEncodingType').forEach(function (table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },
    },
    edit_deadlines: {

        tableFilter: function () {

            let tableClassSelector = '.tableFilterEncodingType';

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'input',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_types = [
                    'string',
                    'string',
                    'string',
                    'string',
                    'date',
                    'date',
                ],
                config.col_widths = [
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                    '150px',
                ];
            document.querySelectorAll('.tableFilterEncodingType').forEach(function (table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },

    },
}

export default invoiceFunctions
