import tableFilterFunctions from "./tableFilter";

const userFunction = {
    displayCompanySelectByRoleSelected: function() {

        let $choicesOfCompanySelect = $('#form_company').find(':nth-child(n+1)');

        if ($('#form_groups').val() <= 2) {
            $choicesOfCompanySelect.remove();
        }

        $(document).on('change', '#form_groups', function() {

            let hasNotSelectedAdministrator = ($(this).val() > 2) ? true : false;

            if (hasNotSelectedAdministrator) {

                $('#form_company').append($choicesOfCompanySelect);

            } else {

                $choicesOfCompanySelect.remove();
            }
        });
    },

    tableFilter: function(isOrganizationDisplayed, isCompanyDisplayed) {

        let config = tableFilterFunctions.defaultConfig();

        if (isOrganizationDisplayed && isCompanyDisplayed) {

            config.col_0 = 'none',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'checklist',
                config.col_4 = 'checklist',
                config.col_5 = 'input',
                config.col_6 = 'checklist',
                config.col_7 = 'none',
                config.col_types = [
                    'none',
                    'string',
                    'string',
                    'string',
                    'string',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'string',
                    'none',
                ],
                config.col_widths = [
                    '100px',
                    '250px',
                    '250px',
                    '250px',
                    '250px',
                    '250px',
                    '250px',
                    '100px',
                ];

        } else if (isCompanyDisplayed) {

            config.col_0 = 'none',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'checklist',
                config.col_4 = 'input',
                config.col_5 = 'checklist',
                config.col_6 = 'none',
                config.col_types = [
                    'none',
                    'string',
                    'string',
                    'string',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'string',
                    'none',
                ],
                config.col_widths = [
                    '100px',
                    '250px',
                    '250px',
                    '250px',
                    '250px',
                    '250px',
                    '100px',
                ];

        } else {

            config.col_0 = 'none',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'none',
                config.col_types = [
                    'none',
                    'string',
                    'string',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'string',
                    'none',
                ],
                config.col_widths = [
                    '100px',
                    '250px',
                    '250px',
                    '250px',
                    '250px',
                    '100px',
                ];
        }

        document.querySelectorAll('.tablefilter').forEach(function(table) {
            let tf = new TableFilter(table, config);
            tf.init();
        });
    },
    folder: {
        tableFilterCardDocumentsUploaded: function() {

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'none',
                ],
                config.col_widths = [
                    '125px',
                    '325px',
                    '100px',
                    '75px',
                ];

            document.querySelectorAll('.tablefilterUserCardDocumentsUploaded').forEach(function(table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },
    }
}

export default userFunction
