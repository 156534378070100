import Swal from "sweetalert2";

const SwalModalFunctions = {

    returnError: (reason, isPageHaveToBeReloaded) => {
        Swal.fire({
            title: reason,
            icon: 'error',
            confirmButtonText: 'Fermer',
            confirmButtonColor: '#dc3741',
        }).then(() => {
            if (isPageHaveToBeReloaded) {
                location.reload()
            }
        })
    },

    returnSuccess: (reason, isPageHaveToBeReloaded) => {
        Swal.fire({
            title: reason,
            icon: 'success',
            confirmButtonText: 'Fermer',
            confirmButtonColor: 'rgb(92, 184, 92)',
        }).then(() => {
            if (isPageHaveToBeReloaded) {
                location.reload()
            }
        })
    },
}

export default SwalModalFunctions
