const orderSupplierFunctions = {
    tableFilter: function() {

        let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'input',
                config.col_1 = 'checklist',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'checklist',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'input',
                config.col_8 = 'input',
                config.col_9 = 'input',
                config.col_10 = 'input',
                config.col_11 = 'input',
                config.col_12 = 'input',
                config.col_13 = 'input',
                config.col_14 = 'input',
                config.col_15 = 'input',
                config.col_16 = 'checklist',
                config.col_17 = 'input',
                config.col_18 = 'checklist',
                config.col_19 = 'checklist',
                config.col_20 = 'none'
            config.col_types = [
                'number',
                'string',
                'string',
                'string',
                'string',
                'number',
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                'string',
                'string',
                'number',
                'string',
                'string',
                'none',
            ];


        document.querySelectorAll('.tablefilter').forEach(function(table) {

            let tf = new TableFilter(table, config);
            tf.init();

            let searchKeywords = propertyFunctions.getSearchKeywordsForTables();

            propertyFunctions.setFiltersOnTableIfExist(tf, searchKeywords);

            tf.filter();
        });
    },

    setFiltersOnTableIfExist: function(tf, searchKeywords) {

        if (searchKeywords.building !== null) {
            tf.setFilterValue(1, [searchKeywords.building]);
            tf.activateFilter(1);
        }
        if (searchKeywords.type !== null) {
            tf.setFilterValue(4, [searchKeywords.type]);
            tf.activateFilter(4);
        }
        if (searchKeywords.bedroomMinimum !== null) {
            tf.setFilterValue(5, ['>='+searchKeywords.bedroomMinimum]);
            tf.activateFilter(5);
        }
        if (searchKeywords.priceSaleStart !== null && searchKeywords.priceSaleEnd === null) {
            tf.setFilterValue(14, ['>='+priceSaleStartLess10Pourcent]);
            tf.activateFilter(14);
        }
        if (searchKeywords.priceSaleEnd !== null && searchKeywords.priceSaleStart === null) {
            tf.setFilterValue(14, ['<='+searchKeywords.priceSaleEnd]);
            tf.activateFilter(14);
        }
        if (searchKeywords.priceSaleEnd !== null && searchKeywords.priceSaleStart !== null) {
            tf.setFilterValue(14, ['>='+searchKeywords.priceSaleStart+' && <='+searchKeywords.priceSaleEnd]);
            tf.activateFilter(14);
        }
        if (searchKeywords.objective !== null) {
            tf.setFilterValue(19, [searchKeywords.objective]);
            tf.activateFilter(19);
        }
    },
    index: {
        tableFilter: function() {

            let tableClassSelector = '.tablefilterOrderSupplierIndex';

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'checklist',
                config.col_2 = 'checklist',
                config.col_3 = 'input',
                config.col_4 = 'checklist',
                config.col_5 = 'checklist',
                config.col_6 = 'none',
                config.col_7 = 'none',
                config.col_8 = 'none',
                config.col_9 = 'checklist',
                config.col_10 = 'none',
                config.col_11 = 'checklist',
                config.col_12 = 'checklist',
                config.col_13 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'number',
                    'number',
                    'number',
                    'string',
                    'string',
                    'string',
                ]
                /*config.extensions = [
                    {
                        name: 'sort',
                        sort_col_at_start: [0, false],
                        on_after_sort:function(o, colIndex){

                            tableFilterFunctions.setTotalRowFromTableAlwaysBottom(tableClassSelector);
                        },
                    }, {
                        name: 'colOps',
                        id: [
                            'sumBedroom',
                            'sumBathroom',
                            'sumSeparateWc',
                            'sumStoreroom',
                            'sumBuanderie',
                            'sumQuotShare',
                            'sumSurfacePlan',
                            'sumSurfaceAnnexe',
                            'sumSurfaceCalculated',
                            'sumUnitPriceTVAC3',
                            'sumForfaitTVAC3',
                            'sumUnitPriceTVAC17',
                            'sumForfaitTVAC17',
                            'sumSellingPriceTVAC3HPT',
                            'sumSellingPriceTVAC17HPT',
                            'sumSellingPriceHTVAHPT',
                            'sumPriceLand',
                            'sumTVA3',
                            'sumTVA17',
                            'sumSellingPriceTVAC3PlusPT',
                            'sumSellingPriceTVAC17PlusPT',
                            'sumDiscountHTVA',
                            'sumSellingPriceGlobalTVAC3',
                            'sumSellingPriceGlobalTVAC17',
                            'sellingPriceGlobalHTVAPlusPT'
                        ],
                        col: [
                            5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        ],
                        operation: [
                            'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum',
                        ],
                        decimal_precision: [
                            0, 0, 0, 0, 0, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
                        ],
                    }
                ];*/

            let tf = null;
            document.querySelectorAll(tableClassSelector).forEach(function(table) {
                tf = new TableFilter(table, config);
                tf.init();
            });

            tableFilterFunctions.setTotalRowInTable(tf, tableClassSelector);

        }
    }
}

export default orderSupplierFunctions
