const buildingFunctions = {
    tableFilter: function(isCompanyDisplayed) {

        let config = tableFilterFunctions.defaultConfig();

        if (isCompanyDisplayed) {

            config.col_0 = 'none',
                config.col_1 = 'input',
                config.col_2 = 'checklist',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'checklist',
                config.col_8 = 'checklist',
                config.col_9 = 'checklist',
                config.col_10 = 'checklist',
                config.col_11 = 'checklist',
                config.col_12 = 'checklist',
                config.col_types = [
                    'none',
                    'string',
                    'string',
                    'string',
                    'number',
                    'number',
                    'number',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                ];

        } else {

            config.col_0 = 'none',
                config.col_1 = 'input',
                config.col_2 = 'checklist',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'checklist',
                config.col_8 = 'checklist',
                config.col_9 = 'checklist',
                config.col_10 = 'checklist',
                config.col_11 = 'checklist',
                config.col_types = [
                    'none',
                    'string',
                    'string',
                    'string',
                    'number',
                    'number',
                    'number',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                ];

        }

        document.querySelectorAll('.tablefilter').forEach(function(table) {
            let tf = new TableFilter(table, config);
            tf.init();
        });
    },
    folder: {
        financial: {
            stepConstructionSite: {
                tablefilterStepConstructionSiteAndCDR: function() {

                    let tableClassSelector = '.tablefilterStepConstructionSiteAndCDR';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'input',
                        config.col_1 = 'checklist',
                        config.col_2 = 'input',
                        config.col_3 = 'input',
                        config.col_4 = 'checklist',
                        config.col_5 = 'checklist',
                        config.col_6 = 'checklist',
                        config.col_7 = 'none',
                        config.col_8 = 'none',
                        config.col_types = [
                            'string',
                            'string',
                            'string',
                            'number',
                            'string',
                            'string',
                            'none',
                            'none',
                        ],
                        config.extensions = [
                            {
                                name: 'sort',
                                on_after_sort:function(o, colIndex){

                                    tableFilterFunctions.setTotalRowFromTableAlwaysBottom(tableClassSelector);
                                },
                            }, {
                                name: 'colOps',
                                id: [
                                    'sumCDR',
                                ],
                                col: [
                                    3,
                                ],
                                operation: [
                                    'sum',
                                ],
                                decimal_precision: [
                                    0,
                                ],
                            }
                        ],
                        config.col_widths = [
                            '100px',
                            '325px',
                            '325px',
                            '75px',
                            '125px',
                            '125px',
                            '100px',
                            '100px',
                        ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                    tableFilterFunctions.setTotalRowInTable(tf, tableClassSelector);

                    appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationConstructionSite', '#sumValidationConstructionSite');
                    appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationFinancial', '#sumValidationFinancial');

                    tf.emitter.on(['after-filtering'], function(tf, searchArgs) {
                        appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationConstructionSite', '#sumValidationConstructionSite');
                        appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationFinancial', '#sumValidationFinancial');
                    });

                },

                tablefilterStepConstructionSiteAndCDRConsultation: function() {

                    let tableClassSelector = '.tablefilterStepConstructionSiteAndCDR';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'checklist',
                        config.col_1 = 'checklist',
                        config.col_2 = 'input',
                        config.col_3 = 'input',
                        config.col_4 = 'checklist',
                        config.col_5 = 'checklist',
                        config.col_types = [
                            'number',
                            'string',
                            'string',
                            'number',
                            'string',
                            'string',
                        ],
                        config.extensions = [
                            {
                                name: 'sort',
                                on_after_sort:function(o, colIndex){

                                    tableFilterFunctions.setTotalRowFromTableAlwaysBottom(tableClassSelector);
                                },
                            }, {
                                name: 'colOps',
                                id: [
                                    'sumCDR',
                                ],
                                col: [
                                    3,
                                ],
                                operation: [
                                    'sum',
                                ],
                                decimal_precision: [
                                    0,
                                ],
                            }
                        ]

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                   //tableFilterFunctions.setTotalRowInTable(tf, tableClassSelector);

                    appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationConstructionSite', '#sumValidationConstructionSite');
                    appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationFinancial', '#sumValidationFinancial');

                    tf.emitter.on(['after-filtering'], function(tf, searchArgs) {
                        appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationConstructionSite', '#sumValidationConstructionSite');
                        appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationFinancial', '#sumValidationFinancial');
                    });

                },

                displayValidationStepConstructionSiteAndCDRInTableFilter: function() {

                    $('.tot').append($('#validationStepConstructionSiteAndCDR').html());
                },
                displayValidationPriceEvolvingInTableFilter: function() {

                    $('.tot').append($('#validationPriceEvolving').html());
                },
            },
            analyticalAccountBudgets: {
                tablefilterAnalyticalAccountBudgets: function () {

                    let tableClassSelector = '.tablefilterAnalyticalAccountsValidations';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'checklist',
                        config.col_1 = 'checklist',
                        config.col_2 = 'checklist',
                        config.col_3 = 'input',
                        config.col_4 = 'none',
                        config.col_5 = 'none',
                        config.col_6 = 'none',
                        config.col_types = [
                            'number',
                            'string',
                            'string',
                            'string',
                            'string',
                            'none',
                            'none',
                        ],
                        config.col_widths = [
                            '200px',
                            '200px',
                            '200px',
                            '200px',
                            '200px',
                            '100px',
                            '75px',
                        ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function (table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                },
            },
            analyticalAccount: {
                tablefilterAnalyticalAccount: function() {

                    let tableClassSelector = '.tablefilterAnalyticalAccount';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'input',
                        config.col_1 = 'input',
                        config.col_2 = 'checklist',
                        config.col_3 = 'checklist',
                        config.col_4 = 'none',
                        config.col_types = [
                            'number',
                            'string',
                            'string',
                            'string',
                            'none',
                        ],
                        config.col_widths = [
                            '100px',
                            '325px',
                            '325px',
                            '100px',
                            '75px',
                        ];
                }
            },
            costSelectDate: {
                tablefilterCostSelectDate: function() {

                    let tableClassSelector = '.tablefilterCostSelectDate';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_types = [
                        { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                        'none'
                    ],
                        config.col_widths = [
                            '500px',
                            '100px',
                        ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                    $('.grd_headTblCont th:last').html('');
                },

            },
            costDashboard: {
                tablefilterCostDashboard: function() {

                    let tableClassSelector = '.tablefilterCostDashboard';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'input',
                        config.col_1 = 'checklist',
                        config.col_2 = 'input',
                        config.col_types = [
                            'number',
                            'string',
                            'string',
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                        ];
                    config.col_widths = [
                        '100px',
                        '300px',
                        '350px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                        '125px',
                    ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                    $('.grd_headTblCont th:last').html('');
                },
            },
        },
        marketing: {
            priceInitial: {
                tableFilterPriceInitial: function() {

                    let tableClassSelector = '.tablefilterBuildingFolderMarketingPriceInitial';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'checklist',
                        config.col_1 = 'input',
                        config.col_2 = 'input',
                        config.col_3 = 'checklist',
                        config.col_4 = 'checklist',
                        config.col_5 = 'input',
                        config.col_6 = 'input',
                        config.col_7 = 'input',
                        config.col_8 = 'input',
                        config.col_9 = 'input',
                        config.col_10 = 'input',
                        config.col_11 = 'input',
                        config.col_12 = 'input',
                        config.col_13 = 'input',
                        config.col_14 = 'input',
                        config.col_15 = 'input',
                        config.col_16 = 'input',
                        config.col_17 = 'input',
                        config.col_18 = 'input',
                        config.col_19 = 'input',
                        config.col_20 = 'input',
                        config.col_21 = 'input',
                        config.col_22 = 'input',
                        config.col_23 = 'input',
                        config.col_24 = 'input',
                        config.col_25 = 'input',
                        config.col_26 = 'input',
                        config.col_27 = 'input',
                        config.col_28 = 'none',
                        config.col_types = [
                            'string',
                            'string',
                            'string',
                            'string',
                            'string',
                            'number',
                            'number',
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            'none',
                        ],
                        config.extensions = [
                            {
                                name: 'sort',
                                on_after_sort: function (o, colIndex) {

                                    tableFilterFunctions.setTotalRowFromTableAlwaysBottom(tableClassSelector);
                                },
                            }, {
                                name: 'colOps',
                                id: [
                                    'sumQuotShare',
                                    'sumSurfacePlan',
                                    'sumSurfaceAnnexe',
                                    'sumSurfaceCalculated',
                                    'sumUnitPriceTVAC3',
                                    'sumForfaitTVAC3',
                                    'sumUnitPriceTVAC17',
                                    'sumForfaitTVAC17',
                                    'sumSellingPriceTVAC3HPT',
                                    'sumSellingPriceTVAC17HPT',
                                    'sumSellingPriceHTVAHPT',
                                    'sumPriceLand',
                                    'sumTVA3Intermediary',
                                    'sumTVA3',
                                    'sumTVA17',
                                    'sumSellingPriceTVAC3PlusPT',
                                    'sumSellingPriceTVAC17PlusPT',
                                    'sumDiscountHTVA',
                                    'sumSellingPriceGlobalTVAC3',
                                    'sumSellingPriceGlobalTVAC17',
                                    'sellingPriceGlobalHTVAPlusPT'
                                ],
                                col: [
                                    7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27
                                ],
                                operation: [
                                    'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum'
                                ],
                                decimal_precision: [
                                    3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
                                ],
                            }
                        ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                    tableFilterFunctions.setTotalRowInTable(tf, tableClassSelector);

                }
            },
            priceEvolving: {
                tableFilterPriceEvolving: function() {

                    let tableClassSelector = '.tablefilterBuildingFolderMarketingPriceEvolving';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'checklist',
                        config.col_1 = 'input',
                        config.col_2 = 'input',
                        config.col_3 = 'checklist',
                        config.col_4 = 'checklist',
                        config.col_5 = 'input',
                        config.col_6 = 'input',
                        config.col_7 = 'input',
                        config.col_8 = 'input',
                        config.col_9 = 'input',
                        config.col_10 = 'input',
                        config.col_11 = 'input',
                        config.col_12 = 'input',
                        config.col_13 = 'input',
                        config.col_14 = 'input',
                        config.col_15 = 'input',
                        config.col_16 = 'input',
                        config.col_17 = 'input',
                        config.col_18 = 'input',
                        config.col_19 = 'input',
                        config.col_20 = 'input',
                        config.col_21 = 'input',
                        config.col_22 = 'input',
                        config.col_23 = 'input',
                        config.col_24 = 'input',
                        config.col_25 = 'input',
                        config.col_26 = 'input',
                        config.col_27 = 'none',
                        config.col_types = [
                            'string',
                            'string',
                            'string',
                            'string',
                            'string',
                            'number',
                            'number',
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            'none',
                        ],
                        config.extensions = [
                            {
                                name: 'sort',
                                on_after_sort:function(o, colIndex){

                                    tableFilterFunctions.setTotalRowFromTableAlwaysBottom(tableClassSelector);
                                },
                            }, {
                                name: 'colOps',
                                id: [
                                    'sumQuotShare',
                                    'sumSurfacePlan',
                                    'sumSurfaceAnnexe',
                                    'sumSurfaceCalculated',
                                    'sumUnitPriceTVAC3',
                                    'sumForfaitTVAC3',
                                    'sumUnitPriceTVAC17',
                                    'sumForfaitTVAC17',
                                    'sumSellingPriceTVAC3HPT',
                                    'sumSellingPriceTVAC17HPT',
                                    'sumSellingPriceHTVAHPT',
                                    'sumPriceLand',
                                    'sumTVA3',
                                    'sumTVA17',
                                    'sumSellingPriceTVAC3PlusPT',
                                    'sumSellingPriceTVAC17PlusPT',
                                    'sumDiscountHTVA',
                                    'sumSellingPriceGlobalTVAC3',
                                    'sumSellingPriceGlobalTVAC17',
                                    'sellingPriceGlobalHTVAPlusPT'
                                ],
                                col: [
                                    7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                                ],
                                operation: [
                                    'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum',
                                ],
                                decimal_precision: [
                                    3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
                                ],
                            }
                        ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                    tableFilterFunctions.setTotalRowInTable(tf, tableClassSelector);
                },
            },
            priceHistory: {
                tableFilterPriceHistory: function() {

                    let tableClassSelector = '.tablefilterBuildingFolderMarketingPriceHistory';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'checklist',
                        config.col_1 = 'checklist',
                        config.col_2 = 'input',
                        config.col_3 = 'input',
                        config.col_4 = 'checklist',
                        config.col_5 = 'checklist',
                        config.col_6 = 'input',
                        config.col_7 = 'input',
                        config.col_8 = 'input',
                        config.col_9 = 'input',
                        config.col_10 = 'input',
                        config.col_11 = 'input',
                        config.col_12 = 'input',
                        config.col_13 = 'input',
                        config.col_14 = 'input',
                        config.col_15 = 'input',
                        config.col_16 = 'input',
                        config.col_17 = 'input',
                        config.col_18 = 'input',
                        config.col_19 = 'input',
                        config.col_20 = 'input',
                        config.col_21 = 'input',
                        config.col_22 = 'input',
                        config.col_23 = 'input',
                        config.col_24 = 'input',
                        config.col_25 = 'input',
                        config.col_26 = 'input',
                        config.col_types = [
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            'string',
                            'string',
                            'string',
                            'string',
                            'string',
                            'number',
                            'number',
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                            { type: 'formatted-number', decimal: ',', thousands: ' ' },
                        ],
                        config.extensions = [
                            {
                                name: 'sort',
                                on_after_sort:function(o, colIndex){

                                    tableFilterFunctions.setTotalRowFromTableAlwaysBottom(tableClassSelector);
                                },
                            }, {
                                name: 'colOps',
                                id: [
                                    'sumQuotShare',
                                    'sumSurfacePlan',
                                    'sumSurfaceAnnexe',
                                    'sumSurfaceCalculated',
                                    'sumUnitPriceTVAC3',
                                    'sumForfaitTVAC3',
                                    'sumUnitPriceTVAC17',
                                    'sumForfaitTVAC17',
                                    'sumSellingPriceTVAC3HPT',
                                    'sumSellingPriceTVAC17HPT',
                                    'sumSellingPriceHTVAHPT',
                                    'sumPriceLand',
                                    'sumTVA3',
                                    'sumTVA17',
                                    'sumSellingPriceTVAC3PlusPT',
                                    'sumSellingPriceTVAC17PlusPT',
                                    'sumDiscountHTVA',
                                    'sumSellingPriceGlobalTVAC3',
                                    'sumSellingPriceGlobalTVAC17',
                                ],
                                col: [
                                    8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                                ],
                                operation: [
                                    'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum',
                                ],
                                decimal_precision: [
                                    3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
                                ],
                            }
                        ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                    tableFilterFunctions.setTotalRowInTable(tf, tableClassSelector);

                },
            },
            displayValidationPriceInitialInTableFilter: function() {

                $('.tot').append($('#validationPriceInitial').html());
            },
        },
        construction: {
            costItem: {
                tablefilterCostItemSelect: function() {

                    let tableClassSelector = '.tablefilterCostItemSelect';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'input',
                        config.col_1 = 'input',
                        config.col_2 = 'checklist',
                        config.col_3 = 'checklist',
                        config.col_4 = 'checklist',
                        config.col_5 = 'input',
                        config.col_6 = 'input',
                        config.col_7 = 'input',
                        config.col_types = [
                            'number',
                            'string',
                            'string',
                            'string',
                            'string',
                            'number',
                            'number',
                            'number',
                        ],
                        config.col_widths = [
                            '100px',
                            '325px',
                            '325px',
                            '200px',
                            '200px',
                            '100px',
                            '100px',
                            '100px',
                        ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                },
                tablefilterCostItemDisplay: function() {

                    let tableClassSelector = '.tablefilterCostItemDisplay';

                    let config = tableFilterFunctions.defaultConfig();

                    config.col_0 = 'none',
                        config.col_1 = 'none',
                        config.col_2 = 'none',
                        config.col_3 = 'none',
                        config.col_4 = 'none',
                        config.col_5 = 'none',
                        config.col_types = [
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                        ],
                        config.col_widths = [
                            '100px',
                            '325px',
                            '325px',
                            '200px',
                            '200px',
                            '100px',
                        ];

                    let tf = null;
                    document.querySelectorAll(tableClassSelector).forEach(function(table) {
                        tf = new TableFilter(table, config);
                        tf.init();
                    });

                    $('.grd_headTblCont th:last').html('');

                },
                getTotals: function() {

                    let defaultValuesOfTotals = buildingFunctions.folder.construction.costItem.getDefaultValuesOfTotals();

                    $.each(defaultValuesOfTotals, function(column) {

                        defaultValuesOfTotals[column] = buildingFunctions.folder.construction.costItem.getTotalsForColumn(column);
                    });

                    return defaultValuesOfTotals;
                },
                getTotalsForColumn: function(column) {

                    let fieldsToCheck = [];

                    fieldsToCheck.push($('.'+column));

                    let totals = {
                        'analyticalAccountSupplier': 0,
                    };

                    $.each(fieldsToCheck, function(index, fieldToCheck) {

                        $.each(fieldToCheck, function() {

                            let type = $(this).parent().attr('id');
                            let amountFieldText = $(this).text();
                            let amountFieldNumeral = numeral(amountFieldText);

                            totals[type] = totals[type] + amountFieldNumeral._value;

                        });
                    });

                    return totals;
                },
                getDefaultValuesOfTotals: function() {

                    let defaultValuesOfTotals = {
                        'totalCostHTVA': null,
                        'totalAnnualBudget': null,
                    };

                    buildingFunctions.folder.construction.costItem.addBudgetYearsToDefaultValuesOfTotals(defaultValuesOfTotals)

                    return defaultValuesOfTotals;
                },
                getBudgetYears: function() {

                    let budgetYears = [];
                    let $budgetsAmount = $('.tablefilterCostItemDisplay tbody').find('.budgetAmount');

                    $budgetsAmount.each(function() {

                        let budgetYear = $(this).attr('class').split(' ')[1];
                        budgetYears.push(budgetYear);
                    });

                    let uniqueBudgetYears = [...new Set(budgetYears)];

                    return uniqueBudgetYears;
                },
                addBudgetYearsToDefaultValuesOfTotals: function(defaultValuesOfTotals) {

                    let budgetYears = buildingFunctions.folder.construction.costItem.getBudgetYears();

                    $.each(budgetYears, function(index, budgetYear){

                        //probleme ici
                        //defaultValuesOfTotals['budgetAmount'+budgetYear] = null;



                        /*defaultValuesOfTotals['TVA17BudgetAmount'+budgetYear] = null;
                        defaultValuesOfTotals['TVACBudgetAmount'+budgetYear] = null;*/
                    });
                },
                setTotals: function() {

                    let totals = buildingFunctions.folder.construction.costItem.getTotals();
                    $.each(totals, function(columnName, subTotal) {

                        $.each(subTotal, function(type, amount) {

                            let amountNumeral = numeral(amount).format('0,0.00');
                            let typeWithoutSpace = type.replace(/\s+/g, '');
                            let subTotalRow = $('.total' + appFunctions.capitalizeFirstLetter(columnName) + '_' + typeWithoutSpace);

                            subTotalRow.html(amountNumeral);
                        });
                    });
                },
            },
        },
        salesDashboard : {
            tablefilterSales: function() {

                let tableClassSelector = '.tablefilterSalesDashboard';

                let config = tableFilterFunctions.defaultConfig();

                config.col_0 = 'input',
                    config.col_1 = 'input',
                    config.col_2 = 'input',
                    config.col_3 = 'checklist',
                    config.col_4 = 'checklist',
                    config.col_types = [
                        'string',
                        'string',
                        'string',
                        'string',
                        'string',
                    ],
                    config.extensions = [
                        {
                            name: 'sort',
                            on_after_sort:function(o, colIndex){

                                tableFilterFunctions.setTotalRowFromTableAlwaysBottom(tableClassSelector);
                            },
                        }, {
                            name: 'colOps',
                            id: [
                                'sumCDR',
                            ],
                            col: [
                                3,
                            ],
                            operation: [
                                'sum',
                            ],
                            decimal_precision: [
                                0,
                            ],
                        }
                    ],
                    config.col_widths = [
                        '200px',
                        '200px',
                        '200px',
                        '200px',
                        '200px',
                    ];

                let tf = null;
                document.querySelectorAll(tableClassSelector).forEach(function(table) {
                    tf = new TableFilter(table, config);
                    tf.init();
                });

                tableFilterFunctions.setTotalRowInTable(tf, tableClassSelector);

                appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationConstructionSite', '#sumValidationConstructionSite');
                appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationFinancial', '#sumValidationFinancial');

                tf.emitter.on(['after-filtering'], function(tf, searchArgs) {
                    appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationConstructionSite', '#sumValidationConstructionSite');
                    appFunctions.sumBooleanFields('.tablefilterStepConstructionSiteAndCDR', '.validationFinancial', '#sumValidationFinancial');
                });

            },
        },
        validations : {
            tablefilterValidations: function() {

                console.log('yo')
                let tableClassSelector = '.tablefilter';

                let config = tableFilterFunctions.defaultConfig();

                config.col_0 = 'input',
                    config.col_1 = 'checklist',
                    config.col_2 = 'checklist',
                    config.col_3 = 'input',
                    config.col_4 = 'none',
                    config.col_types = [
                        'string',
                        'string',
                        'string',
                        'string',
                        'none',
                    ]
                document.querySelectorAll(tableClassSelector).forEach(function(table) {
                    const tf = new TableFilter(table, config);
                    tf.init();
                });
            },
        }
    }
}

export default buildingFunctions
