const accountFunctions = {
    tableFilter: function(isCompanyDisplayed) {

        let config = tableFilterFunctions.defaultConfig();

        if (isCompanyDisplayed) {

            config.col_0 = 'input',
                config.col_1 = 'input',
                config.col_2 = 'checklist',
                config.col_3 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    'string',
                    'none',
                ],
                config.col_widths = [
                    '100px',
                    '300px',
                    '150px',
                    '75px',
                ];

        } else {

            config.col_0 = 'input',
                config.col_1 = 'input',
                config.col_2 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    'none',
                ],
                config.col_widths = [
                    '100px',
                    '450px',
                    '75px',
                ];

        }

        document.querySelectorAll('.tablefilter').forEach(function(table) {
            let tf = new TableFilter(table, config);
            tf.init();
        });
    }
}

export default accountFunctions
