import Swal from "sweetalert2";
import $ from "jquery";
import Routing from "../../public/bundles/fosjsrouting/js/router";
import SwalModalFunctions from "./swalModal";

const costDashboardFunctions = {

    adjustGlobalBudget: (analyticalAccountId) => {

        Swal.fire({
            template: '#adjust_modal_' + analyticalAccountId,
        }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    type: "POST",
                    url: Routing.generate('analytical_account_set_adjust_budget'),
                    data: JSON.stringify({
                        amount: result.value,
                        analyticalAccountId: analyticalAccountId,
                      /*  pendingValidationCostDashboard: pendingValidationCostDashboard*/
                    }),
                    cache: false,
                    dataType: "json",
                }).done(function (result) {
                    const response = JSON.parse(result.data)
                    if (!response.success) {
                        SwalModalFunctions.returnError(response.reason, false)
                    } else {

                        Swal.fire({
                            title: response.reason,
                            icon: 'success',
                            input: 'text',
                            inputLabel: 'Ajouter un commentaire',
                            cancelButtonText: 'Fermer',
                            showCancelButton: true,
                            cancelButtonColor: '#dc3741',
                            confirmButtonText: 'Enregistrer',
                            confirmButtonColor: 'rgb(92, 184, 92)',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                $.ajax({
                                    type: "POST",
                                    url: Routing.generate('analytical_account_set_adjust_comment'),
                                    data: JSON.stringify({
                                        comment: result.value,
                                        validation: response.validationId
                                    }),
                                    cache: false,
                                    dataType: "json",
                                }).done(function (result) {
                                    if (!response.success) {
                                        const response = JSON.parse(result.data)
                                        SwalModalFunctions.returnError(response.reason, true)
                                    } else {
                                        SwalModalFunctions.returnSuccess(response.reason, true)
                                    }
                                })
                            } else {
                                location.reload()
                            }
                        })
                    }
                })

            }
        })
    },

    adjustAnnualBudget: (analyticalAccountId, labelSplit) => {
        Swal.fire({
            template: '#adjust_modal_' + analyticalAccountId + '_' + labelSplit[1],
        }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    type: "POST",
                    url: Routing.generate('analytical_account_set_adjust_budget_annual'),
                    data: JSON.stringify({
                        analyticalAccountId: analyticalAccountId,
                        year: labelSplit[1],
                        amount: result.value,
                    }),
                    cache: false,
                    dataType: "json",
                }).done(function (result) {
                    const response = JSON.parse(result.data)
                    if (!response.success) {
                        /*TEMPLATE RESPONSE ERROR*/
                        const response = JSON.parse(result.data)
                        SwalModalFunctions.returnError(response.reason, true)
                    } else {
                        SwalModalFunctions.returnSuccess(response.reason, true)
                    }
                })

            }
        })
    }

}

export default costDashboardFunctions
