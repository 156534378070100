import intlTelInputFunctions from "./intlTelInput";
import tableFilterFunctions from "./tableFilter";

const supplierFunctions = {
    tableFilter: function(isCompanyDisplayed) {

        let config = tableFilterFunctions.defaultConfig();

        if (isCompanyDisplayed) {

            config.col_0 = 'input',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_7 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'none',
                ];

        } else {

            config.col_0 = 'input',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_7 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    'none',
                ];

        }

        document.querySelectorAll('.tablefilter').forEach(function(table) {
            let tf = new TableFilter(table, config);
            tf.init();
        });
    },
    IntlTelInputSupplier: function() {

        let config = intlTelInputFunctions.defaultConfig();

        $("#supplier_coordinate_phoneNumber1").intlTelInput(config);

        $("form").submit(function(e) {
            $("#supplier_coordinate_phoneNumber1").val($("#supplier_coordinate_phoneNumber1").intlTelInput("getNumber"));
        });
    },
    IntlTelInputContactPerson: function() {

        config = intlTelInputFunctions.defaultConfig();

        $("#contact_person_coordinate_phoneNumber1").intlTelInput(config);
        $("#contact_person_coordinate_phoneNumber2").intlTelInput(config);

        $("form").submit(function(e) {
            $("#contact_person_coordinate_phoneNumber1").val($("#contact_person_coordinate_phoneNumber1").intlTelInput("getNumber"));
            $("#contact_person_coordinate_phoneNumber2").val($("#contact_person_coordinate_phoneNumber2").intlTelInput("getNumber"));
        });
    },
    folder: {
        tableFilterCardDocumentsUploaded: function() {

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'none',
                ],
                config.col_widths = [
                    '125px',
                    '325px',
                    '100px',
                    '75px',
                ];

            document.querySelectorAll('.tablefilterSupplierCardDocumentsUploaded').forEach(function(table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },
        costItem: {
            displayDifferentTablesOnModal: function() {

                let defaultModals = supplierFunctions.folder.costItem.getDataForModals();

                supplierFunctions.folder.costItem.removeDataHTMLForModalsFromDOM(defaultModals);

                $('body').on('click', '.buttonsModal button', function() {

                    let tableName = $(this).attr('id');
                    let modalTableSelected =  tableName + '_' + $(this).attr('class').split(' ')[0];

                    let contentAndTitleForModal = supplierFunctions.folder.costItem.getContentAndTitleForModal(defaultModals, modalTableSelected);

                    $.alert({
                        title: "<div style='text-align:center'>"+contentAndTitleForModal[0].title+"</div>",
                        content: contentAndTitleForModal[0].content,
                        type: 'blue',
                        typeAnimated: true,
                        //draggable: true,
                        //dragWindowGap: 0, // number of px of distance
                        columnClass: 'col-sm-12',
                        /*useBootstrap: false,
                        boxWidth: '95%',*/
                        theme: 'modern',
                        buttons: {
                            submit: {
                                text: 'Enregistrer',
                                btnClass: 'btn-default',
                                action: function() {
                                    $('body').off('click', '.orderSupplierAddRowBtn');

                                    supplierFunctions.folder.costItem.onSubmit(tableName);

                                    return false; // to prevent close the modal.
                                },
                            },
                            cancel: {
                                text: 'Fermer',
                                btnClass: 'btn-default',
                                action: function() {
                                    $('body').off('click', '.orderSupplierAddRowBtn');
                                    $('body').off('click', '.orderSupplierEditRowBtn');
                                    $('body').off('click', '.orderSupplierRemoveRowBtn');
                                },
                            },
                        },
                        onContentReady: function () {

                            supplierFunctions.folder.costItem.tablefilterCostItemDisplayDifferentTablesOnModal(tableName);

                            supplierFunctions.folder.costItem.onAddRow(tableName);

                            supplierFunctions.folder.costItem.onEditRow(tableName);

                            supplierFunctions.folder.costItem.onDeleteRow();
                        },

                    });
                });
            },
            onAddRow: function(tableName) {

                $('body').on('click', '.orderSupplierAddRowBtn', function() {

                    let $currentTable = $(this).closest('.grd_Cont').find('.grd_tblCont table');

                    if (tableName === 'tablefilterCostItemDisplayOrder') {

                        $currentTable.prepend('<tr ordersupplierid="null">\
														<td class="name"><input type="text" class="form-control input-sm" /></td>\
														<td class="totalNumberSlice"><input type="text" class="form-control input-sm" /></td>\
														<td class="numberSlice"><input type="text" class="form-control input-sm" /></td>\
														<td class="dateStartWork"><input type="text" class="form-control input-sm" /></td>\
														<td class="dateEndWork"><input type="text" class="form-control input-sm" /></td>\
														<td class="date"><input type="text" class="form-control input-sm" /></td>\
														<td class="orderNumber"><input type="text" class="form-control input-sm" /></td>\
														<td class="amountHTVA"><input type="text" class="form-control input-sm" /></td>\
														<td class="rateTVA"><input type="text" class="form-control input-sm" /></td>\
														<td class="holdbackGuarantee"><input type="text" class="form-control input-sm" /></td>\
														<td class="prorataAccount"><input type="text" class="form-control input-sm" /></td>\
														<td class="paymentTerms">\
															<select name="paymentTerms" class="paymentTerms form-control input-sm">\
																<option value="8 jours">8 jours</option>\
																<option value="30 jours fin de mois">30 jours fin de mois</option>\
																<option value="60 jours fin de mois">60 jours fin de mois</option>\
																<option value="90 jours fin de mois">90 jours fin de mois</option>\
															</select>\
														</td>\
														<td class="escompte"><input type="text" class="form-control input-sm" /></td>\
														<td class="escompteDeadline"><input type="text" class="form-control input-sm" /></td>\
														<td><button type="button" class="orderSupplierRemoveRowBtn btn btn-default"><i class="fa fa-fw fa-remove" aria-hidden="true"></i></button>\
														</td>\
													</tr>');

                    } else if (tableName === 'tablefilterCostItemDisplaySupplierValidation') {

                        $currentTable.prepend('<tr ordersupplierid="null">\
														<td class="name"><input type="text" class="form-control input-sm" /></td>\
														<td class="date"><input type="text" class="form-control input-sm" /></td>\
														<td class="orderNumber"><input type="text" class="form-control input-sm" /></td>\
														<td class="amountHTVA"><input type="text" class="form-control input-sm" /></td>\
														<td class="estimatedDateInvoiceReceipt"><input type="text" class="form-control input-sm" /></td>\
														<td class="amountBillReceivableTVAC"><input type="text" class="form-control input-sm" /></td>\
														<td class="futureDateInvoicePayment"><input type="text" class="form-control input-sm" /></td>\
														<td><button type="button" class="orderSupplierRemoveRowBtn btn btn-default"><i class="fa fa-fw fa-remove" aria-hidden="true"></i></button></td>\
													</tr>');

                    } else if (tableName === 'tablefilterCostItemDisplayValidationFAConstructionSite') {

                        $currentTable.prepend('<tr ordersupplierid="null">\
														<td class="name"><input type="text" class="form-control input-sm" /></td>\
														<td class="date"><input type="text" class="form-control input-sm" /></td>\
														<td class="orderNumber"><input type="text" class="form-control input-sm" /></td>\
														<td class="amountHTVA"><input type="text" class="form-control input-sm" /></td>\
														<td class="estimatedDateInvoiceReceipt"><input type="text" class="form-control input-sm" /></td>\
														<td class="amountBillReceivableTVAC"><input type="text" class="form-control input-sm" /></td>\
														<td class="futureDateInvoicePayment"><input type="text" class="form-control input-sm" /></td>\
														<td><button type="button" class="orderSupplierRemoveRowBtn btn btn-default"><i class="fa fa-fw fa-remove" aria-hidden="true"></i></button></td>\
													</tr>');
                    }

                    $('.dateStartWork, .dateEndWork, .date, .estimatedDateInvoiceReceipt, .futureDateInvoicePayment').datepicker({
                        language: 'fr',
                        calendarWeeks: true,
                        autoclose: true,
                        todayHighlight: true,
                        orientation: 'bottom auto',
                    });
                });
            },
            onEditRow: function(tableName) {

                $('body').on('click', '.orderSupplierEditRowBtn', function() {

                    let $orderSupplierEditRowBtn = $(this);
                    let $currentRow = $orderSupplierEditRowBtn.closest('tr');

                    if (tableName === 'tablefilterCostItemDisplayOrder') {

                        let name = $currentRow.find('.name');
                        name.html('<input type="text" class="form-control input-sm" id="name" value="'+name.text()+'" />');

                        let totalNumberSlice = $currentRow.find('.totalNumberSlice');
                        totalNumberSlice.html('<input type="text" class="form-control input-sm" id="totalNumberSlice" value="'+totalNumberSlice.text()+'" />');

                        let numberSlice = $currentRow.find('.numberSlice');
                        numberSlice.html('<input type="text" class="form-control input-sm" id="numberSlice" value="'+numberSlice.text()+'" />');

                        let dateStartWork = $currentRow.find('.dateStartWork');
                        dateStartWork.html('<input type="text" class="form-control input-sm" id="dateStartWork" value="'+dateStartWork.text()+'" />');

                        let dateEndWork = $currentRow.find('.dateEndWork');
                        dateEndWork.html('<input type="text" class="form-control input-sm" id="dateEndWork" value="'+dateEndWork.text()+'" />');

                        let date = $currentRow.find('.date');
                        date.html('<input type="text" class="form-control input-sm" id="date" value="'+date.text()+'" />');

                        let orderNumber = $currentRow.find('.orderNumber');
                        orderNumber.html('<input type="text" class="form-control input-sm" id="orderNumber" value="'+orderNumber.text()+'" />');

                        let amountHTVA = $currentRow.find('.amountHTVA');
                        amountHTVA.html('<input type="text" class="form-control input-sm" id="amountHTVA" value="'+amountHTVA.text()+'" />');

                        let rateTVA = $currentRow.find('.rateTVA');
                        rateTVA.html('<input type="text" class="form-control input-sm" id="rateTVA" value="'+rateTVA.text()+'" />');

                        let holdbackGuarantee = $currentRow.find('.holdbackGuarantee');
                        holdbackGuarantee.html('<input type="text" class="form-control input-sm" id="holdbackGuarantee" value="'+holdbackGuarantee.text()+'" />');

                        let prorataAccount = $currentRow.find('.prorataAccount');
                        prorataAccount.html('<input type="text" class="form-control input-sm" id="prorataAccount" value="'+prorataAccount.text()+'" />');

                        let paymentTerms = $currentRow.find('.paymentTerms');
                        let selectPaymentTerms = '\
									<select name="paymentTerms" class="paymentTerms form-control input-sm">\
										<option value="8 jours" ' + ($.trim(paymentTerms.text()) === '8 jours' ? 'selected="selected"' : '') + '>8 jours</option>\
										<option value="30 jours fin de mois" ' + ($.trim(paymentTerms.text()) === '30 jours fin de mois' ? 'selected="selected"' : '') + '>30 jours fin de mois</option>\
										<option value="60 jours fin de mois" ' + ($.trim(paymentTerms.text()) === '60 jours fin de mois' ? 'selected="selected"' : '') + '>60 jours fin de mois</option>\
										<option value="90 jours fin de mois" ' + ($.trim(paymentTerms.text()) === '90 jours fin de mois' ? 'selected="selected"' : '') + '>90 jours fin de mois</option>\
									</select>';
                        paymentTerms.html(selectPaymentTerms);

                        let escompte = $currentRow.find('.escompte');
                        escompte.html('<input type="text" class="form-control input-sm" id="escompte" value="'+escompte.text()+'" />');

                        let escompteDeadline = $currentRow.find('.escompteDeadline');
                        escompteDeadline.html('<input type="text" class="form-control input-sm" id="escompteDeadline" value="'+escompteDeadline.text()+'" />');

                    } else if (tableName === 'tablefilterCostItemDisplaySupplierValidation') {

                        let name = $currentRow.find('.name');
                        name.html('<input type="text" class="form-control input-sm" id="name" value="'+name.text()+'" />');

                        let date = $currentRow.find('.date');
                        date.html('<input type="text" class="form-control input-sm" id="date" value="'+date.text()+'" />');

                        let orderNumber = $currentRow.find('.orderNumber');
                        orderNumber.html('<input type="text" class="form-control input-sm" id="orderNumber" value="'+orderNumber.text()+'" />');

                        let amountHTVA = $currentRow.find('.amountHTVA');
                        amountHTVA.html('<input type="text" class="form-control input-sm" id="amountHTVA" value="'+amountHTVA.text()+'" />');

                        let estimatedDateInvoiceReceipt = $currentRow.find('.estimatedDateInvoiceReceipt');
                        estimatedDateInvoiceReceipt.html('<input type="text" class="form-control input-sm" id="estimatedDateInvoiceReceipt" value="'+estimatedDateInvoiceReceipt.text()+'" />');

                        let amountBillReceivableTVAC = $currentRow.find('.amountBillReceivableTVAC');
                        amountBillReceivableTVAC.html('<input type="text" class="form-control input-sm" id="amountBillReceivableTVAC" value="'+amountBillReceivableTVAC.text()+'" />');

                        let futureDateInvoicePayment = $currentRow.find('.futureDateInvoicePayment');
                        futureDateInvoicePayment.html('<input type="text" class="form-control input-sm" id="futureDateInvoicePayment" value="'+futureDateInvoicePayment.text()+'" />');

                    } else if (tableName === 'tablefilterCostItemDisplayValidationFAConstructionSite') {

                        let name = $currentRow.find('.name');
                        name.html('<input type="text" class="form-control input-sm" id="name" value="'+name.text()+'" />');

                        let date = $currentRow.find('.date');
                        date.html('<input type="text" class="form-control input-sm" id="date" value="'+date.text()+'" />');

                        let orderNumber = $currentRow.find('.orderNumber');
                        orderNumber.html('<input type="text" class="form-control input-sm" id="orderNumber" value="'+orderNumber.text()+'" />');

                        let amountHTVA = $currentRow.find('.amountHTVA');
                        amountHTVA.html('<input type="text" class="form-control input-sm" id="amountHTVA" value="'+amountHTVA.text()+'" />');

                        let estimatedDateInvoiceReceipt = $currentRow.find('.estimatedDateInvoiceReceipt');
                        estimatedDateInvoiceReceipt.html('<input type="text" class="form-control input-sm" id="estimatedDateInvoiceReceipt" value="'+estimatedDateInvoiceReceipt.text()+'" />');

                        let amountBillReceivableTVAC = $currentRow.find('.amountBillReceivableTVAC');
                        amountBillReceivableTVAC.html('<input type="text" class="form-control input-sm" id="amountBillReceivableTVAC" value="'+amountBillReceivableTVAC.text()+'" />');

                        let futureDateInvoicePayment = $currentRow.find('.futureDateInvoicePayment');
                        futureDateInvoicePayment.html('<input type="text" class="form-control input-sm" id="futureDateInvoicePayment" value="'+futureDateInvoicePayment.text()+'" />');
                    }

                    $orderSupplierEditRowBtn.hide();

                    $('.dateStartWork, .dateEndWork, .date, .estimatedDateInvoiceReceipt, .futureDateInvoicePayment').datepicker({
                        language: 'fr',
                        calendarWeeks: true,
                        autoclose: true,
                        todayHighlight: true,
                        orientation: 'bottom auto',
                    });

                });
            },
            onDeleteRow: function() {

                $('body').on('click', '.orderSupplierRemoveRowBtn', function() {
                    $(this).parent().parent().remove();
                });
            },
            onSubmit: function(tableName) {

                let selectorTableString = '.jconfirm-content .grd_tblCont table';
                let table = $(selectorTableString);

                supplierFunctions.folder.costItem.withdrawAllInputsFromTableForOrderSupplier();

                supplierFunctions.folder.costItem.displayBackOrderSupplierEditRowBtn(table);

                supplierFunctions.folder.costItem.onAddRow(tableName);

                let datasParams = {
                    'analyticalAccountSupplierId': table.attr('analyticalAccountSupplierId'),
                    'tableName': tableName
                };

                let datas = [];

                datas.push(datasParams);

                $(selectorTableString + ' tr:not(.totalColumns)').each(function() {

                    let data = {
                        orderSupplierId: null,
                        name: null,
                        totalNumberSlice: null,
                        numberSlice: null,
                        dateStartWork: null,
                        dateEndWork: null,
                        date: null,
                        orderNumber: null,
                        amountHTVA: null,
                        rateTVA: null,
                        holdbackGuarantee: null,
                        prorataAccount: null,
                        paymentTerms: null,
                        escompte: null,
                        escompteDeadline: null,
                        estimatedDateInvoiceReceipt: null,
                        amountBillReceivableTVAC: null,
                        futureDateInvoicePayment: null,
                    };

                    data.orderSupplierId = $(this).attr('orderSupplierId');
                    data.name = $(this).find('.name').text();
                    data.totalNumberSlice = $(this).find('.totalNumberSlice').text();
                    data.numberSlice = $(this).find('.numberSlice').text();
                    data.dateStartWork = $(this).find('.dateStartWork').text();
                    data.dateEndWork = $(this).find('.dateEndWork').text();
                    data.date = $(this).find('.date').text();
                    data.orderNumber = $(this).find('.orderNumber').text();
                    data.amountHTVA = $(this).find('.amountHTVA').text();
                    data.rateTVA = $(this).find('.rateTVA').text();
                    data.holdbackGuarantee = $(this).find('.holdbackGuarantee').text();
                    data.prorataAccount = $(this).find('.prorataAccount').text();
                    data.paymentTerms = $(this).find('.paymentTerms').text();
                    data.escompte = $(this).find('.escompte').text();
                    data.escompteDeadline = $(this).find('.escompteDeadline').text();
                    data.estimatedDateInvoiceReceipt = $(this).find('.estimatedDateInvoiceReceipt').text();
                    data.amountBillReceivableTVAC = $(this).find('.amountBillReceivableTVAC').text();
                    data.futureDateInvoicePayment = $(this).find('.futureDateInvoicePayment').text();

                    datas.push(data);
                });

                /*let areAnalyticalAccountSuppliersCorrectlyEncoded = functions.building.folder.financial.analyticalAccountSupplier.checkIfAnalyticalAccountSuppliersAreCorrectlyEncodedBeforeUpdate();

                if (areAnalyticalAccountSuppliersCorrectlyEncoded) {*/

                $.ajax({
                    type: "POST",
                    url: Routing.generate('building_folder_order_supplier_update'),
                    data: {dataJson: JSON.stringify(datas)},
                    cache: false,
                    dataType: "json",
                }).done(function(data) {

                    location.reload();

                });
                /*}*/
            },
            displayBackOrderSupplierEditRowBtn: function($table) {

                let orderSupplierEditRowBtns = $table.find('.orderSupplierEditRowBtn');

                orderSupplierEditRowBtns.each(function(){
                    $(this).show();
                });
            },
            withdrawAllInputsFromTableForOrderSupplier: function() {

                $('.jconfirm-content .grd_tblCont table tbody tr td input, .jconfirm-content .grd_tblCont table tbody tr td select').each(function() {

                    let valueInput = $(this).val();

                    $(this).parent().html(valueInput);
                });
            },
            getContentAndTitleForModal: function(defaultModals, modalTableSelected) {

                let contentAndTitleForModal = [];

                for (let table in defaultModals) {
                    if (defaultModals[table].tableName === modalTableSelected) {

                        contentAndTitleForModal.push({
                            'content': defaultModals[table].html,
                            'title': defaultModals[table].title,
                        });
                    }
                }
                return contentAndTitleForModal;
            },
            getDataForModals: function() {

                let defaultModals = [];

                $('.tablesForModal div').each(function(index) {

                    let $table = $(this);
                    let tableName = $(this).find('table').attr('class').split(' ')[0] + '_' + $table.attr('class').split(' ')[1];
                    let tableTitle = $(this).find('table').attr('modalTitle');

                    defaultModals.push({
                        'tableName': tableName,
                        'el': $table,
                        'html': $table.html(),
                        'title': tableTitle
                    });
                });

                return defaultModals;
            },
            removeDataHTMLForModalsFromDOM: function(defaultModals) {

                for (let table in defaultModals) {
                    let removeAllDefaultModalElements = defaultModals[table].el.remove();
                }
            },
            tablefilterCostItemDisplayDifferentTablesOnModal: function(tableToDisplay) {

                let tableClassSelector = '.'+tableToDisplay;

                let config = tableFilterFunctions.defaultConfig();

                if (tableToDisplay === 'tablefilterCostItemDisplayOrder') {

                    config.col_0 = 'input',
                        config.col_1 = 'input',
                        config.col_2 = 'input',
                        config.col_3 = 'input',
                        config.col_4 = 'input',
                        config.col_5 = 'input',
                        config.col_6 = 'input',
                        config.col_7 = 'input',
                        config.col_8 = 'input',
                        config.col_9 = 'input',
                        config.col_10 = 'input',
                        config.col_11 = 'input',
                        config.col_12 = 'input',
                        config.col_13 = 'input',
                        config.col_14 = 'none',
                        config.col_types = [
                            'string',
                            'number',
                            'number',
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            'string',
                            { type: 'formatted-number', decimal: ',', thousands: '.' },
                            'number',
                            'number',
                            'number',
                            'string',
                            'number',
                            'string',
                            'none',
                        ],
                        config.col_widths = [
                            '100px',
                            '325px',
                        ];

                } else if (tableToDisplay === 'tablefilterCostItemDisplaySupplierValidation') {

                    config.col_0 = 'input',
                        config.col_1 = 'input',
                        config.col_2 = 'input',
                        config.col_3 = 'input',
                        config.col_4 = 'input',
                        config.col_5 = 'input',
                        config.col_6 = 'input',
                        config.col_7 = 'none',
                        config.col_types = [
                            'string',
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            'string',
                            { type: 'formatted-number', decimal: ',', thousands: '.' },
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            { type: 'formatted-number', decimal: ',', thousands: '.' },
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            'none',
                        ];

                } else if (tableToDisplay === 'tablefilterCostItemDisplayValidationFAConstructionSite') {

                    config.col_0 = 'input',
                        config.col_1 = 'input',
                        config.col_2 = 'input',
                        config.col_3 = 'input',
                        config.col_4 = 'input',
                        config.col_5 = 'input',
                        config.col_6 = 'input',
                        config.col_7 = 'input',
                        config.col_8 = 'input',
                        config.col_9 = 'input',
                        config.col_10 = 'input',
                        config.col_11 = 'input',
                        config.col_12 = 'input',
                        config.col_13 = 'none',
                        config.col_types = [
                            'string',
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            'string',
                            { type: 'formatted-number', decimal: ',', thousands: '.' },
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            'string',
                            'string',
                            'string',
                            { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                            'string',
                            { type: 'formatted-number', decimal: ',', thousands: '.' },
                            { type: 'formatted-number', decimal: ',', thousands: '.' },
                            'string',
                            'none',
                        ];
                }

                let tf = null;
                document.querySelectorAll(tableClassSelector).forEach(function(table) {
                    tf = new TableFilter(table, config);
                    tf.init();
                });
            },
            invoice: {
                onAddInvoice: function() {

                    $('body').on('click', '.orderSupplierInvoiceAddRowBtn', function() {

                        $.alert({
                            title: "<div style='text-align:center'>a</div>",
                            content: '<div style="width:2400px;border:1px solid red;"></div><p>lorem 	rezrezr ezr ezrez rzer zeLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla p</p>',
                            type: 'blue',
                            typeAnimated: true,
                            draggable: true,
                            dragWindowGap: 0, // number of px of distance
                            //columnClass: 'col-sm-12',
                            useBootstrap: false,
                            boxWidth: '50%',
                            theme: 'modern',
                            buttons: {
                                submit: {
                                    text: 'Enregistrer',
                                    btnClass: 'btn-default',
                                    action: function() {
                                        $('body').off('click', '.orderSupplierAddRowBtn');

                                        supplierFunctions.folder.costItem.onSubmit(tableName);

                                        return false; // to prevent close the modal.
                                    },
                                },
                                cancel: {
                                    text: 'Fermer',
                                    btnClass: 'btn-default',
                                    action: function() {
                                    },
                                },
                            },
                            onContentReady: function () {

                                console.log('ready');
                            },

                        });

                    });
                }
            },
        },
    }
}

export default supplierFunctions
