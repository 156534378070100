const historyFunctions = {
    tableFilter: function () {

        let config = tableFilterFunctions.defaultConfig();

        config.col_0 = 'input'
        config.col_1 = 'checklist'
        config.col_2 = 'input'
        config.col_3 = 'input'
        config.col_4 = 'input'
        config.col_5 = 'checklist'

        config.col_types = [
            'string',
            'string',
            'string',
            'string',
            'string',
            'string',
        ];

        /*config.col_widths = [
            '80%',
            '20%',
        ];*/


        document.querySelectorAll('.tablefilter').forEach(function (table) {

            let tf = new TableFilter(table, config);
            tf.init();
            tf.filter();
        });
    }
}

export default historyFunctions
