const propertyFunctions = {
    tableFilter: function(isCompanyDisplayed) {

        let config = tableFilterFunctions.defaultConfig();

        if (isCompanyDisplayed) {

            config.col_0 = 'checklist',
                config.col_1 = 'checklist',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'checklist',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'input',
                config.col_8 = 'input',
                config.col_9 = 'input',
                config.col_10 = 'input',
                config.col_11 = 'input',
                config.col_12 = 'input',
                config.col_13 = 'input',
                config.col_14 = 'input',
                config.col_15 = 'input',
                config.col_16 = 'checklist',
                config.col_17 = 'input',
                config.col_18 = 'checklist',
                config.col_19 = 'checklist',
                config.col_20 = 'none',
            config.col_types = [
                'string',
                'string',
                'string',
                'string',
                'string',
                'number',
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                'string',
                'string',
                'number',
                'string',
                'string',
                'string',
                'none',
            ];

        } else {

            config.col_0 = 'input',
                config.col_1 = 'checklist',
                config.col_2 = 'input',
                config.col_3 = 'input',
                config.col_4 = 'checklist',
                config.col_5 = 'input',
                config.col_6 = 'none',
                config.col_7 = 'none',
                config.col_8 = 'none',
                config.col_9 = 'none',
                config.col_10 = 'none',
                config.col_11 = 'none',
                config.col_12 = 'none',
                config.col_13 = 'none',
                config.col_14 = 'input',
                config.col_15 = 'checklist',
                config.col_16 = 'checklist',
                config.col_17 = 'checklist',
                config.col_18 = 'none',
                config.col_19 = 'none',
                config.col_20 = 'none'
            config.col_types = [
                'number',
                'string',
                'string',
                'string',
                'string',
                'number',
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                { type: 'formatted-number', decimal: ',', thousands: '.' },
                'string',
                'string',
                'number',
                'string',
                'string',
                'none',
            ];
        }

        document.querySelectorAll('.tablefilter').forEach(function(table) {

            let tf = new TableFilter(table, config);
            tf.init();

            let searchKeywords = propertyFunctions.getSearchKeywordsForTables();

            propertyFunctions.setFiltersOnTableIfExist(tf, searchKeywords);

            tf.filter();
        });
    },
    getSearchKeywordsForTables: function() {

        let searchKeywordsForTables = null;

        let params = (new URL(location)).searchParams;

        searchKeywordsForTables = {
            'building': params.get('building'),
            'type': params.get('type'),
            'bedroomMinimum': params.get('bedroomMinimum'),
            'priceSaleStart': params.get('priceSaleStart'),
            'priceSaleEnd': params.get('priceSaleEnd'),
            'objective': params.get('objective')
        }

        //console.log(searchKeywordsForTables.outsides);

        return searchKeywordsForTables;
    },
    setFiltersOnTableIfExist: function(tf, searchKeywords) {

        if (searchKeywords.building !== null) {
            tf.setFilterValue(1, [searchKeywords.building]);
            tf.activateFilter(1);
        }
        if (searchKeywords.type !== null) {
            tf.setFilterValue(4, [searchKeywords.type]);
            tf.activateFilter(4);
        }
        if (searchKeywords.bedroomMinimum !== null) {
            tf.setFilterValue(5, ['>='+searchKeywords.bedroomMinimum]);
            tf.activateFilter(5);
        }
        if (searchKeywords.priceSaleStart !== null && searchKeywords.priceSaleEnd === null) {
            tf.setFilterValue(14, ['>='+priceSaleStartLess10Pourcent]);
            tf.activateFilter(14);
        }
        if (searchKeywords.priceSaleEnd !== null && searchKeywords.priceSaleStart === null) {
            tf.setFilterValue(14, ['<='+searchKeywords.priceSaleEnd]);
            tf.activateFilter(14);
        }
        if (searchKeywords.priceSaleEnd !== null && searchKeywords.priceSaleStart !== null) {
            tf.setFilterValue(14, ['>='+searchKeywords.priceSaleStart+' && <='+searchKeywords.priceSaleEnd]);
            tf.activateFilter(14);
        }
        if (searchKeywords.objective !== null) {
            tf.setFilterValue(19, [searchKeywords.objective]);
            tf.activateFilter(19);
        }
    },
    add: {
        tableFilter: function() {

            let tableClassSelector = '.tablefilterPropertyAdd';

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'checklist',
                config.col_4 = 'input',
                config.col_5 = 'input',
                config.col_6 = 'input',
                config.col_7 = 'input',
                config.col_8 = 'input',
                config.col_9 = 'input',
                config.col_10 = 'checklist',
                config.col_11 = 'input',
                config.col_12 = 'input',
                config.col_13 = 'input',
                config.col_14 = 'input',
                config.col_15 = 'input',
                config.col_16 = 'input',
                config.col_17 = 'input',
                config.col_18 = 'input',
                config.col_19 = 'input',
                config.col_20 = 'input',
                config.col_21 = 'input',
                config.col_22 = 'input',
                config.col_23 = 'input',
                config.col_24 = 'input',
                config.col_25 = 'input',
                config.col_26 = 'input',
                config.col_27 = 'input',
                config.col_28 = 'input',
                config.col_29 = 'input',
                config.col_29 = 'input',
                config.col_30 = 'none',
                config.col_types = [
                    'number',
                    'string',
                    'string',
                    'string',
                    'number',
                    'number',
                    'number',
                    'number',
                    'number',
                    'number',
                    'string',
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    'none',
                ],
                config.extensions = [
                    {
                        name: 'sort',
                        sort_col_at_start: [0, false],
                        on_after_sort:function(o, colIndex){

                            tableFilterFunctions.setTotalRowFromTableAlwaysBottom(tableClassSelector);
                        },
                    }, {
                        name: 'colOps',
                        id: [
                            'sumBedroom',
                            'sumBathroom',
                            'sumSeparateWc',
                            'sumStoreroom',
                            'sumBuanderie',
                            'sumQuotShare',
                            'sumSurfacePlan',
                            'sumSurfaceAnnexe',
                            'sumSurfaceCalculated',
                            'sumUnitPriceTVAC3',
                            'sumForfaitTVAC3',
                            'sumUnitPriceTVAC17',
                            'sumForfaitTVAC17',
                            'sumSellingPriceTVAC3HPT',
                            'sumSellingPriceTVAC17HPT',
                            'sumSellingPriceHTVAHPT',
                            'sumPriceLand',
                            'sumTVA3',
                            'sumTVA17',
                            'sumSellingPriceTVAC3PlusPT',
                            'sumSellingPriceTVAC17PlusPT',
                            'sumDiscountHTVA',
                            'sumSellingPriceGlobalTVAC3',
                            'sumSellingPriceGlobalTVAC17',
                            'sellingPriceGlobalHTVAPlusPT'
                        ],
                        col: [
                            5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        ],
                        operation: [
                            'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum', 'sum',
                        ],
                        decimal_precision: [
                            0, 0, 0, 0, 0, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
                        ],
                    }
                ];

            let tf = null;
            document.querySelectorAll(tableClassSelector).forEach(function(table) {
                tf = new TableFilter(table, config);
                tf.init();
            });

            tableFilterFunctions.setTotalRowInTable(tf, tableClassSelector);

        },

        checkIfPropertiesAreCorrectlyEncodedBeforeUpdate: function() {

            let arePropertiesCorrectlyEncoded = true;

            let $elementsWithErrors = $('.tablefilterPropertyAdd tbody tr:not(.totalColumns)').find('.textDanger');
            if ($elementsWithErrors.length > 0) {

                $('html, body').animate({
                    scrollTop: $('.textDanger:first').offset().top - 70
                }, 0);

                arePropertiesCorrectlyEncoded = false;
            }

            let showAllPropertyEditRowBtn = $('.propertyEditRowBtn').show();

            return arePropertiesCorrectlyEncoded;
        },

        getDatasRowForChecking: function(propertyRow) {

            let datasRow = {
                'reference': {
                    'element': propertyRow.find('.reference'),
                    'value': propertyRow.find('.reference').text(),
                },
                'cadastralNumber': {
                    'element': propertyRow.find('.cadastralNumber'),
                    'value': propertyRow.find('.cadastralNumber').text(),
                },
                'batchNumber': {
                    'element': propertyRow.find('.batchNumber'),
                    'value': propertyRow.find('.batchNumber').text(),
                },
                'type': {
                    'element': propertyRow.find('.type'),
                    'value': propertyRow.find('.type').text(),
                },
                'floor': {
                    'element': propertyRow.find('.floor'),
                    'value': propertyRow.find('.floor').text(),
                },
                'bedroom': {
                    'element': propertyRow.find('.bedroom'),
                    'value': propertyRow.find('.bedroom').text(),
                },
                'bathroom': {
                    'element': propertyRow.find('.bathroom'),
                    'value': propertyRow.find('.bathroom').text(),
                },
                'separateWc': {
                    'element': propertyRow.find('.separateWc'),
                    'value': propertyRow.find('.separateWc').text(),
                },
                'storeroom': {
                    'element': propertyRow.find('.storeroom'),
                    'value': propertyRow.find('.storeroom').text(),
                },
                'buanderie': {
                    'element': propertyRow.find('.buanderie'),
                    'value': propertyRow.find('.buanderie').text(),
                },
                'isBuanderieCommune': {
                    'element': propertyRow.find('.isBuanderieCommune'),
                    'value': propertyRow.find('.isBuanderieCommune').text(),
                },
                'quotShare': {
                    'element': propertyRow.find('.quotShare'),
                    'value': propertyRow.find('.quotShare').text(),
                },
                'surfacePlan': {
                    'element': propertyRow.find('.surfacePlan'),
                    'value': propertyRow.find('.surfacePlan').text(),
                },
                'surfaceAnnexe': {
                    'element': propertyRow.find('.surfaceAnnexe'),
                    'value': propertyRow.find('.surfaceAnnexe').text(),
                },
                'surfaceCalculated': {
                    'element': propertyRow.find('.surfaceCalculated'),
                    'value': propertyRow.find('.surfaceCalculated').text(),
                },
                'unitPriceTVAC3': {
                    'element': propertyRow.find('.unitPriceTVAC3'),
                    'value': propertyRow.find('.unitPriceTVAC3').text(),
                },
                'forfaitTVAC3': {
                    'element': propertyRow.find('.forfaitTVAC3'),
                    'value': propertyRow.find('.forfaitTVAC3').text(),
                },
                'discountHTVA': {
                    'element': propertyRow.find('.discountHTVA'),
                    'value': propertyRow.find('.discountHTVA').text(),
                },
            };

            return datasRow;
        },
        putBackPropertyEditRowBtnInsteadOfDeleteForNewsRows: function() {

            $('.propertyRemoveRowBtn').parent().html('<button type="button" class="propertyEditRowBtn btn btn-default"><i class="fa fa-fw fa-pencil" aria-hidden="true"></i></button>');
        },
        checkDifferenceWithBuildingPrices: function(tf) {

            let checkTotalValuesFromBuilding = {

                'numberProperties': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[0]),
                'quotShare': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[1]),
                'surfacePlan': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[2]),
                'surfaceCalculated': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[3]),
                'priceLand': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[4]),
                'sellingPriceHTVAHPT': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[5]),
                'TVA3': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[6]),
                'TVA17': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[7]),
                'sellingPriceTVAC3HPT': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[8]),
                'sellingPriceTVAC17HPT': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[9]),
                'sellingPriceTVAC3PlusPT': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[10]),
                'sellingPriceTVAC17PlusPT': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[11]),
                'discountHTVA': numeral($('#checkTotalValuesFromBuilding').attr('class').split(' ')[12]),
            }

            setTimeout(function(){

                propertyFunctions.add.setDifferenceWithBuildingPrices(checkTotalValuesFromBuilding);

            }, 500);

        },
        setDifferenceWithBuildingPrices: function(checkTotalValuesFromBuilding) {

            let sum = {
                /*'numberProperties': {
                    'element': $('#numberProperties'),
                    'value': numeral($('#numberProperties')[0].innerHTML)._value,
                },*/
                'quotShare': {
                    'element': $('#sumQuotShare'),
                    'value': numeral($('#sumQuotShare')[0].innerHTML)._value,
                },
                'surfacePlan': {
                    'element': $('#sumSurfacePlan'),
                    'value': numeral($('#sumSurfacePlan')[0].innerHTML)._value,
                },
                'surfaceCalculated': {
                    'element': $('#sumSurfaceCalculated'),
                    'value': numeral($('#sumSurfaceCalculated')[0].innerHTML)._value,
                },
                'sellingPriceTVAC3HPT': {
                    'element': $('#sumSellingPriceTVAC3HPT'),
                    'value': numeral($('#sumSellingPriceTVAC3HPT')[0].innerHTML)._value,
                },
                'sellingPriceTVAC17HPT': {
                    'element': $('#sumSellingPriceTVAC17HPT'),
                    'value': numeral($('#sumSellingPriceTVAC17HPT')[0].innerHTML)._value,
                },
                'sellingPriceHTVAHPT': {
                    'element': $('#sumSellingPriceHTVAHPT'),
                    'value': numeral($('#sumSellingPriceHTVAHPT')[0].innerHTML)._value,
                },
                'priceLand': {
                    'element': $('#sumPriceLand'),
                    'value': numeral($('#sumPriceLand')[0].innerHTML)._value,
                },
                'TVA3': {
                    'element': $('#sumTVA3'),
                    'value': numeral($('#sumTVA3')[0].innerHTML)._value,
                },
                'TVA17': {
                    'element': $('#sumTVA17'),
                    'value': numeral($('#sumTVA17')[0].innerHTML)._value,
                },
                'sellingPriceTVAC3PlusPT': {
                    'element': $('#sumSellingPriceTVAC3PlusPT'),
                    'value': numeral($('#sumSellingPriceTVAC3PlusPT')[0].innerHTML)._value,
                },
                'sellingPriceTVAC17PlusPT': {
                    'element': $('#sumSellingPriceTVAC17PlusPT'),
                    'value': numeral($('#sumSellingPriceTVAC17PlusPT')[0].innerHTML)._value,
                },
                'discountHTVA': {
                    'element': $('#sumDiscountHTVA'),
                    'value': numeral($('#sumDiscountHTVA')[0].innerHTML)._value,
                },
            };

            $.each(sum, function(key, column) {

                let setDefaultBackgroundColor = column.element.removeClass(['textSuccess', 'textDanger']);

                if (column.value != checkTotalValuesFromBuilding[key]._input) {

                    column.element.addClass('textDanger');
                    console.log(checkTotalValuesFromBuilding[key]._input)

                } else {

                    column.element.addClass('textSuccess');
                }
            });
        },
        /*validation: function() {

            $('body').on('click', '.validationBtn', function() {

                let isValidation2 = ($(this)[0].id == 'validation2' ? true : false);

                let datas = {
                    'buildingId': $('#validationParams').attr('class').split(' ')[0],
                    'userId': $('#validationParams').attr('class').split(' ')[1],
                };

                let isDifferentUser = appFunctions.isDifferentUser(isValidation2, datas);

                let arePropertiesCorrectlyEncodedForValidation = propertyFunctions.add.checkIfPropertiesAreCorrectlyEncodedForValidation();

                let areValidationsGreen = propertyFunctions.add.areValidationsInPropertyAddGreen();

                if (isDifferentUser && arePropertiesCorrectlyEncodedForValidation && areValidationsGreen) {

                    $.ajax({
                        type: "POST",
                        url: Routing.generate('property_add_validation'),
                        data: {dataJson: JSON.stringify(datas)},
                        cache: false,
                        dataType: "json",
                    }).done(function(data) {

                        location.reload();
                    });
                }
            });
        },*/
        areValidationsInPropertyAddGreen: function() {

            let areValidationsGreen = true;

            let cellsTotal = $('.totalColumns td');

            let columnsThatDoesntMatchWithTotalValuesFromBuilding = '';

            cellsTotal.each(function() {

                if ($(this).hasClass('textDanger')) {


                    let columnNameFrench = propertyFunctions.add.getColumnNameInFrench($(this));

                    columnsThatDoesntMatchWithTotalValuesFromBuilding += columnNameFrench + ' , ';

                    areValidationsGreen = false;
                }
            });

            if (columnsThatDoesntMatchWithTotalValuesFromBuilding.length > 0) {

                $.alert({
                    title: 'Attention',
                    content: "Les totaux des colonnes suivantes : <b>"+ columnsThatDoesntMatchWithTotalValuesFromBuilding +"</b>ne correspondent pas avec les montants encodés dans la fiche de l\'immeuble",
                    icon: 'fa fa-warning',
                    type: 'red',
                    typeAnimated: true,
                    draggable: true,
                    dragWindowGap: 0, // number of px of distance
                    theme: 'modern',
                    buttons: {
                        cancel: {
                            text: 'Ok',
                            btnClass: 'btn-default',
                        },
                    }
                });
            }

            return areValidationsGreen;
        },
        getColumnNameInFrench: function(element) {

            let columnNameEnglish = element.attr('id');
            let columnNameFrench = '';

            if (columnNameEnglish === 'sumQuotShare') {
                columnNameFrench = '"Quot-part (millièmes)"';
            } else if (columnNameEnglish === 'sumSurfacePlan') {
                columnNameFrench = '"Surface utile sur plan (m²)"';
            } else if (columnNameEnglish === 'sumSurfaceCalculated') {
                columnNameFrench = '"Surface calculée (m²)"';
            } else if (columnNameEnglish === 'sumSellingPriceTVAC3HPT') {
                columnNameFrench = '"Prix de vente TVAC 3% - HPT"';
            } else if (columnNameEnglish === 'sumSellingPriceTVAC17HPT') {
                columnNameFrench = '"Prix de vente TVAC 17% - HPT"';
            } else if (columnNameEnglish === 'sumSellingPriceHTVAHPT') {
                columnNameFrench = '"Prix de vente HTVA - HPT"';
            } else if (columnNameEnglish === 'sumPriceLand') {
                columnNameFrench = '"Prix PT"';
            } else if (columnNameEnglish === 'sumTVA3') {
                columnNameFrench = '"TVA 3%"';
            } else if (columnNameEnglish === 'sumTVA17') {
                columnNameFrench = '"TVA 17%"';
            } else if (columnNameEnglish === 'sumSellingPriceTVAC3PlusPT') {
                columnNameFrench = '"Prix de vente TVAC 3% + PT"';
            } else if (columnNameEnglish === 'sumSellingPriceTVAC17PlusPT') {
                columnNameFrench = '"Prix de vente TVAC 17% + PT"';
            } else if (columnNameEnglish === 'sumDiscountHTVA') {
                columnNameFrench = '"Remise HTVA"';
            }

            return columnNameFrench;
        },
        displayValidationPropertyAddInTableFilter: function() {

            $('.tot').append($('#validationPropertyAdd').html());
        },
    },
    folder: {
        tableFilterCardDocumentsUploaded: function() {

           let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'input',
                config.col_2 = 'input',
                config.col_3 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    { type: 'date', locale: 'fr', format: '{dd}/{MM}/{yyyy|yy}' },
                    'none',
                ],
                config.col_widths = [
                    '125px',
                    '325px',
                    '100px',
                    '75px',
                ];

            document.querySelectorAll('.tablefilterPropertyCardDocumentsUploaded').forEach(function(table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },
    },
    priceHistoryByProperty: {
        tableFilter: function() {

            let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'none',
                config.col_2 = 'none',
                config.col_3 = 'none',
                config.col_4 = 'none',
                config.col_5 = 'none',
                config.col_6 = 'none',
                config.col_7 = 'none',
                config.col_8 = 'none',
                config.col_9 = 'none',
                config.col_10 = 'none',
                config.col_11 = 'none',
                config.col_12 = 'none',
                config.col_13 = 'none',
                config.col_14 = 'none',
                config.col_15 = 'none',
                config.col_16 = 'none',
                config.col_17 = 'none',
                config.col_18 = 'none',
                config.col_19 = 'none',
                config.col_20 = 'none',
                config.col_types = [
                    'string',
                    'string',
                    'string',
                    'string',
                    'string',
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    { type: 'formatted-number', decimal: ',', thousands: ' ' },
                    'none',
                ]

            console.log('yo')

            document.querySelectorAll('.tablefilterPriceHistoryByProperty').forEach(function(table) {
                let tf = new TableFilter(table, config);
                tf.init();
            });
        },
    }
}

export default propertyFunctions
