import './bootstrap';

import $ from "jquery";

import './functions/bootstrap'

global.$ = global.jQuery = $

import tableFilterFunctions from "./functions/tableFilter"

global.tableFilterFunctions = tableFilterFunctions


import TableFilter from 'tablefilter'

global.TableFilter = TableFilter

import appFunctions from "./functions/app"

global.appFunctions = appFunctions

import numeral from 'numeral'

global.numeral = numeral

import dropzone from "dropzone/dist/dropzone"

global.dropzone = dropzone

import routes from "../public/js/fos_js_routes.json"
import Routing from "../public/bundles/fosjsrouting/js/router"

Routing.setRoutingData(routes);
global.Routing = Routing

import tinymce from "tinymce";
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default/icons.min'

import accountFunctions from "./functions/account";

import buildingFunctions from "./functions/building";
import clientFunctions from "./functions/client";
import companyFunctions from "./functions/company";
import mediaFunctions from "./functions/media";
import numeralFunctions from "./functions/numeral";
import projectFunctions from "./functions/project";
import propertyFunctions from "./functions/property";
import orderSupplierFunctions from "./functions/oderSupplier";
import activityField from "./functions/activityField";

import TomSelect from "tom-select"
import tinyDatePicker from "tiny-date-picker"

import supplierFunctions from "./functions/supplier";

import userFunction from "./functions/user";
import historyFunctions from "./functions/history";

import "datatables.net"
import "datatables.net-bs4"

import Swal from 'sweetalert2'
import deadlineFunctions from "./functions/deadline";
import budgetSupplier from "./functions/budgetSupplier";
import costDashboardFunctions from "./functions/costDashboard";
import headerControl from "./functions/headerControl";
import invoiceFunctions from "./functions/invoice";

const asideTopBurger = document.querySelector('.top_bar--burger')
const asideContainer = document.querySelector('.aside__container')
const topBarBurger = document.querySelector('.top_bar--burger')
const contentContainer = document.querySelector('.content__container')
const stateInformationContainer = document.querySelector('#stateInformationContainer')

const widthDocument = window.innerWidth
const breakPointWidth = 1024
if (widthDocument < breakPointWidth) {
    asideTopBurger.addEventListener('click', () => {
        asideContainer.classList.toggle('left__0')
        stateInformationContainer.style.left = '50px'
    })
} else {
    asideTopBurger.addEventListener('click', () => {
        asideContainer.classList.toggle('left__minus250')
        topBarBurger.classList.toggle('margin__left0')
        contentContainer.classList.toggle('margin__left0')
        //TODO toggle and class
        stateInformationContainer.style.left = '50px'
    })
}

const toggleNavButtons = document.querySelectorAll('.toggle__nav')
toggleNavButtons.forEach((toggleNavButton) => {
    toggleNavButton.addEventListener('click', () => {
        const minusIcon = toggleNavButton.querySelector('.minus__absolute')
        const plusIcon = toggleNavButton.querySelector('.cross__absolute')
        minusIcon.classList.toggle('d-none')
        plusIcon.classList.toggle('d-none')
    })
})

const defaultLanguagesTinyDatePicker = {
    days: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Decembre',
    ]
}


$(document).ready(function () {

        /*********************************************************************************
         --- ALL ---
         **********************************************************************************/

        tableFilterFunctions.setBadgeToNumberOfRows();

        tableFilterFunctions.setFullWidthOnSelectFilter();

        appFunctions.redirectToPathLinkOnClickRow();

        numeralFunctions.defaultConfig();

        appFunctions.transformDotFromPavNumIntoComma();

        if ($('#home_default').length > 0) {

            if ($('#deadline_modal').length > 0) {
                Swal.fire({
                    template: '#deadline_modal'
                }).then((e) => {
                    if (e.isConfirmed) {
                        window.location = '/my-deadlines'
                    }
                })
            }
        }

        if ($('.closeButtonAlert').length > 0) {

            const closeButtons = document.querySelectorAll('.closeButtonAlert')

            closeButtons.forEach((closeButton) => {
                closeButton.addEventListener('click', () => {
                    closeButton.parentElement.classList.add('d-none')
                })
            })
        }

        /*

        /*********************************************************************************
         --- ACCOUNT ---
         **********************************************************************************/

        if ($('#account_default').length > 0) {

            const isCompanyDisplayed = $('#is_company_displayed').length;

            accountFunctions.tableFilter(isCompanyDisplayed);
        }

        /*********************************************************************************
         --- CLIENT ---
         **********************************************************************************/

        if ($('#client_default').length > 0) {

            const isCompanyDisplayed = $('#is_company_displayed').length;

            clientFunctions.tableFilter(isCompanyDisplayed);
        }

        if ($('#client_picture').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucune photo disponible');

            mediaFunctions.selectMedia('client_media_select');

            mediaFunctions.deleteMedia('client_media_delete', 'cette photo');

            const config = {
                'message': '',
                'minImageWidth': 150,
                'maxImageWidth': 9999,
                'minImageHeight': 150,
                'maxImageHeight': 9999
            };

            mediaFunctions.dropZonePicture(config);
        }

        if ($('#client_document').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucun document disponible');

            mediaFunctions.deleteMedia('client_media_delete', 'ce document');

            mediaFunctions.dropZoneDocument();

            mediaFunctions.eventChangeOriginalNameMedia();
        }

        if ($('#client_folder').length > 0) {

            appFunctions.setLinkInTabOnReload();

            tableFilterFunctions.setDefaultWidthColumnsIfTableEmpty('tablefilterFutureAcquisition');
        }

        if ($('#client_edit').length > 0) {

            tinymce.init({
                selector: '#client_coordinate_comment',
                menubar: false,
                branding: false
            });

        }

        if ($('#client_folder_future_acquisition_add').length > 0 || $('#client_folder_future_acquisition_edit').length > 0) {

            tinyDatePicker({
                input: document.querySelector('#property_future_dateOfAvailability'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            new TomSelect('#property_future_type', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })
        }

        if ($('#client_data').length > 0) {

            const isCompanyDisplayed = $('#is_company_displayed').length;

            clientFunctions.tableDataFilter(isCompanyDisplayed);

        }

        /*********************************************************************************
         --- SUPPLIER ---
         **********************************************************************************/

        if ($('#supplier_default').length > 0) {

            const isCompanyDisplayed = $('#is_company_displayed').length;

            supplierFunctions.tableFilter(isCompanyDisplayed);
        }

        if ($('#supplier_picture').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucune photo disponible');

            mediaFunctions.selectMedia('supplier_media_select');

            mediaFunctions.deleteMedia('supplier_media_delete', 'cette photo');

            const config = {
                'message': '',
                'minImageWidth': 150,
                'maxImageWidth': 9999,
                'minImageHeight': 150,
                'maxImageHeight': 9999
            };

            mediaFunctions.dropZonePicture(config);
        }

        if ($('#supplier_document').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucun document disponible');

            mediaFunctions.deleteMedia('supplier_media_delete', 'ce document');

            mediaFunctions.dropZoneDocument();

            mediaFunctions.eventChangeOriginalNameMedia();
        }

        if ($('#supplier_folder').length > 0) {

            appFunctions.setLinkInTabOnReload();

            supplierFunctions.folder.tableFilterCardDocumentsUploaded();

            supplierFunctions.folder.costItem.displayDifferentTablesOnModal();

            supplierFunctions.folder.costItem.invoice.onAddInvoice();
        }

        if ($('#supplier_add').length > 0) {

            new TomSelect('#supplier_activityFieldSuppliers', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })
        }

        if ($('#supplier_edit').length > 0) {

            const currentActivityFields = document.querySelectorAll('.currentActivityField')
            const currentActivityFieldsValues = []

            currentActivityFields.forEach((e) => {
                currentActivityFieldsValues.push(e.textContent)
            })

            new TomSelect('#supplier_activityFieldSuppliers', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                items: currentActivityFieldsValues
            })
        }

        /*********************************************************************************
         --- BUILDING ---
         **********************************************************************************/

        if ($('#building_default').length > 0) {

            const isCompanyDisplayed = $('#is_company_displayed').length;

            buildingFunctions.tableFilter(isCompanyDisplayed);
        }

        if ($('#building_add').length > 0) {

            const totalFieldLand = document.querySelector('#building_totalPriceLandFieldMarginArchitectEngineer')
            const fieldLand = document.querySelector('#building_totalPriceLand')
            const marginField = document.querySelector('#building_fieldMargin')
            const architectEngineerPrice = document.querySelector('#building_priceArchitectEngineer')

            const setTotalFieldLand = (fieldLand, marginField, architectEngineerPrice) => {
                let currentFieldLand = parseFloat(fieldLand)
                let currentMarginField = parseFloat(marginField)
                let currentArchitectEngineerPrice = parseFloat(architectEngineerPrice)
                totalFieldLand.value = 0

                if (typeof currentFieldLand === 'number' && !isNaN(currentFieldLand)) {
                    totalFieldLand.value = parseFloat(totalFieldLand.value) + currentFieldLand
                }
                if (typeof currentMarginField === 'number' && !isNaN(currentMarginField)) {
                    totalFieldLand.value = parseFloat(totalFieldLand.value) + currentMarginField
                }
                if (typeof currentArchitectEngineerPrice === 'number' && !isNaN(currentArchitectEngineerPrice)) {
                    totalFieldLand.value = parseFloat(totalFieldLand.value) + currentArchitectEngineerPrice
                }

            }

            fieldLand.addEventListener('focusout', (e) => {
                let currentValue = parseFloat(e.target.value)
                if (typeof currentValue === 'number' && !isNaN(currentValue)) {
                    setTotalFieldLand(fieldLand.value, marginField.value, architectEngineerPrice.value)
                }
            })

            marginField.addEventListener('focusout', (e) => {
                let currentValue = parseFloat(e.target.value)
                if (typeof currentValue === 'number' && !isNaN(currentValue)) {
                    setTotalFieldLand(fieldLand.value, marginField.value, architectEngineerPrice.value)
                }
            })

            architectEngineerPrice.addEventListener('focusout', (e) => {
                let currentValue = parseFloat(e.target.value)
                if (typeof currentValue === 'number' && !isNaN(currentValue)) {
                    setTotalFieldLand(fieldLand.value, marginField.value, architectEngineerPrice.value)
                }
            })


            tinyDatePicker({
                input: document.querySelector('#building_dateStartBudget'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#building_dateEndBudget'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#building_cdrForecast100Percent'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinymce.init({
                selector: '#building_description',
                menubar: false,
                branding: false
            });

            tinymce.init({
                selector: '#building_strongPoints',
                menubar: false,
                branding: false
            });

            tinymce.init({
                selector: '#building_isAdaptedToPMRComment',
                menubar: false,
                branding: false
            });

        }

        if ($('#building_edit').length > 0) {

            const totalFieldLand = document.querySelector('#building_totalPriceLandFieldMarginArchitectEngineer')
            const fieldLand = document.querySelector('#building_totalPriceLand')
            const marginField = document.querySelector('#building_fieldMargin')
            const architectEngineerPrice = document.querySelector('#building_priceArchitectEngineer')

            const setTotalFieldLand = (fieldLand, marginField, architectEngineerPrice) => {

                let currentFieldLand = parseFloat(fieldLand.replace(/,/, '.'));

                let currentMarginField = parseFloat(marginField.replace(/,/, '.'))
                let currentArchitectEngineerPrice = parseFloat(architectEngineerPrice.replace(/,/, '.'))
                totalFieldLand.value = 0

                if (typeof currentFieldLand === 'number' && !isNaN(currentFieldLand)) {
                    totalFieldLand.value = Math.round((parseFloat(totalFieldLand.value) + currentFieldLand) * 100) / 100
                }
                if (typeof currentMarginField === 'number' && !isNaN(currentMarginField)) {
                    totalFieldLand.value = Math.round((parseFloat(totalFieldLand.value) + currentMarginField) * 100) / 100
                }
                if (typeof currentArchitectEngineerPrice === 'number' && !isNaN(currentArchitectEngineerPrice)) {
                    totalFieldLand.value = Math.round((parseFloat(totalFieldLand.value) + currentArchitectEngineerPrice) * 100) / 100
                }

            }

            setTotalFieldLand(fieldLand.value, marginField.value, architectEngineerPrice.value)

            fieldLand.addEventListener('focusout', (e) => {
                let currentValue = parseFloat(e.target.value)
                if (typeof currentValue === 'number' && !isNaN(currentValue)) {
                    setTotalFieldLand(fieldLand.value, marginField.value, architectEngineerPrice.value)
                }
            })

            marginField.addEventListener('focusout', (e) => {
                let currentValue = parseFloat(e.target.value)
                if (typeof currentValue === 'number' && !isNaN(currentValue)) {
                    setTotalFieldLand(fieldLand.value, marginField.value, architectEngineerPrice.value)
                }
            })

            architectEngineerPrice.addEventListener('focusout', (e) => {
                let currentValue = parseFloat(e.target.value)
                if (typeof currentValue === 'number' && !isNaN(currentValue)) {
                    setTotalFieldLand(fieldLand.value, marginField.value, architectEngineerPrice.value)
                }
            })

            tinymce.init({
                selector: '#building_description',
                menubar: false,
                branding: false
            });

            tinymce.init({
                selector: '#building_strongPoints',
                menubar: false,
                branding: false
            });

            tinymce.init({
                selector: '#building_isAdaptedToPMRComment',
                menubar: false,
                branding: false
            });

            if ($('#building_userProjectManager').length > 0) {

                tinyDatePicker({
                    input: document.querySelector('#building_dateStartBudget'),
                    format(date) {
                        return date.toLocaleDateString('fr-FR');
                    },
                    defaultLanguagesTinyDatePicker
                })

                tinyDatePicker({
                    input: document.querySelector('#building_dateEndBudget'),
                    format(date) {
                        return date.toLocaleDateString('fr-FR');
                    },
                    defaultLanguagesTinyDatePicker
                })

                tinyDatePicker({
                    input: document.querySelector('#building_cdrForecast100Percent'),
                    format(date) {
                        return date.toLocaleDateString('fr-FR');
                    },
                    defaultLanguagesTinyDatePicker
                })
            }
        }

        if ($('#building_picture').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucune photo disponible');

            mediaFunctions.selectMedia('building_media_select');

            mediaFunctions.deleteMedia('building_media_delete', 'cette photo');

            const config = {
                'message': '',
                'minImageWidth': 150,
                'maxImageWidth': 9999,
                'minImageHeight': 150,
                'maxImageHeight': 9999
            };

            mediaFunctions.dropZonePicture(config);
        }

        if ($('#building_document').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucun document disponible');

            mediaFunctions.deleteMedia('building_media_delete', 'ce document');

            mediaFunctions.dropZoneDocument();

            mediaFunctions.eventChangeOriginalNameMedia();
        }

        if ($('#building_folder').length > 0) {

            appFunctions.setLinkInTabOnReload();
        }

        if ($('#building_folder_marketing_price_initial').length > 0) {

            buildingFunctions.folder.marketing.priceInitial.tableFilterPriceInitial();

            const tableCells = document.querySelectorAll('td')
            tableCells.forEach((cell) => {
                if (!cell.classList.contains('floor')) {
                    if (cell.textContent[0] === '-') {
                        cell.classList.add('colorDanger')
                    }
                }
            })

            const sumSellingPriceTVAC3HPT = document.querySelector('#sumSellingPriceTVAC3HPT')
            const controlSellingPriceTVAC3HPT = document.querySelector('#controlSellingPriceTVAC3HPT')

            const sumSellingPriceTVAC17HPT = document.querySelector('#sumSellingPriceTVAC17HPT')
            const controlSellingPriceTVAC17HPT = document.querySelector('#controlSellingPriceTVAC17HPT')

            const sumSellingPriceHTVAHPT = document.querySelector('#sumSellingPriceHTVAHPT')
            const controlSellingPriceHTVAHPT = document.querySelector('#controlSellingPriceHTVAHPT')

            const sumSellingPriceTVAC3PlusPT = document.querySelector('#sumSellingPriceTVAC3PlusPT')
            const controlSellingPriceTVAC3PlusPT = document.querySelector('#controlSellingPriceTVAC3PlusPT')

            const sumSellingPriceTVAC17PlusPT = document.querySelector('#sumSellingPriceTVAC17PlusPT')
            const controlSellingPriceTVAC17PlusPT = document.querySelector('#controlSellingPriceTVAC17PlusPT')

            /* const sumPriceLand = document.querySelector('#sumPriceLand')
             const controlPriceLand = document.querySelector('#controlPriceLand')*/

            const sumTVA3 = document.querySelector('#sumTVA3')
            const controlTVA3 = document.querySelector('#controlTVA3')

            const sumTVA17 = document.querySelector('#sumTVA17')
            const controlTVA17 = document.querySelector('#controlTVA17')

            const sumDiscountHTVA = document.querySelector('#sumDiscountHTVA')
            const controlDiscountHTVA = document.querySelector('#controlDiscountHTVA')

            const sumQuotShare = document.querySelector('#sumQuotShare')
            const controlQuotShare = document.querySelector('#controlQuotShare')

            const sumSellingPriceGlobalTVAC3 = document.querySelector('#sumSellingPriceGlobalTVAC3')
            const controlSellingPriceGlobalTVAC3 = document.querySelector('#controlSellingPriceGlobalTVAC3')

            const sumSellingPriceGlobalTVAC17 = document.querySelector('#sumSellingPriceGlobalTVAC17')
            const controlSellingPriceGlobalTVAC17 = document.querySelector('#controlSellingPriceGlobalTVAC17')

            const sellingPriceGlobalHTVAPlusPT = document.querySelector('#sellingPriceGlobalHTVAPlusPT')
            const controlSellingPriceGlobalHTVAPlusPT = document.querySelector('#controlSellingPriceGlobalHTVAPlusPT')

            setTimeout(() => {

                /*  calculateDifferenceBetweenValues(sumSellingPriceTVAC3HPT, controlSellingPriceTVAC3HPT)
                  calculateDifferenceBetweenValues(sumSellingPriceTVAC17HPT, controlSellingPriceTVAC17HPT)
                  calculateDifferenceBetweenValues(sumSellingPriceHTVAHPT, controlSellingPriceHTVAHPT)
                  calculateDifferenceBetweenValues(sumSellingPriceTVAC3PlusPT, controlSellingPriceTVAC3PlusPT)
                  calculateDifferenceBetweenValues(sumSellingPriceTVAC17PlusPT, controlSellingPriceTVAC17PlusPT)
                  calculateDifferenceBetweenValues(sumPriceLand, controlPriceLand)
                  calculateDifferenceBetweenValues(sumTVA3, controlTVA3)
                  calculateDifferenceBetweenValues(sumTVA17, controlTVA17)
                  calculateDifferenceBetweenValues(sumDiscountHTVA, controlDiscountHTVA)
                  calculateDifferenceBetweenValues(sumQuotShare, controlQuotShare)
                  calculateDifferenceBetweenValues(sumSellingPriceGlobalTVAC3, controlSellingPriceGlobalTVAC3)
                  calculateDifferenceBetweenValues(sumSellingPriceGlobalTVAC17, controlSellingPriceGlobalTVAC17)
                  calculateDifferenceBetweenValues(sellingPriceGlobalHTVAPlusPT, controlSellingPriceGlobalHTVAPlusPT)
      */
            }, 1000)

            /*const calculateDifferenceBetweenValues = (element, control) => {
                const elementValue = parseFloat(element.textContent.replaceAll(' ', '').replace(/,/, '.'))
                const controlValue = parseFloat(control.textContent)
                const differenceValue = Math.round((elementValue - controlValue) * 100) / 100
                if (differenceValue === 0) {
                    element.classList.add('textSuccess')
                } else if ((differenceValue < 1 && differenceValue > 0) || (differenceValue > -1 && differenceValue < 0)) {
                    element.classList.add('textWarning')
                } else {
                    element.classList.add('textDanger')
                }
            }*/

        }

        if ($('#building_folder_marketing_price_evolving').length > 0) {

            const tableCells = document.querySelectorAll('td')
            tableCells.forEach((cell) => {
                if (!cell.classList.contains('floor')) {
                    if (cell.textContent[0] === '-') {
                        cell.classList.add('colorDanger')
                    }
                }
            })

            buildingFunctions.folder.marketing.priceEvolving.tableFilterPriceEvolving();

            //buildingFunctions.folder.marketing.priceEvolving.displayTotalCDRPriceEvolvingInTableFilter();

            //buildingFunctions.folder.marketing.displayValidationPriceInitialInTableFilter();
        }

        if ($('#building_folder_marketing_price_history').length > 0) {

            buildingFunctions.folder.marketing.priceHistory.tableFilterPriceHistory();
        }

        //TODO : refactor function
        if ($('#building_folder_financial_step_construction_site_and_CDR_assignation_in_progress').length > 0) {

            buildingFunctions.folder.financial.stepConstructionSite.tablefilterStepConstructionSiteAndCDR();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationStepConstructionSiteAndCDRInTableFilter();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationPriceEvolvingInTableFilter();
            const editCdrButton = document.querySelector('#edit_cdr')
            const saveCdrButton = document.querySelector('#save_cdr')
            const cancelCdrButton = document.querySelector('#cancel_cdr')
            const cdrData = document.querySelectorAll('.CDR')
            let sumCdr = document.querySelector('#sumCDR')

            const toggleButtonsAndInputs = (cdrData) => {
                editCdrButton.classList.toggle('d-none')
                saveCdrButton.classList.toggle('d-none')
                cancelCdrButton.classList.toggle('d-none')
                cdrData.forEach((e) => {
                    const cdrInput = e.children[0]
                    const cdrDefault = e.children[1]
                    cdrInput.classList.toggle('d-none')
                    cdrDefault.classList.toggle('d-none')
                })
            }

            editCdrButton.addEventListener('click', () => toggleButtonsAndInputs(cdrData))
            cancelCdrButton.addEventListener('click', () => toggleButtonsAndInputs(cdrData))
            let dataToSend = []

            const addCdrAmounts = () => {
                let amount = 0
                cdrData.forEach((containerCdr) => {
                    amount = amount + parseInt(containerCdr.children[0].value)
                })
                return amount
            }

            cdrData.forEach((containerCdr) => {
                const inputCdr = containerCdr.children[0]
                const inputValue = containerCdr.children[1].textContent
                inputCdr.addEventListener('change', (event) => {
                    sumCdr.textContent = addCdrAmounts()
                    const currentCdr = {
                        id: containerCdr.dataset.id,
                        cdr: inputCdr.value
                    }
                    if (dataToSend.length === 0) {
                        dataToSend.push(currentCdr)
                    } else {
                        let pushCdrData = true
                        dataToSend.forEach((elt) => {
                            if (elt.id === currentCdr.id) {
                                pushCdrData = false
                                elt.cdr = currentCdr.cdr
                            }
                        })
                        if (pushCdrData) {
                            dataToSend.push(currentCdr)
                        }
                    }
                })
            })

            saveCdrButton.addEventListener('click', () => {
                toggleButtonsAndInputs(cdrData)
                editCdrButton.textContent = 'Chargement'

                $.ajax({
                    type: "POST",
                    url: Routing.generate('building_edit_cdr'),
                    data: JSON.stringify(dataToSend),
                    cache: false,
                    dataType: "json",
                }).done(function () {
                    location.reload()
                })
            })

        }

        if ($('#cdr_assignation_consultation').length > 0) {

            buildingFunctions.folder.financial.stepConstructionSite.tablefilterStepConstructionSiteAndCDRConsultation();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationStepConstructionSiteAndCDRInTableFilter();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationPriceEvolvingInTableFilter();
            const editCdrButton = document.querySelector('#edit_cdr')
            const saveCdrButton = document.querySelector('#save_cdr')
            const cancelCdrButton = document.querySelector('#cancel_cdr')
            const cdrData = document.querySelectorAll('.CDR')
            let sumCdr = document.querySelector('#sumCDR')

            const toggleButtonsAndInputs = (cdrData) => {
                editCdrButton.classList.toggle('d-none')
                saveCdrButton.classList.toggle('d-none')
                cancelCdrButton.classList.toggle('d-none')
                cdrData.forEach((e) => {
                    const cdrInput = e.children[0]
                    const cdrDefault = e.children[1]
                    cdrInput.classList.toggle('d-none')
                    cdrDefault.classList.toggle('d-none')
                })
            }

            editCdrButton.addEventListener('click', () => toggleButtonsAndInputs(cdrData))
            cancelCdrButton.addEventListener('click', () => toggleButtonsAndInputs(cdrData))
            let dataToSend = []

            const addCdrAmounts = () => {
                let amount = 0
                cdrData.forEach((containerCdr) => {
                    amount = amount + parseInt(containerCdr.children[0].value)
                })
                return amount
            }

            cdrData.forEach((containerCdr) => {
                const inputCdr = containerCdr.children[0]
                const inputValue = containerCdr.children[1].textContent
                inputCdr.addEventListener('change', (event) => {
                    sumCdr.textContent = addCdrAmounts()
                    const currentCdr = {
                        id: containerCdr.dataset.id,
                        cdr: inputCdr.value
                    }
                    if (dataToSend.length === 0) {
                        dataToSend.push(currentCdr)
                    } else {
                        let pushCdrData = true
                        dataToSend.forEach((elt) => {
                            if (elt.id === currentCdr.id) {
                                pushCdrData = false
                                elt.cdr = currentCdr.cdr
                            }
                        })
                        if (pushCdrData) {
                            dataToSend.push(currentCdr)
                        }
                    }
                })
            })

            saveCdrButton.addEventListener('click', () => {
                toggleButtonsAndInputs(cdrData)
                editCdrButton.textContent = 'Chargement'

                $.ajax({
                    type: "POST",
                    url: Routing.generate('building_edit_cdr'),
                    data: JSON.stringify(dataToSend),
                    cache: false,
                    dataType: "json",
                }).done(function () {
                    location.reload()
                })
            })

        }

        if ($('#building_folder_financial_step_construction_site_and_CDR_waiting_for_building_validation').length > 0) {

            //delete one column here
            buildingFunctions.folder.financial.stepConstructionSite.tablefilterStepConstructionSiteAndCDR();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationStepConstructionSiteAndCDRInTableFilter();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationPriceEvolvingInTableFilter();

        }

        if ($('#waiting_for_building_validation_consultation').length > 0) {

            //delete one column here
            buildingFunctions.folder.financial.stepConstructionSite.tablefilterStepConstructionSiteAndCDRConsultation();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationStepConstructionSiteAndCDRInTableFilter();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationPriceEvolvingInTableFilter();

        }

        if ($('#building_folder_financial_step_construction_site_and_CDR_waiting_for_financial_construction_billing').length > 0) {

            //delete one column here
            buildingFunctions.folder.financial.stepConstructionSite.tablefilterStepConstructionSiteAndCDR();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationStepConstructionSiteAndCDRInTableFilter();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationPriceEvolvingInTableFilter();

            if ($('#construction_validation').length > 0) {
                const editConstructionButton = document.querySelector('#construction_validation')
                const saveConstructionButton = document.querySelector('#construction_save')
                const cancelConstructionButton = document.querySelector('#construction_cancel')
                const constructionValidationData = document.querySelectorAll('.construction_validation')
                let dataToSend = []

                const toggleButtonsAndInputs = (constructionValidationData) => {
                    editConstructionButton.classList.toggle('d-none')
                    saveConstructionButton.classList.toggle('d-none')
                    cancelConstructionButton.classList.toggle('d-none')
                    constructionValidationData.forEach((container) => {
                        const cdrInput = container.children[0]
                        const cdrDefault = container.children[1]
                        if (cdrInput.children[1].textContent === 'Non') {
                            cdrInput.classList.toggle('d-none')
                            cdrDefault.classList.toggle('d-none')
                        }
                    })
                }

                editConstructionButton.addEventListener('click', () => toggleButtonsAndInputs(constructionValidationData))
                cancelConstructionButton.addEventListener('click', () => toggleButtonsAndInputs(constructionValidationData))
                constructionValidationData.forEach((container) => {
                    const checkBox = container.children[1]

                    checkBox.addEventListener('change', (e) => {
                        const constructionValidation = {
                            id: container.dataset.id,
                            value: e.target.checked,
                            type: 'constructionSite'
                        }

                        if (dataToSend.length === 0) {
                            dataToSend.push(constructionValidation)
                        } else {
                            let pushCdrData = true
                            dataToSend.forEach((elt) => {
                                if (elt.id === constructionValidation.id) {
                                    pushCdrData = false
                                    elt.value = constructionValidation.value
                                }
                            })
                            if (pushCdrData) {
                                dataToSend.push(constructionValidation)
                            }
                        }
                    })
                })

                saveConstructionButton.addEventListener('click', () => {
                    toggleButtonsAndInputs(constructionValidationData)
                    editConstructionButton.textContent = 'Chargement'
                    console.log(dataToSend)
                    $.ajax({
                        type: "POST",
                        url: Routing.generate('step_construction_site_validation'),
                        data: JSON.stringify(dataToSend),
                        cache: false,
                        dataType: "json",
                    }).done(function (result) {
                        location.reload()
                        /*JSON.parse(result.data).forEach((element) => {
                            const currentContainer = document.querySelector(`.construction_validation[data-id="${element.id}"]`)
                        })*/
                    })
                })
            }

            if ($('#financial_validation').length > 0) {
                const editFinancialButton = document.querySelector('#financial_validation')
                const saveFinancialButton = document.querySelector('#financial_save')
                const cancelFinancialButton = document.querySelector('#financial_cancel')
                const financialValidationData = document.querySelectorAll('.financial_validation')
                let dataToSend = []

                const toggleButtonsAndInputs = (financialValidationData) => {
                    editFinancialButton.classList.toggle('d-none')
                    saveFinancialButton.classList.toggle('d-none')
                    cancelFinancialButton.classList.toggle('d-none')
                    financialValidationData.forEach((container) => {
                        const cdrInput = container.children[0]
                        //if there is no construction validation the checkbox doesn't appear
                        if (container.children[1]) {
                            const cdrDefault = container.children[1]
                            if (cdrInput.children[1].textContent === 'Non') {
                                cdrInput.classList.toggle('d-none')
                                cdrDefault.classList.toggle('d-none')
                            }
                        }
                    })
                }

                editFinancialButton.addEventListener('click', () => toggleButtonsAndInputs(financialValidationData))
                cancelFinancialButton.addEventListener('click', () => toggleButtonsAndInputs(financialValidationData))

                financialValidationData.forEach((container) => {
                    //if there is no construction validation the checkbox doesn't appear
                    if (container.children[1]) {
                        const checkBox = container.children[1]

                        checkBox.addEventListener('change', (e) => {
                            const constructionValidation = {
                                id: container.dataset.id,
                                value: e.target.checked,
                                type: 'financial'
                            }

                            if (dataToSend.length === 0) {
                                dataToSend.push(constructionValidation)
                            } else {
                                let pushCdrData = true
                                dataToSend.forEach((elt) => {
                                    if (elt.id === constructionValidation.id) {
                                        pushCdrData = false
                                        elt.value = constructionValidation.value
                                    }
                                })
                                if (pushCdrData) {
                                    dataToSend.push(constructionValidation)
                                }
                            }
                        })
                    }

                })

                saveFinancialButton.addEventListener('click', () => {
                    toggleButtonsAndInputs(financialValidationData)
                    editFinancialButton.textContent = 'Chargement'
                    $.ajax({
                        type: "POST",
                        url: Routing.generate('step_construction_site_validation'),
                        data: JSON.stringify(dataToSend),
                        cache: false,
                        dataType: "json",
                    }).done(function (result) {
                        location.reload()
                        /*JSON.parse(result.data).forEach((element) => {
                            const currentContainer = document.querySelector(`.construction_validation[data-id="${element.id}"]`)
                        })*/
                    })
                })
            }


            //If User can edit validations
            if ($('.editStepConstructionValidation').length > 0) {
                const editStepConstructionValidationButtons = document.querySelectorAll('.editStepConstructionValidation')
                editStepConstructionValidationButtons.forEach((button) => {
                    button.addEventListener('click', (e) => {
                        //dévérouiller le select
                        console.log(e.target)

                    })
                })
            }


        }

        if ($('#building_folder_financial_step_construction_site_and_CDR_waiting_for_financial_construction_billing_consultation').length > 0) {

            console.log('yo')

            buildingFunctions.folder.financial.stepConstructionSite.tablefilterStepConstructionSiteAndCDRConsultation();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationStepConstructionSiteAndCDRInTableFilter();
            buildingFunctions.folder.financial.stepConstructionSite.displayValidationPriceEvolvingInTableFilter();
        }

        //original
        if ($('#building_folder_financial_step_construction_site_and_CDR').length > 0) {

            buildingFunctions.folder.financial.stepConstructionSite.tablefilterStepConstructionSiteAndCDR();

            buildingFunctions.folder.financial.stepConstructionSite.displayValidationStepConstructionSiteAndCDRInTableFilter();

            buildingFunctions.folder.financial.stepConstructionSite.displayValidationPriceEvolvingInTableFilter();

        }

        if ($('#building_folder_financial_analytical_account').length > 0) {

            buildingFunctions.folder.financial.analyticalAccount.tablefilterAnalyticalAccount();

            if ($('#analytical_account_activation_permission').length > 0) {
                const editAnalyticalAccountButton = document.querySelector('#analytical_account_activation')
                const saveAnalyticalAccountButton = document.querySelector('#analytical_account_save')
                const cancelAnalyticalAccountButton = document.querySelector('#analytical_account_cancel')
                const addAnalyticalAccountButton = document.querySelector('#analytical_account_add')
                const analyticalAccountValidationData = document.querySelectorAll('.analytical_account_validation')
                const selectAnalyticalAccountButton = document.querySelector('#analytical_account_select')

                let dataToSend = []

                const toggleButtonsAndInputs = (analyticalAccountValidationData) => {
                    editAnalyticalAccountButton.classList.toggle('d-none')
                    saveAnalyticalAccountButton.classList.toggle('d-none')
                    cancelAnalyticalAccountButton.classList.toggle('d-none')
                    addAnalyticalAccountButton.classList.toggle('d-none')
                    selectAnalyticalAccountButton.classList.toggle('d-none')
                    analyticalAccountValidationData.forEach((container) => {
                        const cdrInput = container.children[0]
                        const cdrDefault = container.children[1]
                        //an analytical account cannot be deactivated if the budget is already defined
                        if (container.dataset.budget <= 0) {
                            cdrInput.classList.toggle('d-none')
                            cdrDefault.classList.toggle('d-none')
                        }
                    })
                }

                editAnalyticalAccountButton.addEventListener('click', () => toggleButtonsAndInputs(analyticalAccountValidationData))
                cancelAnalyticalAccountButton.addEventListener('click', () => toggleButtonsAndInputs(analyticalAccountValidationData))

                let checkFlag = true
                selectAnalyticalAccountButton.addEventListener('click', () => {
                    analyticalAccountValidationData.forEach((container) => {
                        const analyticalAccountValidation = {
                            id: container.dataset.id,
                            value: checkFlag,
                        }
                        let pushAnalyticalAccountData = true
                        dataToSend.forEach((elt) => {
                            if (elt.id === analyticalAccountValidation.id) {
                                pushAnalyticalAccountData = false
                                elt.value = analyticalAccountValidation.value
                            }
                        })
                        if (pushAnalyticalAccountData) {
                            dataToSend.push(analyticalAccountValidation)
                        }
                        const checkBox = container.children[1]
                        checkBox.checked = checkFlag
                    })
                    checkFlag = !checkFlag
                    if (checkFlag) {
                        selectAnalyticalAccountButton.textContent = 'Tout sélectionner'
                    } else {
                        selectAnalyticalAccountButton.textContent = 'Tout désélectionner'
                    }
                })

                analyticalAccountValidationData.forEach((container) => {
                    const checkBox = container.children[1]

                    checkBox.addEventListener('change', (e) => {
                        const analyticalAccountValidation = {
                            id: container.dataset.id,
                            value: e.target.checked,
                        }
                        if (dataToSend.length === 0) {
                            dataToSend.push(analyticalAccountValidation)
                        } else {
                            let pushAnalyticalAccountData = true
                            dataToSend.forEach((elt) => {
                                if (elt.id === analyticalAccountValidation.id) {
                                    pushAnalyticalAccountData = false
                                    elt.value = analyticalAccountValidation.value
                                }
                            })
                            if (pushAnalyticalAccountData) {
                                dataToSend.push(analyticalAccountValidation)
                            }
                        }
                    })
                })

                saveAnalyticalAccountButton.addEventListener('click', () => {
                    toggleButtonsAndInputs(analyticalAccountValidationData)
                    editAnalyticalAccountButton.textContent = 'Chargement'
                    console.log(dataToSend)
                    $.ajax({
                        type: "POST",
                        url: Routing.generate('analytical_account_activation'),
                        data: JSON.stringify(dataToSend),
                        cache: false,
                        dataType: "json",
                    }).done(function (result) {
                        location.reload()
                        /*JSON.parse(result.data).forEach((element) => {
                            const currentContainer = document.querySelector(`.construction_validation[data-id="${element.id}"]`)
                        })*/
                    })
                })
            }


        }

        if ($('#analytical_account_edit_general_info').length > 0) {

            tinyDatePicker({
                input: document.querySelector('#analytical_account_general_infos_dateStartDurationStudy'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#analytical_account_general_infos_dateEndDurationStudy'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#analytical_account_general_infos_dateStartDurationSubmission'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#analytical_account_general_infos_dateEndDurationSubmission'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#analytical_account_general_infos_dateStartDurationRealizationOnSite'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#analytical_account_general_infos_dateEndDurationRealizationOnSite'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

        }

        if ($('#building_folder_financial_cost_select_date').length > 0) {

            buildingFunctions.folder.financial.costSelectDate.tablefilterCostSelectDate();
        }

        if ($('#building_folder_financial_cost_dashboard_without_adjust').length > 0) {
            buildingFunctions.folder.financial.costDashboard.tablefilterCostDashboard();

            headerControl.costDashboard()

            $('.tot').append($('#validationCostDashboard').html());
        }


        if ($('#building_folder_financial_cost_dashboard').length > 0) {

            headerControl.costDashboard()

            const adjustButtons = document.querySelectorAll('.adjustButton')

            const adjustAnnualBudget = document.querySelectorAll('.adjustAnnualBudget')

            adjustButtons.forEach((button) => {

                const analyticalAccountId = button.dataset.analyticalaccount

                button.addEventListener('click', () => {

                    costDashboardFunctions.adjustGlobalBudget(analyticalAccountId)

                })
            })

            adjustAnnualBudget.forEach((button) => {
                const label = button.dataset.label
                const labelSplit = label.split(' ')

                if (labelSplit[0] === 'Budget') {

                    const analyticalAccountId = button.dataset.analyticalaccount
                    /*  const amount = button.dataset.amount*/

                    button.addEventListener('click', () => {

                        costDashboardFunctions.adjustAnnualBudget(analyticalAccountId, labelSplit)

                    })
                }

            })

            buildingFunctions.folder.financial.costDashboard.tablefilterCostDashboard();

            $('.tot').append($('#validationCostDashboard').html());

        }

        if ($('#building_folder_financial_cost_dashboard_history').length > 0) {

            buildingFunctions.folder.financial.costDashboard.tablefilterCostDashboard();


        }


        if ($('#building_folder_construction_cost_item_select').length > 0) {

            buildingFunctions.folder.construction.costItem.tablefilterCostItemSelect();
        }

        if ($('#building_folder_construction_cost_item_display').length > 0) {

            buildingFunctions.folder.construction.costItem.tablefilterCostItemDisplay();

            buildingFunctions.folder.construction.costItem.setTotals();

        }

        if ($('#analytical_account_index_budgets').length > 0) {

            const analyticalAccountId = document.querySelector('#analytical_account_index_budgets').dataset.id
            const budgetPerYearEdit = document.querySelectorAll('.budgetPerYearEdit')

           /* const pendingValidationCostDashboard = document.querySelector('#pendingValidationCostDashboard')
            const pendingValidationCostDashboardDirection = pendingValidationCostDashboard.dataset.direction
            const pendingValidationCostDashboardConstruction = pendingValidationCostDashboard.dataset.construction
            const pendingValidationCostDashboardAdminFinancial = pendingValidationCostDashboard.dataset.admin*/

            if ($('#noBudget').length > 0) {
                const edit = document.querySelector('#noBudgetEdit')
                edit.classList.toggle('d-none')

                edit.addEventListener('click', () => {

                    Swal.fire({
                        template: '#noBudgetPendingBudgetModal',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            $.ajax({
                                type: "POST",
                                url: Routing.generate('analytical_account_set_budget'),
                                data: JSON.stringify({
                                    id: analyticalAccountId,
                                    amount: result.value,
                                    method: 'create'
                                }),
                                cache: false,
                                dataType: "json",
                            }).done(function (result) {
                                const response = JSON.parse(result.data)
                                if (!response.success) {
                                    Swal.fire({
                                        title: response.reason,
                                        icon: 'error',
                                        confirmButtonText: 'Fermer',
                                        confirmButtonColor: '#dc3741',
                                    })
                                } else {
                                    Swal.fire({
                                        title: response.reason,
                                        icon: 'success',
                                        confirmButtonText: 'Fermer',
                                        confirmButtonColor: 'rgb(92, 184, 92)',
                                    }).then(() => {
                                            location.reload()
                                        }
                                    )
                                }
                            }).fail(() => {
                                console.log('error')
                            })
                        }
                    })
                })
            }

            if ($('#pendingBudget').length > 0) {

                const input = document.querySelector('#noBudgetPendingBudgetInput')
                const edit = document.querySelector('#pendingBudgetEdit')
                const cancelButton = document.querySelector('#noBudgetPendingBudgetCancel')
                const save = document.querySelector('#noBudgetPendingBudgetSave')
                const deleteButton = document.querySelector('#pendingBudgetDelete')
                const validateButton = document.querySelector('#pendingBudgetValidate')

                budgetPerYearEdit.forEach((btn) => {
                    btn.classList.remove('d-none')
                })

                edit.classList.toggle('d-none')
                deleteButton.classList.toggle('d-none')
                validateButton.classList.toggle('d-none')

                edit.addEventListener('click', () => {

                    Swal.fire({
                        template: '#pendingBudgetEditModal',
                    }).then((result) => {

                        if (result.isConfirmed) {
                            $.ajax({
                                type: "POST",
                                url: Routing.generate('analytical_account_set_budget'),
                                data: JSON.stringify({
                                    id: analyticalAccountId,
                                    amount: result.value,
                                    method: 'update'
                                }),
                                cache: false,
                                dataType: "json",
                            }).done(function (result) {
                                const response = JSON.parse(result.data)
                                if (!response.success) {
                                    Swal.fire({
                                        title: response.reason,
                                        icon: 'error',
                                        confirmButtonText: 'Fermer',
                                        confirmButtonColor: '#dc3741',
                                    })
                                } else {
                                    Swal.fire({
                                        title: response.reason,
                                        icon: 'success',
                                        confirmButtonText: 'Fermer',
                                        confirmButtonColor: 'rgb(92, 184, 92)',
                                    }).then(() => {
                                            location.reload()
                                        }
                                    )
                                }
                            }).fail(() => {
                                console.log('error')
                            })
                        }
                    })
                })

                cancelButton.addEventListener('click', () => {
                    edit.classList.toggle('d-none')
                    input.classList.toggle('d-none')
                    save.classList.toggle('d-none')
                    deleteButton.classList.toggle('d-none')
                    validateButton.classList.toggle('d-none')
                    cancelButton.classList.toggle('d-none')
                })

                deleteButton.addEventListener('click', () => {

                    Swal.fire({
                        template: '#pendingBudgetDeleteModal',
                    }).then((result) => {

                        if (result.isConfirmed) {
                            $.ajax({
                                type: "POST",
                                url: Routing.generate('analytical_account_set_budget'),
                                data: JSON.stringify({
                                    id: analyticalAccountId,
                                    /* amount: result.value,*/
                                    method: 'delete'
                                }),
                                cache: false,
                                dataType: "json",
                            }).done(function (result) {
                                const response = JSON.parse(result.data)
                                if (!response.success) {

                                    /*TEMPLATE RESPONSE ERROR*/
                                    Swal.fire({
                                        title: response.reason,
                                        icon: 'error',
                                        confirmButtonText: 'Fermer',
                                        confirmButtonColor: '#dc3741',
                                    })
                                } else {
                                    /*TEMPLATE RESPONSE SUCCESS*/
                                    Swal.fire({
                                        title: response.reason,
                                        icon: 'success',
                                        confirmButtonText: 'Fermer',
                                        confirmButtonColor: 'rgb(92, 184, 92)',
                                    }).then(() => {
                                            location.reload()
                                        }
                                    )
                                }
                            }).fail(() => {
                                console.log('error')
                            })
                        }
                    })
                })

                validateButton.addEventListener('click', () => {

                        Swal.fire({
                            template: '#pendingBudgetValidateModal',
                        }).then((result) => {

                            if (result.isConfirmed) {
                                $.ajax({
                                    type: "POST",
                                    url: Routing.generate('analytical_account_set_budget'),
                                    data: JSON.stringify({
                                        id: analyticalAccountId,
                                        /* amount: result.value,*/
                                        method: 'validate_budget'
                                    }),
                                    cache: false,
                                    dataType: "json",
                                }).done(function (result) {
                                    const response = JSON.parse(result.data)
                                    if (!response.success) {

                                        /*TEMPLATE RESPONSE ERROR*/
                                        Swal.fire({
                                            title: response.reason,
                                            icon: 'error',
                                            confirmButtonText: 'Fermer',
                                            confirmButtonColor: '#dc3741',
                                        })
                                    } else {
                                        /*TEMPLATE RESPONSE SUCCESS*/
                                        Swal.fire({
                                            title: response.reason,
                                            icon: 'success',
                                            confirmButtonText: 'Fermer',
                                            confirmButtonColor: 'rgb(92, 184, 92)',
                                        }).then(() => {
                                                location.reload()
                                            }
                                        )
                                    }
                                }).fail(() => {
                                    console.log('error')
                                })
                            }
                        })
                })
            }

            if ($('#firstValidationBudget').length > 0) {
                const cancelButton = document.querySelector('#firstValidationBudgetCancel')

                budgetPerYearEdit.forEach((btn) => {
                    btn.classList.remove('d-none')
                })

                cancelButton.classList.toggle('d-none')

                cancelButton.addEventListener('click', () => {
                    const dataToSend = {
                        id: analyticalAccountId,
                        amount: 0,
                        method: 'cancel'
                    }
                    $.ajax({
                        type: "POST",
                        url: Routing.generate('analytical_account_set_budget'),
                        data: JSON.stringify(dataToSend),
                        cache: false,
                        dataType: "json",
                    }).done(function (result) {
                        location.reload()
                    })
                })
            }

            if ($('#validated').length > 0) {
                const cancelButton = document.querySelector('#firstValidationBudgetCancel')
                cancelButton.classList.toggle('d-none')

                cancelButton.addEventListener('click', () => {
                    const dataToSend = {
                        id: analyticalAccountId,
                        amount: 0,
                        method: 'cancel'
                    }
                    $.ajax({
                        type: "POST",
                        url: Routing.generate('analytical_account_set_budget'),
                        data: JSON.stringify(dataToSend),
                        cache: false,
                        dataType: "json",
                    }).done(function (result) {
                        location.reload()
                    })
                })

                budgetPerYearEdit.forEach((btn) => {
                    btn.classList.remove('d-none')
                })

            }
        }

        if ($('#validations_budgets_by_building').length > 0) {

            buildingFunctions.folder.financial.analyticalAccountBudgets.tablefilterAnalyticalAccountBudgets();
            const inputsValidation = document.querySelectorAll('.input__validation')
            const deleteButton = document.querySelector('#deleteButton')

            deleteButton.addEventListener('click', () => {

                    let dataToSend = [];
                    inputsValidation.forEach((checkbox) => {
                        if (checkbox.checked) {
                            const currentBudget = {
                                id: checkbox.dataset.id,
                                method: 'delete',
                                amount: checkbox.dataset.amount
                            }
                            dataToSend.push(currentBudget)
                        }
                    })

                    Swal.fire({
                        template: '#deleteModal',
                    }).then((result) => {
                            if (result.isConfirmed) {
                                $.ajax({
                                    type: "POST",
                                    url: Routing.generate('analytical_account_set_multiples_budgets'),
                                    data: JSON.stringify(dataToSend),
                                    cache: false,
                                    dataType: "json",
                                }).done(function (result) {
                                    const response = JSON.parse(result.data)
                                    if (!response.success) {
                                        /*TEMPLATE RESPONSE ERROR*/
                                        Swal.fire({
                                            title: response.reason,
                                            icon: 'error',
                                            confirmButtonText: 'Fermer',
                                            confirmButtonColor: '#dc3741',
                                        })
                                    } else {
                                        /*TEMPLATE RESPONSE SUCCESS*/
                                        Swal.fire({
                                            title: response.reason,
                                            icon: 'success',
                                            confirmButtonText: 'Fermer',
                                            confirmButtonColor: 'rgb(92, 184, 92)',
                                        }).then(() => {
                                                location.reload()
                                            }
                                        )
                                    }
                                }).fail(() => {
                                    console.log('error')
                                })
                            }
                        }
                    )
                }
            )
        }

        if ($('#index_validations').length > 0) {
            buildingFunctions.folder.validations.tablefilterValidations()


        }

        if ($('#sales_dashboard').length > 0) {
            buildingFunctions.folder.salesDashboard.tablefilterSales()

        }

        /*********************************************************************************
         --- PROJECT ---
         **********************************************************************************/

        if ($('#project_default').length > 0) {

            const isCompanyDisplayed = $('#is_company_displayed').length;

            projectFunctions.tableFilter(isCompanyDisplayed);
        }

        if ($('#project_picture').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucune photo disponible');

            mediaFunctions.selectMedia('project_media_select');

            mediaFunctions.deleteMedia('project_media_delete', 'cette photo');

            const config = {
                'message': '',
                'minImageWidth': 150,
                'maxImageWidth': 9999,
                'minImageHeight': 150,
                'maxImageHeight': 9999
            };

            mediaFunctions.dropZonePicture(config);
        }

        if ($('#project_document').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucun document disponible');

            mediaFunctions.deleteMedia('project_media_delete', 'ce document');

            mediaFunctions.dropZoneDocument();

            mediaFunctions.eventChangeOriginalNameMedia();
        }

        if ($('#project_folder').length > 0) {

            appFunctions.setLinkInTabOnReload();

            projectFunctions.folder.tableFilterCardDocumentsUploaded();
        }

        if ($('#building_future_acquisition').length > 0) {

            appFunctions.setLinkInTabOnReload();

            projectFunctions.folder.tableFilterCardDocumentsUploaded();
        }

        if ($('#project_add').length > 0) {

            const currentCompanyContainer = document.querySelector('#project_company')
            const currentCompanyContactContainer = document.querySelector('#project_companyContact')
            const currentCompanyContactId = document.querySelector('#project_companyContact').value
            const currentCompanyContactEmailContainer = document.querySelector('#project_companyContactEmail')
            const currentCompanyContactFixedPhoneContainer = document.querySelector('#project_companyContactFixedPhone')
            const currentCompanyContactCellPhoneContainer = document.querySelector('#project_companyContactCellPhone')

            /*Company Select*/
            currentCompanyContactContainer.addEventListener('change', () => {
                $.ajax({
                    type: "POST",
                    url: Routing.generate('get_user_coordinate'),
                    data: JSON.stringify({
                        id: currentCompanyContactContainer.value
                    }),
                    cache: false,
                    dataType: "json",
                }).done(function (result) {
                    currentCompanyContactEmailContainer.value = JSON.parse(result.email),
                        currentCompanyContactFixedPhoneContainer.value = JSON.parse(result.fixedPhone),
                        currentCompanyContactCellPhoneContainer.value = JSON.parse(result.cellPhone)
                }).fail(() => {
                    console.log('currentCompanyContactContainer')
                })
            })

            currentCompanyContainer.addEventListener('change', () => {
                $.ajax({
                    type: "POST",
                    url: Routing.generate('get_users_by_company'),
                    data: JSON.stringify({
                        id: currentCompanyContainer.value
                    }),
                    cache: false,
                    dataType: "json",
                }).done(function (result) {
                    currentCompanyContactContainer.innerText = '';

                    if (result.data === '[]') {
                        currentCompanyContactEmailContainer.value = '',
                            currentCompanyContactEmailContainer.disabled = true,
                            currentCompanyContactFixedPhoneContainer.value = '',
                            currentCompanyContactFixedPhoneContainer.disabled = true,
                            currentCompanyContactCellPhoneContainer.value = '',
                            currentCompanyContactCellPhoneContainer.disabled = true
                    } else {
                        JSON.parse(result.data).forEach((element) => {
                            const children = document.createElement('option')
                            children.value = element.id
                            children.textContent = element.username
                            currentCompanyContactContainer.appendChild(children)
                        })

                        if (currentCompanyContactContainer.value === '') {
                            currentCompanyContactEmailContainer.value = '',
                                currentCompanyContactEmailContainer.disabled = true,
                                currentCompanyContactFixedPhoneContainer.value = '',
                                currentCompanyContactFixedPhoneContainer.disabled = true,
                                currentCompanyContactCellPhoneContainer.value = '',
                                currentCompanyContactCellPhoneContainer.disabled = true
                        } else {
                            currentCompanyContactEmailContainer.disabled = false,
                                currentCompanyContactFixedPhoneContainer.disabled = false,
                                currentCompanyContactCellPhoneContainer.disabled = false
                            $.ajax({
                                type: "POST",
                                url: Routing.generate('get_user_coordinate'),
                                data: JSON.stringify({
                                    id: currentCompanyContactContainer.value
                                }),
                                cache: false,
                                dataType: "json",
                            }).done(function (result) {
                                currentCompanyContactEmailContainer.value = JSON.parse(result.email),
                                    currentCompanyContactFixedPhoneContainer.value = JSON.parse(result.fixedPhone),
                                    currentCompanyContactCellPhoneContainer.value = JSON.parse(result.cellPhone)
                            }).fail(() => {
                                console.log('error')
                            })
                        }
                    }
                }).fail(() => {
                    console.log('currentCompanyContainer')
                })
            })

            $.ajax({
                type: "POST",
                url: Routing.generate('get_user_coordinate'),
                data: JSON.stringify({
                    id: currentCompanyContactId
                }),
                cache: false,
                dataType: "json",
            }).done(function (result) {
                currentCompanyContactEmailContainer.value = JSON.parse(result.email),
                    currentCompanyContactFixedPhoneContainer.value = JSON.parse(result.fixedPhone),
                    currentCompanyContactCellPhoneContainer.value = JSON.parse(result.cellPhone)
            }).fail(() => {
                console.log('get_user_coordinate')
            })

            /*Supplier Select*/
            const currentSupplierContainer = document.querySelector('#project_supplier')
            const currentSupplierStreetContainer = document.querySelector('#project_supplierStreet')
            const currentSupplierStreetNumberContainer = document.querySelector('#project_supplierStreetNumber')
            const currentSupplierCityContainer = document.querySelector('#project_supplierCity')
            const currentSupplierZipCodeContainer = document.querySelector('#project_supplierZipCode')
            const currentSupplierLink = document.querySelector('#project_supplierLink')

            currentSupplierContainer.addEventListener('change', () => {
                if (currentSupplierContainer.value) {
                    $.ajax({
                        type: "POST",
                        url: Routing.generate('get_supplier_coordinate'),
                        data: JSON.stringify({
                            id: currentSupplierContainer.value
                        }),
                        cache: false,
                        dataType: "json",
                    }).done(function (result) {
                        currentSupplierStreetContainer.value = JSON.parse(result.street)
                        currentSupplierStreetNumberContainer.value = JSON.parse(result.streetNumber)
                        currentSupplierCityContainer.value = JSON.parse(result.city)
                        currentSupplierZipCodeContainer.value = JSON.parse(result.zipCode)
                        currentSupplierLink.href = '/supplier/' + JSON.parse(result.id)
                        if (currentSupplierLink.classList.contains('d-none')) {
                            currentSupplierLink.classList.remove('d-none')
                        }
                    }).fail(() => {
                        console.log('get_supplier_coordinate_change')
                    })
                } else {
                    currentSupplierStreetContainer.value = ''
                    currentSupplierStreetNumberContainer.value = ''
                    currentSupplierCityContainer.value = ''
                    currentSupplierZipCodeContainer.value = ''
                    currentSupplierLink.href = ''
                    currentSupplierLink.classList.add('d-none')
                }
            })

            tinymce.init({
                selector: '#project_distanceFrom_comment',
                menubar: false,
                branding: false
            });

            tinymce.init({
                selector: '#project_description',
                menubar: false,
                branding: false
            });

            tinymce.init({
                selector: '#project_strongPoints',
                menubar: false,
                branding: false
            });
        }


        /*********************************************************************************
         --- PROPERTY ---
         **********************************************************************************/

        if ($('#property_default').length > 0) {

            const isCompanyDisplayed = $('#is_company_displayed').length;

            propertyFunctions.tableFilter(isCompanyDisplayed);
        }

        if ($('#property_picture').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucune photo disponible');

            mediaFunctions.selectMedia('property_media_select');

            mediaFunctions.deleteMedia('property_media_delete', 'cette photo');

            const config = {
                'message': '',
                'minImageWidth': 150,
                'maxImageWidth': 9999,
                'minImageHeight': 150,
                'maxImageHeight': 9999
            };

            mediaFunctions.dropZonePicture(config);
        }

        if ($('#property_document').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucun document disponible');

            mediaFunctions.deleteMedia('property_media_delete', 'ce document');

            mediaFunctions.dropZoneDocument();

            mediaFunctions.eventChangeOriginalNameMedia();
        }

        if ($('#property_add').length > 0) {

            const propertySelector = document.querySelector('.propertyTypeSelector')
            const toggleHouseContent = document.querySelectorAll('.toggleHouse')
            const togglePropertyContent = document.querySelectorAll('.toggleProperty')
            const quotPartInput = document.querySelector('#property_quotShare')

            const toggleContent = () => {
                if (propertySelector.options[propertySelector.selectedIndex].textContent === 'Maison') {
                    if (toggleHouseContent[0].classList.contains('d-none')) {
                        toggleHouseContent.forEach(element => {
                            element.classList.toggle('d-none')
                        })
                        togglePropertyContent.forEach(element => {
                            element.classList.toggle('d-none')
                        })
                        quotPartInput.value = 1000
                        quotPartInput.disabled = 'disabled'
                    }
                } else {
                    if (togglePropertyContent[0].classList.contains('d-none')) {
                        toggleHouseContent.forEach(element => {
                            element.classList.toggle('d-none')
                        })
                        togglePropertyContent.forEach(element => {
                            element.classList.toggle('d-none')
                        })
                    }
                }
            }

            if (propertySelector.options[propertySelector.selectedIndex].textContent === 'Maison') {
                toggleContent()
            }

            propertySelector.addEventListener('change', () => {
                toggleContent()
            })

            tinymce.init({
                selector: '#property_comments',
                menubar: false,
                branding: false
            });

            new TomSelect('#property_existingReference', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })

            new TomSelect('#property_type', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc",
                },

            })
        }

        if ($('#property_add_reservation').length > 0) {
            new TomSelect('#property_current_account', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateOption'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_datePrevisionReservedSigned'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateReservedSigned'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_datePrevisionReservedGuarantee'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateReservedGuarantee'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_datePrevisionSignedAct'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateSignedAct'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateDelivery'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })
        }

        if ($('#property_edit_reservation').length > 0) {
            new TomSelect('#property_current_account', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateOption'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_datePrevisionReservedSigned'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateReservedSigned'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_datePrevisionReservedGuarantee'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateReservedGuarantee'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_datePrevisionSignedAct'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateSignedAct'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#property_current_dateDelivery'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })
        }


        if ($('#property_index').length > 0) {

            propertyFunctions.add.tableFilter();

            const tableCells = document.querySelectorAll('td')
            tableCells.forEach((cell) => {
                if (!cell.classList.contains('floor')) {
                    if (cell.textContent[0] === '-') {
                        cell.classList.add('colorDanger')
                    }
                }
            })

            propertyFunctions.add.displayValidationPropertyAddInTableFilter();

            tableFilterFunctions.setDefaultWidthColumnsIfTableEmpty('tablefilterPropertyAdd');
        }

        if ($('#property_folder').length > 0) {

            const editDiscountButtons = document.querySelectorAll('.edit_discount')
            const addDiscountButtons = document.querySelectorAll('.add_discount')
            const validateDiscountButtons = document.querySelectorAll('.validate_discount')

            if (editDiscountButtons.length > 0) {
                editDiscountButtons.forEach((button) => {
                    setModalForDiscount(button, 'edit')
                })
            }

            if (addDiscountButtons.length > 0) {
                addDiscountButtons.forEach((button) => {
                    setModalForDiscount(button, 'add')
                })
            }

            if (validateDiscountButtons.length > 0) {
                validateDiscountButtons.forEach((button) => {

                    const propertyId = button.dataset.property
                    button.addEventListener('click', () => {

                        Swal.fire({
                            template: '#validate_discount_modal_' + propertyId,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location = '/property/' + propertyId + '/validate-discount/'
                            }
                        })
                    })

                    /*{#<a href="{{ path('building_marketing_add_discount', {'building': building.id, 'property': propertyData['property'].id}) }}"*/


                    //link to validate but modal first
                    /*   setModalForValidateDiscount(button, 'validate')*/
                })
            }

            function setModalForDiscount(button, method) {

                const propertyId = button.dataset.property
                button.addEventListener('click', () => {

                    Swal.fire({
                        template: '#' + method + '_discount_modal_' + propertyId,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            $.ajax({
                                type: "POST",
                                url: Routing.generate('building_marketing_discount'),
                                data: JSON.stringify({
                                    amount: result.value,
                                    propertyId: propertyId,
                                }),
                                cache: false,
                                dataType: "json",
                            }).done(function (result) {
                                const response = JSON.parse(result.data)
                                if (!response.success) {
                                    /*TEMPLATE RESPONSE ERROR*/
                                    Swal.fire({
                                        title: response.reason,
                                        icon: 'error',
                                        confirmButtonText: 'Fermer',
                                        confirmButtonColor: '#dc3741',
                                    })
                                } else {
                                    /*TEMPLATE RESPONSE SUCCESS*/
                                    Swal.fire({
                                        title: response.reason,
                                        icon: 'success',
                                        confirmButtonText: 'Fermer',
                                        confirmButtonColor: 'rgb(92, 184, 92)',
                                    }).then(() => {
                                            location.reload()
                                        }
                                    )
                                }
                            }).fail(() => {
                                console.log('error')
                            })
                        }
                    })
                })
            }

            /* appFunctions.setLinkInTabOnReload();

             propertyFunctions.folder.tableFilterCardDocumentsUploaded();*/
        }

        if ($('#price_history_by_property').length > 0) {

            propertyFunctions.priceHistoryByProperty.tableFilter();
        }


        /*********************************************************************************
         --- USER ---
         **********************************************************************************/

        if ($('#user_register').length > 0) {
            new TomSelect('#registration_form_roles', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })
        }

        if ($('#user_edit').length > 0) {

            new TomSelect('#user_roles', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })
        }

        if ($('#user_default').length > 0) {

            const isOrganizationDisplayed = $('#is_organization_displayed').length;
            const isCompanyDisplayed = $('#is_company_displayed').length;

            userFunction.tableFilter(isOrganizationDisplayed, isCompanyDisplayed);
        }

        if ($('#user_picture').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucune photo disponible');

            mediaFunctions.selectMedia('user_media_select');

            mediaFunctions.deleteMedia('user_media_delete', 'cette photo');

            const config = {
                'message': '',
                'minImageWidth': 150,
                'maxImageWidth': 9999,
                'minImageHeight': 150,
                'maxImageHeight': 9999
            };

            mediaFunctions.dropZonePicture(config);
        }

        if ($('#user_document').length > 0) {

            appFunctions.displayMessageIfEmptyTable('Aucun document disponible');

            mediaFunctions.deleteMedia('user_media_delete', 'ce document');

            mediaFunctions.dropZoneDocument();

            mediaFunctions.eventChangeOriginalNameMedia();
        }

        if ($('#user_folder').length > 0) {

            appFunctions.setLinkInTabOnReload();

            userFunction.folder.tableFilterCardDocumentsUploaded();
        }

        /*********************************************************************************
         --- COMPANY ---
         **********************************************************************************/

        if ($('#company_default').length > 0) {

            const isOrganizationDisplayed = $('#is_organization_displayed').length;

            companyFunctions.tableFilter(isOrganizationDisplayed);
        }

        /*********************************************************************************
         --- ORDER_SUPPLIER ---
         **********************************************************************************/

        if ($('#order_supplier_index_by_building').length > 0) {
            orderSupplierFunctions.index.tableFilter();
        }

        if ($('#order_supplier_index_by_supplier').length > 0) {
            orderSupplierFunctions.index.tableFilter();
        }

        if ($('#order_supplier_show').length > 0) {

            if (document.querySelector('#edit_after_validation_button') !== null) {
                const editButton = document.querySelector('#edit_after_validation_button')
                editButton.addEventListener('click', (e) => {
                    e.preventDefault()
                    Swal.fire({
                        template: '#edit_after_validation_modal'
                    }).then((e) => {
                        if (e.isConfirmed) {
                            window.location = editButton.dataset.link
                        }
                    })
                })
            }
        }


        if ($('#order_supplier_new').length > 0) {

            new TomSelect('#order_supplier_supplier', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })

            const analyticalAccountContainer = document.querySelector('#order_supplier_analyticalAccount')
            const supplierContainer = document.querySelector('#order_supplier_supplier')
            const budgetSupplierContainer = document.querySelector('#order_supplier_budgetSupplier')
            const buildingId = document.querySelector('#buildingId')
            const analyticalAccountTemp = document.querySelector('#analyticalAccountTemp')
            let budgetTotal = 0

            if (analyticalAccountTemp.dataset.analyticalaccount > 0){
                const analyticalAccountId = analyticalAccountTemp.dataset.analyticalaccount
                resetBudgetSuppliers()
                searchAnalyticalAccounts(buildingId.textContent, supplierContainer.value, analyticalAccountId)
                searchBudgetSuppliers(analyticalAccountId, supplierContainer.value)
            }

            analyticalAccountContainer.addEventListener('change', () => {
                budgetTotal = 0
                resetBudgetSuppliers()
                searchBudgetSuppliers(analyticalAccountContainer.value, supplierContainer.value)
            })

            supplierContainer.addEventListener('change', () => {
                resetBudgetSuppliers()
                searchAnalyticalAccounts(buildingId.textContent, supplierContainer.value, 0)
            })

            function resetBudgetSuppliers() {
                budgetSupplierContainer.replaceChildren()
            }

            function searchAnalyticalAccounts(buildingId, supplierId, currentAnalyticalAccount) {

                $.ajax({
                    type: "POST",
                    url: Routing.generate('get_analytical_account_by_supplier'),
                    data: JSON.stringify({
                        buildingId: buildingId,
                        supplierId: supplierId
                    }),
                    cache: false,
                    dataType: "json",
                }).done(function (result) {
                    const analyticalAccounts = JSON.parse(result.analyticalAccounts)
                    analyticalAccountContainer.replaceChildren()

                    const option = document.createElement('option')
                    option.value = null
                    option.textContent = ''
                    analyticalAccountContainer.appendChild(option)

                    for (const [id, analyticalAccount] of Object.entries(analyticalAccounts)) {
                        const option = document.createElement('option')
                        option.value = id
                        option.textContent = analyticalAccount.name

                        if (currentAnalyticalAccount === id){
                            analyticalAccountContainer.value = id
                            option.selected = true
                        }

                        analyticalAccountContainer.appendChild(option)
                    }

                }).fail(() => {
                    console.log('error to load analytical accounts')
                })
            }

            function searchBudgetSuppliers(analyticalAccountId, supplierId) {
                console.log('ci dessus analyticalAccountContainer.value')
                console.log(analyticalAccountContainer)

                $.ajax({
                    type: "POST",
                    url: Routing.generate('get_budgets_suppliers_by_supplier_and_analytical_account'),
                    data: JSON.stringify({
                        analyticalAccountId: analyticalAccountId,
                        supplierId: supplierId
                    }),
                    cache: false,
                    dataType: "json",
                }).done(function (result) {
                    const budgets = JSON.parse(result.budgets)
                    const orderSuppliers = JSON.parse(result.orderSuppliers)

                    if (budgets.length === 0) {
                        resetBudgetSuppliers()
                    } else {
                        budgets.forEach((budget) => {
                            const elementTitle = document.createElement('a')
                            const elementValue = document.createElement('p')
                            elementValue.value = budget.id

                            if (budget.amount === null) {
                                elementTitle.textContent = 'Budget ' + budget.year
                                elementValue.textContent = 'Non défini'
                                elementTitle.href = '#'
                            } else {
                                const budgetAmount = Number(budget.amount).toLocaleString("fr-FR", {minimumFractionDigits: 2})

                                elementTitle.textContent = 'Budget ' + budget.year

                                elementTitle.href = '/building/' + buildingId.textContent + '/index-budget/' + analyticalAccountId + '#' + budget.year
                                elementTitle.target = '_blank'
                                elementValue.textContent = budgetAmount + ' €'

                                budgetTotal = Number(budgetTotal) + Number(budget.amount)
                            }

                            elementTitle.classList.add('col-6')
                            elementValue.classList.add('col-6')
                            elementTitle.classList.add('colorLink')
                            elementValue.classList.add('text-right')

                            budgetSupplierContainer.appendChild(elementTitle)
                            budgetSupplierContainer.appendChild(elementValue)
                        })

                        orderSuppliers.forEach((orderSupplier) => {
                            const elementTitle = document.createElement('a')
                            const elementValue = document.createElement('p')

                            console.log(orderSupplier.amount)

                            const orderSupplierAmount = Number(orderSupplier.amount).toLocaleString("fr-FR", {minimumFractionDigits: 2})

                            elementTitle.textContent = 'Commande ' + orderSupplier.reference
                            elementTitle.href = '/supplier/' + orderSupplier.supplier + '/commande/' + orderSupplier.id
                            elementTitle.target = '_blank'


                            elementValue.textContent = '-' + orderSupplierAmount + ' €'

                            budgetTotal = Number(budgetTotal) - Number(orderSupplier.amount)


                            elementTitle.classList.add('col-6')
                            elementValue.classList.add('col-6')
                            elementTitle.classList.add('colorLink')
                            elementValue.classList.add('text-right')

                            budgetSupplierContainer.appendChild(elementTitle)
                            budgetSupplierContainer.appendChild(elementValue)
                        })

                        const totalBudgetContainer = document.createElement('p')
                        const totalBudgetContainer2 = document.createElement('p')
                        totalBudgetContainer.textContent = 'Total : '
                        totalBudgetContainer2.textContent = Number(budgetTotal).toLocaleString("fr-FR", {minimumFractionDigits: 2}) + ' €'

                        totalBudgetContainer.classList.add('col-6')
                        totalBudgetContainer2.classList.add('col-6')
                        totalBudgetContainer.classList.add('font-weight-bold')
                        totalBudgetContainer2.classList.add('font-weight-bold')
                        totalBudgetContainer2.classList.add('text-right')
                        budgetSupplierContainer.appendChild(totalBudgetContainer)
                        budgetSupplierContainer.appendChild(totalBudgetContainer2)
                    }
                }).fail(() => {
                    console.log('error to load budget suppliers')
                })
            }

            tinyDatePicker({
                input: document.querySelector('#order_supplier_date'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#order_supplier_dateStartWork'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#order_supplier_dateOfTheOrder'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#order_supplier_deadline_date'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })

            tinyDatePicker({
                input: document.querySelector('#order_supplier_dateEndWork'),
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })
        }

        if ($('#order_supplier_condition_show').length > 0) {

            if (document.querySelector('.condition__content--select') !== null) {
                new TomSelect('#condition__content--select', {
                    create: true,
                    maxOptions: 10,
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                })
            }

            const conditionsContainers = document.querySelectorAll('.conditions__container')

            conditionsContainers.forEach((container) => {

                const title = container.querySelector('.conditions__content--title')
                const content = container.querySelector('.conditions__content')
                const dropDownButtons = container.querySelectorAll('.dropdown__button')

                title.addEventListener('click', () => {
                    content.classList.toggle('conditions__content--hidden')

                    dropDownButtons.forEach((button) => {
                        button.classList.toggle('d-none')
                    })
                })

                /*If user has no the permission to edit OrderSupplierConditions, edit button is not displayed*/
                if (container.querySelector('.conditions__content--edit') !== null) {

                    const editButton = container.querySelector('.conditions__content--edit')
                    const saveButton = container.querySelector('.conditions__content--save')
                    const cancelButton = container.querySelector('.conditions__content--cancel')
                    const conditionsValues = container.querySelectorAll('.condition__value')
                    const buttonsContainer = container.querySelectorAll('.conditions__buttons--container')
                    const textareaInputs = container.querySelectorAll('.condition__comment--textarea')
                    const comments = container.querySelectorAll('.condition__comment')
                    const tomSelectControl = container.querySelector('#condition__content--select')

                    console.log(tomSelectControl)
                    if (tomSelectControl !== null) {
                        tomSelectControl.classList.add('d-none')
                    }


                    cancelButton.addEventListener('click', () => {
                        editButton.classList.toggle('d-none')
                        saveButton.classList.toggle('d-none')
                        cancelButton.classList.toggle('d-none')
                        buttonsContainer.forEach((buttonContainer) => {
                            buttonContainer.classList.toggle('d-none')
                        })
                        conditionsValues.forEach((value) => {
                            value.classList.toggle('d-none')
                        })

                        textareaInputs.forEach((textareaInput) => {
                            textareaInput.classList.toggle('d-none')
                        })

                        comments.forEach((comment) => {
                            comment.classList.toggle('d-none')
                        })

                        if (tomSelectControl !== null) {
                            tomSelectControl.classList.toggle('d-none')
                        }
                    })

                    editButton.addEventListener('click', () => {

                        editButton.classList.toggle('d-none')
                        saveButton.classList.toggle('d-none')
                        cancelButton.classList.toggle('d-none')


                        if (tomSelectControl !== null) {
                            tomSelectControl.classList.toggle('d-none')
                        }


                        conditionsValues.forEach((value) => {
                            value.classList.toggle('d-none')
                        })

                        textareaInputs.forEach((textareaInput) => {
                            textareaInput.classList.toggle('d-none')
                        })

                        comments.forEach((comment) => {
                            comment.classList.toggle('d-none')
                        })

                        buttonsContainer.forEach((buttonContainer) => {
                            buttonContainer.classList.toggle('d-none')
                            const enableButton = buttonContainer.querySelector('.condition__content--enable')
                            const disableButton = buttonContainer.querySelector('.condition__content--disable')

                            const isActiveInput = buttonContainer.querySelector('input')
                            let isActive = null

                            if (isActiveInput.value === "1") {
                                isActive = true
                                if (enableButton.classList.contains('btn-outline-success')) {
                                    enableButton.classList.toggle('btn-success')
                                    enableButton.classList.toggle('btn-outline-success')
                                }

                            } else if (isActiveInput.value === "0") {
                                isActive = false
                                if (disableButton.classList.contains('btn-outline-danger')) {
                                    disableButton.classList.toggle('btn-danger')
                                    disableButton.classList.toggle('btn-outline-danger')
                                }

                            } else {
                                isActive = null
                            }

                            enableButton.addEventListener('click', () => {
                                if (isActive !== null) {
                                    if (!isActive) {
                                        disableButton.classList.toggle('btn-danger')
                                        disableButton.classList.toggle('btn-outline-danger')
                                    }
                                }

                                isActive = true
                                isActiveInput.value = "1"
                                enableButton.classList.toggle('btn-success')
                                enableButton.classList.toggle('btn-outline-success')

                            })

                            disableButton.addEventListener('click', () => {
                                if (isActive !== null) {
                                    if (isActive) {
                                        enableButton.classList.toggle('btn-success')
                                        enableButton.classList.toggle('btn-outline-success')

                                    }
                                }

                                isActive = false
                                isActiveInput.value = "0"
                                disableButton.classList.toggle('btn-danger')
                                disableButton.classList.toggle('btn-outline-danger')


                            })

                        })


                    })

                    saveButton.addEventListener('click', () => {

                        let dataToSend = [];

                        conditionsValues.forEach((value) => {
                            value.classList.toggle('d-none')
                        })
                        if (tomSelectControl !== null) {
                            tomSelectControl.classList.toggle('d-none')
                        }


                        buttonsContainer.forEach((buttonContainer) => {
                            buttonContainer.classList.toggle('d-none')


                            const container = buttonContainer.parentElement.parentElement
                            const comments = container.querySelectorAll('textarea')
                            const result = buttonContainer.querySelector('input')
                            let currentLabel = null
                            let currentComment = ''

                            if (tomSelectControl !== null) {
                                const currentLabelContainer = container.querySelector('#condition__content--select')

                                if (currentLabelContainer.dataset.label === result.dataset.target) {
                                    currentLabel = currentLabelContainer.value
                                }

                            }

                            comments.forEach((comment) => {
                                if (comment.id === result.dataset.target) {
                                    currentComment = comment.value
                                }
                            })

                            const currentBudget = {
                                id: result.dataset.id,
                                target: result.dataset.target,
                                value: result.value,
                                comment: currentComment,
                                label: currentLabel
                            }
                            dataToSend.push(currentBudget)
                        })

                        editButton.classList.toggle('d-none')
                        saveButton.classList.toggle('d-none')
                        cancelButton.classList.toggle('d-none')

                        comments.forEach((comment) => {
                            comment.classList.toggle('d-none')
                        })

                        textareaInputs.forEach((textareaInput) => {
                            textareaInput.classList.toggle('d-none')
                        })

                        /*conditionsValues.forEach((value) => {
                            value.classList.toggle('d-none')
                        })*/

                        $.ajax({
                            type: "POST",
                            url: Routing.generate('order_supplier_set_conditions'),
                            data: JSON.stringify(dataToSend),
                            cache: false,
                            dataType: "json",
                        }).done(function (result) {
                            location.reload()
                        }).fail(() => {
                            location.reload()
                        })


                    })


                }

            })
        }

        if ($('#order_supplier_document_show').length > 0) {

            const editButtons = document.querySelectorAll('.editButton')
            const validateButtons = document.querySelectorAll('.validateButton')
            const cancelButtons = document.querySelectorAll('.cancelButton')

            function toggleButtonsAndDoMethod(currentButton, method) {
                const currentContainer = currentButton.parentNode.parentNode
                const editButton = currentContainer.querySelector('.editButton')
                const validateButton = currentContainer.querySelector('.validateButton')
                const cancelButton = currentContainer.querySelector('.cancelButton')
                const downloadButton = currentContainer.querySelector('.downloadButton')

                const displayName = currentContainer.querySelector('.displayName')
                const inputName = currentContainer.querySelector('.inputName')
                const displayType = currentContainer.querySelector('.displayType')
                const displayTypeValue = displayType.textContent
                const selectType = currentContainer.querySelector('.selectType')

                if (method === 'edit') {
                    selectType.children.forEach((option) => {
                        if (option.innerText === displayTypeValue) {
                            option.selected = true
                        }
                    })
                } else if (method === 'validate') {
                    $.ajax({
                        type: "POST",
                        url: Routing.generate('set_type_document'),
                        data: JSON.stringify({
                            id: displayName.id,
                            name: inputName.value,
                            type: selectType.value,
                        }),
                        cache: false,
                        dataType: "json",
                    }).done(function () {
                        location.reload()
                    }).fail(() => {
                        console.log('error to change name')
                    })
                } else {
                    console.log('nothing')
                }

                editButton.classList.toggle('d-none')
                validateButton.classList.toggle('d-none')
                displayType.classList.toggle('d-none')
                selectType.classList.toggle('d-none')
                displayName.classList.toggle('d-none')
                inputName.classList.toggle('d-none')
                cancelButton.classList.toggle('d-none')
                downloadButton.classList.toggle('d-none')

            }

            editButtons.forEach((editButton) => {
                editButton.addEventListener('click', () => {
                    toggleButtonsAndDoMethod(editButton, 'edit')
                })
            })

            cancelButtons.forEach((cancelButton) => {
                cancelButton.addEventListener('click', () => {
                    toggleButtonsAndDoMethod(cancelButton, 'cancel')
                })
            })

            validateButtons.forEach((validateButton) => {
                validateButton.addEventListener('click', () => {
                    toggleButtonsAndDoMethod(validateButton, 'validate')
                })
            })

            appFunctions.displayMessageIfEmptyTable('Aucun document disponible');

            //mediaFunctions.deleteMedia('building_media_delete', 'ce document');

            mediaFunctions.dropZoneDocument();

            //mediaFunctions.eventChangeOriginalNameMedia();
        }

        /*********************************************************************************
         --- ACTIVITY FIELD ---
         **********************************************************************************/

        if ($('#activity_field_default').length > 0) {
            activityField.tableFilter();
        }

        /*********************************************************************************
         --- HISTORY ---
         **********************************************************************************/

        if ($('#history_default').length > 0) {
            historyFunctions.tableFilter()
        }

        /*********************************************************************************
         --- DEADLINE ---
         **********************************************************************************/

        if ($('#index_deadlines').length > 0) {
            deadlineFunctions.tableFilter()
        }

        /*********************************************************************************
         --- BUDGET SUPPLIER ---
         **********************************************************************************/

        if ($('#budget_supplier_define').length > 0) {
            new TomSelect('#define_supplier_supplier', {
                create: false,
                maxOptions: 200,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })
        }

        if ($('#budget_supplier_edit').length > 0) {
            new TomSelect('#budget_supplier_supplier', {
                create: false,
                maxOptions: 500,
                sortField: {
                    field: "text",
                    direction: "asc"
                }
            })
        }


        /*********************************************************************************
         --- INVOICE ---
         **********************************************************************************/

        if ($('#invoice_show').length > 0) {

            const invoiceEditButtons = document.querySelectorAll('.invoice__content--edit')
            const invoiceCancelButtons = document.querySelectorAll('.invoice__content--cancel')
            const invoiceSaveButtons = document.querySelectorAll('.invoice__content--save')


            invoiceEditButtons.forEach(invoiceEditButton => {
                invoiceEditButton.addEventListener('click', () => {
                    invoiceEditButton.classList.toggle('d-none')

                    const currentContainer = invoiceEditButton.parentElement.parentElement.parentElement

                    const status = currentContainer.dataset.status

                    const statePermanentData = currentContainer.querySelectorAll('.statePermanent')
                    statePermanentData.forEach(data => {
                        data.classList.toggle('d-none')
                    })

                    if (status === 'A renseigner') {

                        const stateToInformData = currentContainer.querySelectorAll('.stateToInform')
                        stateToInformData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                        const expectedDatesOfReceipt = currentContainer.querySelectorAll('.expectedDateOfReceipt')
                        expectedDatesOfReceipt.forEach(expectedDateOfReceipt => {
                            tinyDatePicker({
                                input: expectedDateOfReceipt,
                                format(date) {
                                    return date.toLocaleDateString('fr-FR');
                                },
                                defaultLanguagesTinyDatePicker
                            })
                        })


                    } else if (status === 'A recevoir') {

                        const stateToInformData = currentContainer.querySelectorAll('.stateToInform')
                        stateToInformData.forEach(data => {
                            data.classList.toggle('d-none')
                        })
                        const stateToReceiveData = currentContainer.querySelectorAll('.stateToReceive')
                        stateToReceiveData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                        //fix bug when expectedDateOfReceipt go to amountHTVAInvoice
                        const amountHTVAInvoice = currentContainer.querySelector('.amountHTVAInvoice')
                        console.log(amountHTVAInvoice)
                        console.log(amountHTVAInvoice.textContent)
                        console.log(amountHTVAInvoice.value.split('/').length)
                        if (amountHTVAInvoice.value.split('/').length === 3){
                            amountHTVAInvoice.value = 0
                            console.log('done')
                        }

                        const expectedDatesOfReceipt = currentContainer.querySelectorAll('.expectedDateOfReceipt')
                        expectedDatesOfReceipt.forEach(expectedDateOfReceipt => {
                            tinyDatePicker({
                                input: expectedDateOfReceipt,
                                format(date) {
                                    return date.toLocaleDateString('fr-FR');
                                },
                                defaultLanguagesTinyDatePicker
                            })
                        })

                        const datesReceived = currentContainer.querySelectorAll('.dateReceived')
                        datesReceived.forEach(dateReceived => {
                            tinyDatePicker({
                                input: dateReceived,
                                format(date) {
                                    return date.toLocaleDateString('fr-FR');
                                },
                                defaultLanguagesTinyDatePicker
                            })
                        })

                        const deadlinesPayment = currentContainer.querySelectorAll('.deadlinePayment')
                        deadlinesPayment.forEach(deadlinePayment => {
                            tinyDatePicker({
                                input: deadlinePayment,
                                format(date) {
                                    return date.toLocaleDateString('fr-FR');
                                },
                                defaultLanguagesTinyDatePicker
                            })
                        })

                        const paymentsDate = currentContainer.querySelectorAll('.paymentDate')
                        paymentsDate.forEach(paymentDate => {
                            tinyDatePicker({
                                input: paymentDate,
                                format(date) {
                                    return date.toLocaleDateString('fr-FR');
                                },
                                defaultLanguagesTinyDatePicker
                            })
                        })

                    } else if (status === 'En cours de traitement' || status === 'Attente Validation' || status === 'Paiement en attente') {

                        const stateToReceiveData = currentContainer.querySelectorAll('.stateToReceive')
                        stateToReceiveData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                        const datesReceived = currentContainer.querySelectorAll('.dateReceived')
                        datesReceived.forEach(dateReceived => {
                            tinyDatePicker({
                                input: dateReceived,
                                format(date) {
                                    return date.toLocaleDateString('fr-FR');
                                },
                                defaultLanguagesTinyDatePicker
                            })
                        })

                        const deadlinesPayment = currentContainer.querySelectorAll('.deadlinePayment')
                        deadlinesPayment.forEach(deadlinePayment => {
                            tinyDatePicker({
                                input: deadlinePayment,
                                format(date) {
                                    return date.toLocaleDateString('fr-FR');
                                },
                                defaultLanguagesTinyDatePicker
                            })
                        })

                        const paymentsDate = currentContainer.querySelectorAll('.paymentDate')
                        paymentsDate.forEach(paymentDate => {
                            tinyDatePicker({
                                input: paymentDate,
                                format(date) {
                                    return date.toLocaleDateString('fr-FR');
                                },
                                defaultLanguagesTinyDatePicker
                            })
                        })

                    } else if (status === 'Payé') {

                    } else {
                        console.log('error status')
                    }

                    const invoiceCancelButton = currentContainer.querySelector('.invoice__content--cancel')
                    invoiceCancelButton.classList.toggle('d-none')
                    const invoiceSaveButton = currentContainer.querySelector('.invoice__content--save')
                    invoiceSaveButton.classList.toggle('d-none')

                })
            })

            invoiceCancelButtons.forEach(invoiceCancelButton => {
                invoiceCancelButton.addEventListener('click', () => {
                    invoiceCancelButton.classList.toggle('d-none')
                    const currentContainer = invoiceCancelButton.parentElement.parentElement.parentElement
                    const status = currentContainer.dataset.status

                    const statePermanentData = currentContainer.querySelectorAll('.statePermanent')
                    statePermanentData.forEach(data => {
                        data.classList.toggle('d-none')
                    })

                    if (status === 'A renseigner') {

                        const stateToInformData = currentContainer.querySelectorAll('.stateToInform')
                        stateToInformData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                    } else if (status === 'A recevoir') {

                        const stateToInformData = currentContainer.querySelectorAll('.stateToInform')
                        stateToInformData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                        const stateToReceiveData = currentContainer.querySelectorAll('.stateToReceive')
                        stateToReceiveData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                    } else if (status === 'En cours de traitement' || status === 'Paiement en attente' || status === 'Attente Validation') {

                        const stateToReceiveData = currentContainer.querySelectorAll('.stateToReceive')
                        stateToReceiveData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                    } else if (status === 'Payé') {

                    } else {
                        console.log('error status')
                    }


                    const invoiceEditButton = currentContainer.querySelector('.invoice__content--edit')
                    invoiceEditButton.classList.toggle('d-none')
                    const invoiceSaveButton = currentContainer.querySelector('.invoice__content--save')
                    invoiceSaveButton.classList.toggle('d-none')
                })
            })

            invoiceSaveButtons.forEach(invoiceSaveButton => {
                invoiceSaveButton.addEventListener('click', () => {

                    invoiceSaveButton.classList.toggle('d-none')
                    const currentContainer = invoiceSaveButton.parentElement.parentElement.parentElement
                    const status = currentContainer.dataset.status
                    const invoiceId = currentContainer.dataset.invoice

                    const comment = currentContainer.querySelector('.comment')




                    if (status === 'A renseigner') {

                        const reference = currentContainer.querySelector('.reference')
                        const amountHTVAForecast = currentContainer.querySelector('.amountHTVAForecast')
                        const rateTVAForecast = currentContainer.querySelector('.rateTVAForecast')
                        const expectedDateOfReceipt = currentContainer.querySelector('.expectedDateOfReceipt')

                        const dataToSend = {
                            id: invoiceId,
                            reference: reference.value,
                            amountHTVAForecast: amountHTVAForecast.value,
                            rateTVAForecast: rateTVAForecast.value,
                            expectedDateOfReceipt: expectedDateOfReceipt.value,
                            status: status,
                            comment: comment.value
                        }

                        $.ajax({
                            type: "POST",
                            url: Routing.generate('invoice_set_data'),
                            data: JSON.stringify(dataToSend),
                            cache: false,
                            dataType: "json",
                        }).done(function (result) {
                            location.reload()
                        })

                        const stateToInformData = currentContainer.querySelectorAll('.stateToInform')
                        stateToInformData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                    } else if (status === 'A recevoir') {

                        const reference = currentContainer.querySelector('.reference')
                        const amountHTVAForecast = currentContainer.querySelector('.amountHTVAForecast')
                        const rateTVAForecast = currentContainer.querySelector('.rateTVAForecast')
                        const expectedDateOfReceipt = currentContainer.querySelector('.expectedDateOfReceipt')

                        const referenceSupplier = currentContainer.querySelector('.referenceSupplier')
                        const amountHTVAInvoice = currentContainer.querySelector('.amountHTVAInvoice')
                        const rateTVAInvoice = currentContainer.querySelector('.rateTVAInvoice')
                        const dateReceived = currentContainer.querySelector('.dateReceived')

                        const rebate = currentContainer.querySelector('.rebate')
                        const discount = currentContainer.querySelector('.discount')
                        const deadlinePayment = currentContainer.querySelector('.deadlinePayment')

                        const encodingType = currentContainer.querySelector('.encodingType')
                        const differenceBetweenPaymentsToBeTreated = currentContainer.querySelector('.differenceBetweenPaymentsToBeTreated')

                        const companyBankAccount = currentContainer.querySelector('.companyBankAccount')
                        const supplierBankAccount = currentContainer.querySelector('.supplierBankAccount')

                        let companyBankAccountValue = ''
                        if (companyBankAccount){
                            companyBankAccountValue = companyBankAccount.value
                        }

                        let supplierBankAccountValue = ''
                        if (supplierBankAccount){
                            supplierBankAccountValue = supplierBankAccount.value
                        }

                        const dataToSend = {
                            id: invoiceId,
                            referenceSupplier: referenceSupplier.value,
                            amountHTVAInvoice: amountHTVAInvoice.value,
                            rateTVAInvoice: rateTVAInvoice.value,
                            dateReceived: dateReceived.value,
                            reference: reference.value,
                            amountHTVAForecast: amountHTVAForecast.value,
                            rateTVAForecast: rateTVAForecast.value,
                            expectedDateOfReceipt: expectedDateOfReceipt.value,
                            status: status,
                            comment: comment.value,
                            rebate: rebate.value,
                            discount: discount.value,
                            deadlinePayment: deadlinePayment.value,
                            encodingType: encodingType.value,
                            differenceBetweenPaymentsToBeTreated: differenceBetweenPaymentsToBeTreated.value,
                            companyBankAccount: companyBankAccountValue,
                            supplierBankAccount: supplierBankAccountValue
                        }

                        $.ajax({
                            type: "POST",
                            url: Routing.generate('invoice_set_data'),
                            data: JSON.stringify(dataToSend),
                            cache: false,
                            dataType: "json",
                        }).done(function (result) {
                            location.reload()
                        })

                        const stateToInformData = currentContainer.querySelectorAll('.stateToInform')
                        stateToInformData.forEach(data => {
                            data.classList.toggle('d-none')
                        })
                        const stateToReceiveData = currentContainer.querySelectorAll('.stateToReceive')
                        stateToReceiveData.forEach(data => {
                            data.classList.toggle('d-none')
                        })

                    } else if (status === 'En cours de traitement' || status === 'Paiement en attente' || status === 'Attente Validation') {

                        //TODO : verify data send to Billing

                        const referenceSupplier = currentContainer.querySelector('.referenceSupplier')
                        const amountHTVAInvoice = currentContainer.querySelector('.amountHTVAInvoice')
                        const rateTVAInvoice = currentContainer.querySelector('.rateTVAInvoice')
                        const dateReceived = currentContainer.querySelector('.dateReceived')

                        const rebate = currentContainer.querySelector('.rebate')
                        const discount = currentContainer.querySelector('.discount')
                        /*const amountTVACPaid = currentContainer.querySelector('.amountTVACPaid')*/
                        const deadlinePayment = currentContainer.querySelector('.deadlinePayment')

                        const encodingType = currentContainer.querySelector('.encodingType')
                        const differenceBetweenPaymentsToBeTreated = currentContainer.querySelector('.differenceBetweenPaymentsToBeTreated')

                        const companyBankAccount = currentContainer.querySelector('.companyBankAccount')
                        const supplierBankAccount = currentContainer.querySelector('.supplierBankAccount')

                        let companyBankAccountValue = ''
                        if (companyBankAccount){
                            companyBankAccountValue = companyBankAccount.value
                        }

                        let supplierBankAccountValue = ''
                        if (supplierBankAccount){
                            supplierBankAccountValue = supplierBankAccount.value
                        }

                        const dataToSend = {
                            id: invoiceId,
                            referenceSupplier: referenceSupplier.value,
                            amountHTVAInvoice: amountHTVAInvoice.value,
                            rateTVAInvoice: rateTVAInvoice.value,
                            dateReceived: dateReceived.value,
                            rebate: rebate.value,
                            discount: discount.value,
                            deadlinePayment: deadlinePayment.value,
                            encodingType: encodingType.value,
                            differenceBetweenPaymentsToBeTreated: differenceBetweenPaymentsToBeTreated.value,
                            status: status,
                            comment: comment.value,
                            companyBankAccount: companyBankAccountValue,
                            supplierBankAccount: supplierBankAccountValue
                        }

                        $.ajax({
                            type: "POST",
                            url: Routing.generate('invoice_set_data'),
                            data: JSON.stringify(dataToSend),
                            cache: false,
                            dataType: "json",
                        }).done(function (result) {
                           location.reload()
                        })

                  /*  } else if (status === 'Envoyé') {

                    } else if (status === 'Paiement en attente') {*/

                    } else if (status === 'Payé') {

                    } else {
                        console.log('error status')
                    }

                    const statePermanentData = currentContainer.querySelectorAll('.statePermanent')
                    statePermanentData.forEach(data => {
                        data.classList.toggle('d-none')
                    })


                    const invoiceEditButton = currentContainer.querySelector('.invoice__content--edit')
                    invoiceEditButton.classList.toggle('d-none')
                    const invoiceCancelButton = currentContainer.querySelector('.invoice__content--cancel')
                    invoiceCancelButton.classList.toggle('d-none')
                })
            })

        }

        if ($('#invoice_encoding_type').length > 0) {

            invoiceFunctions.encodingType.tableFilter()

            const encodingButton = document.querySelector('.encoding__button')
            const checkAllTarget = document.querySelector('#invoice__all')
            const checkAllButton = document.createElement('input')
            checkAllButton.type = 'checkbox'
            const checkAllContainer = checkAllTarget.parentNode
            checkAllContainer.appendChild(checkAllButton)
            checkAllButton.style.display = 'inherit'
            const allCheckBoxes = document.querySelectorAll('.checkbox__encoding')
            const allCheckBoxesContainer = document.querySelectorAll('.checkbox__encoding--container')
            const downloadButtons = document.querySelectorAll('.download__button')


            checkAllButton.addEventListener('click', () => {
                if (checkAllButton.checked){
                    checkAllButton.checked = false
                    allCheckBoxes.forEach((checkbox) => {
                        checkbox.checked = false
                    })
                }else{
                    checkAllButton.checked = true
                    allCheckBoxes.forEach((checkbox) => {
                        checkbox.checked = true
                    })
                }
            })

            checkAllContainer.addEventListener('click', () => {
               if (checkAllButton.checked){
                   checkAllButton.checked = false
                   allCheckBoxes.forEach((checkbox) => {
                       checkbox.checked = false
                   })
               }else{
                   checkAllButton.checked = true
                   allCheckBoxes.forEach((checkbox) => {
                       checkbox.checked = true
                   })
               }
            })

            allCheckBoxes.forEach((checkbox) => {
                checkbox.addEventListener('click', () => {
                    if (checkAllButton.checked){
                        checkAllButton.checked = false
                    }
                })
            })

            allCheckBoxesContainer.forEach((checkboxContainer) => {
                checkboxContainer.addEventListener('click', () => {
                    if (checkAllButton.checked){
                        checkAllButton.checked = false
                    }
                    if (checkboxContainer.children[0].checked){
                        checkboxContainer.children[0].checked = false
                    }else{
                        checkboxContainer.children[0].checked = true
                    }
                })
            })

            downloadButtons.forEach((downloadButton) => {
                downloadButton.addEventListener('click', () => {

                    const filenameODACH = downloadButton.dataset.filenameodach
                    const filenameODAnalytique = downloadButton.dataset.filenameodanalytique

                    //There is no promise, this modal uis just user to display links for histories already exported
                    Swal.fire({
                        template: '#download_modal',
                        html: `<a target="_blank" href="/company/invoice-dowload-od/${filenameODACH}">${filenameODACH}</a><br/>
                        <a target="_blank" href="/company/invoice-dowload-od/${filenameODAnalytique}">${filenameODAnalytique}</a>`
                    })
                })
            })


            encodingButton.addEventListener('click', () => {

                //find all rows which are checked

                const encodingDataWeekly = document.querySelectorAll('.invoice__weekly')
                const encodingDataMonthly = document.querySelectorAll('.invoice__monthly')
                const encodingDataManual = document.querySelectorAll('.invoice__manual')

                const dataToSendWeekly = []
                const dataToSendMonthly = []
                const dataToSendManual = []

                let messageModal = ''

                let titleFlagWeekly = false
                let titleFlagMonthly = false
                let titleFlagManual = false

                encodingDataWeekly.forEach((row) => {
                    const checkbox = row.querySelector('input')

                    if(checkbox.checked){

                        if (!titleFlagMonthly){
                            messageModal = messageModal + 'Encodage Hebdomadaire : '
                            titleFlagWeekly = true
                        }

                        const data = {
                            invoiceId: row.dataset.invoiceId,
                            companyId: row.dataset.companyId,
                            analyticalStockAccountReference: '',
                            debitCompanyHTVA: '',
                            creditCompanyHTVA: '',
                            invoiceSupplierReference: row.dataset.invoiceReferenceSupplier,
                            invoiceReference: '',
                            debitSupplierTVAC: '',
                            creditSupplierTVAC: '',
                        }

                        messageModal = messageModal + row.dataset.invoiceReferenceSupplier + ', '
                        dataToSendWeekly.push(data)
                    }
                })

                encodingDataMonthly.forEach((row) => {
                    const checkbox = row.querySelector('input')

                    if(checkbox.checked){

                        if (!titleFlagMonthly){
                            messageModal = messageModal + 'Encodage Mensuel : '
                            titleFlagMonthly = true
                        }

                        const data = {
                            invoiceId: row.dataset.invoiceId,
                            companyId: row.dataset.companyId,
                            analyticalStockAccountReference: '',
                            debitCompanyHTVA: '',
                            creditCompanyHTVA: '',
                            invoiceSupplierReference: row.dataset.invoiceReferenceSupplier,
                            invoiceReference: '',
                            debitSupplierTVAC: '',
                            creditSupplierTVAC: '',
                        }

                        messageModal = messageModal + row.dataset.invoiceReferenceSupplier + ', '
                        dataToSendMonthly.push(data)
                    }
                })

                encodingDataManual.forEach((row) => {
                    const checkbox = row.querySelector('input')

                    if(checkbox.checked){

                        if (!titleFlagManual){
                            messageModal = messageModal + 'Encodage Manuel : '
                            titleFlagManual = true
                        }

                        const data = {
                            invoiceId: row.dataset.invoiceId,
                            companyId: row.dataset.companyId,
                            analyticalStockAccountReference: '',
                            debitCompanyHTVA: '',
                            creditCompanyHTVA: '',
                            invoiceSupplierReference: row.dataset.invoiceReferenceSupplier,
                            invoiceReference: '',
                            debitSupplierTVAC: '',
                            creditSupplierTVAC: '',
                        }

                        messageModal = messageModal + row.dataset.invoiceReferenceSupplier + ', '
                        dataToSendManual.push(data)
                    }
                })

                if(titleFlagWeekly || titleFlagMonthly || titleFlagManual){
                    messageModal = messageModal.slice(0, -2)
                }


                const dataToSend = {
                    weekly: dataToSendWeekly,
                    monthly: dataToSendMonthly,
                    manual: dataToSendManual,
                }

                Swal.fire({
                    template: '#encoding_modal',
                    text: messageModal
                }).then((e) => {
                    if (e.isConfirmed) {
                        $.ajax({
                            type: "POST",
                            //This route save all data in db and return types of encoding and invoices references
                            url: Routing.generate('invoice_encoding'),
                            data: JSON.stringify(dataToSend),
                            cache: false,
                            dataType: "json",
                        }).done(function (result) {
                            window.location.reload()
                            //invoiceFunctions.encodingType.exportAllODACHInExcel(result, messageModal)
                        })
                    }
                })
            })
        }

        if ($('#invoice_encoding_history_type').length > 0) {
            invoiceFunctions.encodingHistoryType.tableFilter()
        }


        if ($('#invoice_payments_by_company').length > 0) {
            invoiceFunctions.payments.tableFilter()

            const paymentButton = document.querySelector('.payment__button')
            const checkAllTarget = document.querySelector('#payment__all')
            const checkAllButton = document.createElement('input')
            checkAllButton.type = 'checkbox'
            const checkAllContainer = checkAllTarget.parentNode
            checkAllContainer.appendChild(checkAllButton)
            checkAllButton.style.display = 'inherit'
            const allCheckBoxes = document.querySelectorAll('.checkbox__payment')
            const allCheckBoxesContainer = document.querySelectorAll('.checkbox__payment--container')
            const payments = document.querySelectorAll('.invoice__payment')


            const editPaymentDeadlineButton = document.querySelector('#edit_payment_deadline__button')
            const formPaymentDeadlineContainer = document.querySelector('#form_payment_deadline__container')



            checkAllButton.addEventListener('click', () => {
                if (checkAllButton.checked){
                    checkAllButton.checked = false
                    allCheckBoxes.forEach((checkbox) => {
                        checkbox.checked = false
                    })
                }else{
                    checkAllButton.checked = true
                    allCheckBoxes.forEach((checkbox) => {
                        checkbox.checked = true
                    })
                }
            })

            checkAllContainer.addEventListener('click', () => {
                if (checkAllButton.checked){
                    checkAllButton.checked = false
                    allCheckBoxes.forEach((checkbox) => {
                        checkbox.checked = false
                    })
                }else{
                    checkAllButton.checked = true
                    allCheckBoxes.forEach((checkbox) => {
                        checkbox.checked = true
                    })
                }
            })

            allCheckBoxes.forEach((checkbox) => {
                checkbox.addEventListener('click', () => {
                    if (checkAllButton.checked){
                        checkAllButton.checked = false
                    }
                })
            })

            allCheckBoxesContainer.forEach((checkboxContainer) => {
                checkboxContainer.addEventListener('click', () => {
                    if (checkAllButton.checked){
                        checkAllButton.checked = false
                    }
                    if (checkboxContainer.children[0].checked){
                        checkboxContainer.children[0].checked = false
                    }else{
                        checkboxContainer.children[0].checked = true
                    }
                })
            })


            formPaymentDeadlineContainer.addEventListener('submit', (e) => {

                e.preventDefault()
                //todo replace payments by other name

                let count = 0
                payments.forEach((row) => {


                    if (row.querySelector('.checkbox__payment')){
                        const checkbox = row.querySelector('input')


                        if(checkbox.checked){

                            const input = document.createElement('input')
                            input.type = 'hidden'
                            input.value = row.dataset.invoiceId
                            input.name = row.dataset.invoiceId
                            formPaymentDeadlineContainer.appendChild(input)

                            count++
                            console.log(input)
                        }
                    }

                })

                if (count > 0){
                    formPaymentDeadlineContainer.submit()
                }




            })


            paymentButton.addEventListener('click', () => {

                //find all rows which are checked

                let dataToSend = []

                payments.forEach((row) => {


                    if (row.querySelector('.checkbox__payment')){
                        const checkbox = row.querySelector('input')


                        if(checkbox.checked){

                            const data = {
                                invoiceId: row.dataset.invoiceId,
                                companyId: row.dataset.companyId,
                            }
                            dataToSend.push(data)
                        }
                    }



                })

                Swal.fire({
                    template: '#payment_modal',
                    text: ''
                }).then((e) => {
                    if (e.isConfirmed) {
                        $.ajax({
                            type: "POST",
                            //This route save all data in db and return types of encoding and invoices references
                            url: Routing.generate('invoice_payment'),
                            data: JSON.stringify(dataToSend),
                            cache: false,
                            dataType: "json",
                        }).done(function (result) {
                            console.log("result")
                            const response = JSON.parse(result.data)
                            if (response.error) {
                                console.log("error")
                                /*TEMPLATE RESPONSE ERROR*/
                                Swal.fire({
                                    title: response.reason,
                                    icon: 'error',
                                    confirmButtonText: 'Fermer',
                                    confirmButtonColor: '#dc3741',
                                })
                            } else {
                                console.log("success")
                                /*TEMPLATE RESPONSE SUCCESS*/
                                Swal.fire({
                                    title: response.reason,
                                    icon: 'success',
                                    confirmButtonText: 'Fermer',
                                    confirmButtonColor: 'rgb(92, 184, 92)',
                                }).then(() => {
                                        // location.reload()
                                    }
                                )
                            }

                           /* Swal.fire({
                                template: '#payment_modal2',
                                text: 'Paiements enregistrés'
                            }).then((e) => {

                                if (e.isConfirmed) {
                                    document.location.href = '/company/' + data['companyId'] + '/invoices-export-xml/'
                                } else {
                                    document.location.href = '/company/' + data['companyId'] + '/invoices-export-xml/'
                                }
                            })*/
                        })
                    }
                })

            })


            /*

            encodingButton.addEventListener('click', () => {

                //find all rows which are checked

                const encodingDataWeekly = document.querySelectorAll('.invoice__weekly')
                const encodingDataMonthly = document.querySelectorAll('.invoice__monthly')
                const encodingDataManual = document.querySelectorAll('.invoice__manual')

                const dataToSendWeekly = []
                const dataToSendMonthly = []
                const dataToSendManual = []

                let messageModal = ''

                let titleFlagWeekly = false
                let titleFlagMonthly = false
                let titleFlagManual = false

                encodingDataWeekly.forEach((row) => {
                    const checkbox = row.querySelector('input')

                    if(checkbox.checked){

                        if (!titleFlagMonthly){
                            messageModal = messageModal + 'Encodage Hebdomadaire : '
                            titleFlagWeekly = true
                        }

                        const data = {
                            invoiceId: row.dataset.invoiceId,
                            companyId: row.dataset.companyId,
                            analyticalStockAccountReference: '',
                            debitCompanyHTVA: '',
                            creditCompanyHTVA: '',
                            invoiceSupplierReference: row.dataset.invoiceReferenceSupplier,
                            invoiceReference: '',
                            debitSupplierTVAC: '',
                            creditSupplierTVAC: '',
                        }

                        messageModal = messageModal + row.dataset.invoiceReferenceSupplier + ', '
                        dataToSendWeekly.push(data)
                    }
                })

                encodingDataMonthly.forEach((row) => {
                    const checkbox = row.querySelector('input')

                    if(checkbox.checked){

                        if (!titleFlagMonthly){
                            messageModal = messageModal + 'Encodage Mensuel : '
                            titleFlagMonthly = true
                        }

                        const data = {
                            invoiceId: row.dataset.invoiceId,
                            companyId: row.dataset.companyId,
                            analyticalStockAccountReference: '',
                            debitCompanyHTVA: '',
                            creditCompanyHTVA: '',
                            invoiceSupplierReference: row.dataset.invoiceReferenceSupplier,
                            invoiceReference: '',
                            debitSupplierTVAC: '',
                            creditSupplierTVAC: '',
                        }

                        messageModal = messageModal + row.dataset.invoiceReferenceSupplier + ', '
                        dataToSendMonthly.push(data)
                    }
                })

                encodingDataManual.forEach((row) => {
                    const checkbox = row.querySelector('input')

                    if(checkbox.checked){

                        if (!titleFlagManual){
                            messageModal = messageModal + 'Encodage Manuel : '
                            titleFlagManual = true
                        }

                        const data = {
                            invoiceId: row.dataset.invoiceId,
                            companyId: row.dataset.companyId,
                            analyticalStockAccountReference: '',
                            debitCompanyHTVA: '',
                            creditCompanyHTVA: '',
                            invoiceSupplierReference: row.dataset.invoiceReferenceSupplier,
                            invoiceReference: '',
                            debitSupplierTVAC: '',
                            creditSupplierTVAC: '',
                        }

                        messageModal = messageModal + row.dataset.invoiceReferenceSupplier + ', '
                        dataToSendManual.push(data)
                    }
                })

                if(titleFlagWeekly || titleFlagMonthly || titleFlagManual){
                    messageModal = messageModal.slice(0, -2)
                }


                const dataToSend = {
                    weekly: dataToSendWeekly,
                    monthly: dataToSendMonthly,
                    manual: dataToSendManual,
                }

                Swal.fire({
                    template: '#encoding_modal',
                    text: messageModal
                }).then((e) => {
                    if (e.isConfirmed) {
                        $.ajax({
                            type: "POST",
                            //This route save all data in db and return types of encoding and invoices references
                            url: Routing.generate('invoice_encoding'),
                            data: JSON.stringify(dataToSend),
                            cache: false,
                            dataType: "json",
                        }).done(function (result) {
                            invoiceFunctions.encodingType.exportAllODACHInExcel(result, messageModal)
                        })
                    }
                })
            })
             */


        }

        if ($('#edit_deadlines_payments_invoices').length > 0) {
            invoiceFunctions.edit_deadlines.tableFilter()

            //todo faire un bouton appliquer


            const currentDeadlinePaymentDates = document.querySelectorAll('.currentDeadlinePaymentDate')
            const newDeadlinePaymentDates = document.querySelectorAll('.newDeadlinePaymentDate')

            const reportDateValue = document.querySelector('.report_date_value')
            const reportDateSelect = document.querySelector('.report_date_select')


            const newCommonDateInput = document.querySelector('#new_common_date')
            let commonDateInput = ''

            const editDeadlineModal = document.querySelector('#edit_deadline_modal')
            const formPaymentDeadlineContainer = document.querySelector('#form_payment_deadline__container')

            const date = tinyDatePicker({
                input: newCommonDateInput,
                format(date) {
                    return date.toLocaleDateString('fr-FR');
                },
                defaultLanguagesTinyDatePicker
            })


            newCommonDateInput.addEventListener('focusout', (e) => {

                commonDateInput = e.target.value
                console.log(commonDateInput)


                newDeadlinePaymentDates.forEach((newDeadlinePaymentDate) => {

                    newDeadlinePaymentDate.textContent = commonDateInput


                })
            })

            Date.prototype.addDays = function(days) {
                const date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            }

            Date.prototype.addWeeks = function(days) {
                const date = new Date(this.valueOf());
                date.setDate(date.getDate() + (days * 7));
                return date;
            }

            Date.prototype.addMonths = function(days) {
                const date = new Date(this.valueOf());
                date.setDate(date.getDate() + (days * 30));
                return date;
            }

            reportDateValue.addEventListener('change', (e) => {


                newDeadlinePaymentDates.forEach((newDeadlinePaymentDate) => {

                    const currentDeadline = newDeadlinePaymentDate.dataset.deadline
                    const currentDeadlineSplit = currentDeadline.split('/')
                    const newValue = parseInt(e.target.value)

                    console.log(currentDeadline)

                    const selectTypeValue = reportDateSelect.value

                    const day = parseInt(currentDeadlineSplit[0])
                    const month = parseInt(currentDeadlineSplit[1])-1
                    const year = parseInt(currentDeadlineSplit[2])

                    const newDeadline = new Date(year, month, day);

                    if (selectTypeValue === 'day'){
                        newDeadlinePaymentDate.textContent = newDeadline.addDays(newValue).toLocaleDateString("fr-FR")
                    }else if(selectTypeValue === 'week'){
                        newDeadlinePaymentDate.textContent = newDeadline.addWeeks(newValue).toLocaleDateString("fr-FR")
                    }else if (selectTypeValue === 'month') {
                        newDeadlinePaymentDate.textContent = newDeadline.addMonths(newValue).toLocaleDateString("fr-FR")
                    }else{
                        console.log('error')
                    }
                })

            })

            reportDateSelect.addEventListener('change', (e) => {


                newDeadlinePaymentDates.forEach((newDeadlinePaymentDate) => {

                    const currentDeadline = newDeadlinePaymentDate.dataset.deadline

                    console.log(currentDeadline)

                    const currentDeadlineSplit = currentDeadline.split('/')
                    const newValue = reportDateValue.value

                    const selectTypeValue = e.target.value

                    const day = parseInt(currentDeadlineSplit[0])
                    const month = parseInt(currentDeadlineSplit[1])-1
                    const year = parseInt(currentDeadlineSplit[2])

                    const newDeadline = new Date(year, month, day);

                    if (selectTypeValue === 'day'){
                        newDeadlinePaymentDate.textContent = newDeadline.addDays(newValue).toLocaleDateString("fr-FR")
                    }else if(selectTypeValue === 'week'){
                        newDeadlinePaymentDate.textContent = newDeadline.addWeeks(newValue).toLocaleDateString("fr-FR")
                    }else if (selectTypeValue === 'month') {
                        newDeadlinePaymentDate.textContent = newDeadline.addMonths(newValue).toLocaleDateString("fr-FR")
                    }else{
                        console.log('error')
                    }
                })

            })

            const returnResponseContainer = document.createElement('div')


            formPaymentDeadlineContainer.addEventListener('submit', (e) => {
                e.preventDefault()

                //todo parse all rows to get id and new value

                console.log(newDeadlinePaymentDates)

                let data = []
                let isValid = false

                newDeadlinePaymentDates.forEach((newDeadlinePaymentDate) => {
                    const newDeadlinePayment = newDeadlinePaymentDate.textContent

                    if (newDeadlinePayment !== ''){
                        isValid = true

                        const invoiceId = newDeadlinePaymentDate.dataset.invoice

                        const currentDeadlinePayment = newDeadlinePaymentDate.dataset.deadline
                        const referenceInvoice = newDeadlinePaymentDate.dataset.reference
                        const arr = [invoiceId, newDeadlinePayment]
                        data.push(arr)




                        console.log(newDeadlinePayment)

                        //todo create inputs

                        const input = document.createElement('input')
                        input.type = 'hidden'
                        input.value = newDeadlinePayment
                        input.name = invoiceId
                        formPaymentDeadlineContainer.appendChild(input)



                        const line = document.createElement('p')
                        line.textContent = referenceInvoice + ': ' + currentDeadlinePayment + ' modifié en ' + newDeadlinePayment
                        returnResponseContainer.appendChild(line)
                    }




                })

                console.log(data)

                if (isValid){
                    Swal.fire({
                        template: '#edit_deadline_modal',
                        html: returnResponseContainer
                    }).then((e) => {
                        if (e.isConfirmed){
                            formPaymentDeadlineContainer.submit()
                            console.log('to send')
                        }else{
                            formPaymentDeadlineContainer.replaceChildren()
                            const submitButton = document.createElement('button')
                            submitButton.classList.add('btn', 'btn-success', 'validate__button')
                            submitButton.type = 'hidden'
                            submitButton.name = 'edit'
                            submitButton.value = 'edit'
                            submitButton.textContent = 'Enregistrer'
                            formPaymentDeadlineContainer.appendChild(submitButton)
                            /*
                            <input name="edit" type="hidden" value="edit">
                                        <button class="btn btn-success validate__button">Enregistrer</button>
                             */

                            returnResponseContainer.replaceChildren()
                            console.log('not send')
                        }

                    })
                }




            })




        }



       //Admin
        if ($('#import_cost_dashboard_data').length > 0) {

            mediaFunctions.dropZoneDocument()
        }


    }
)
;

