const budgetSupplierFunctions = {
    tableFilter: function() {

        let config = tableFilterFunctions.defaultConfig();

            config.col_0 = 'checklist',
                config.col_1 = 'none',
                config.col_2 = 'none',
                config.col_3 = 'none',
                config.col_4 = 'none',
                config.col_5 = 'none',
            config.col_types = [
                'string',
                'none',
                'none',
                'none',
                'none',
                'none',
            ]
            config.col_widths = [
                '150px',
                '150px',
                '150px',
                '150px',
                '150px',
                '150px',
            ]


        document.querySelectorAll('.tablefilter').forEach(function(table) {

            let tf = new TableFilter(table, config);
            tf.init();

         /*   let searchKeywords = propertyFunctions.getSearchKeywordsForTables();

            propertyFunctions.setFiltersOnTableIfExist(tf, searchKeywords);

            tf.filter();*/
        })
    }
}

export default budgetSupplierFunctions
